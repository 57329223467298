import React, { useState } from "react";
import {
  UseComboboxGetInputPropsOptions,
  UseComboboxGetLabelPropsOptions,
  UseComboboxGetMenuPropsOptions,
  useCombobox,
} from "downshift";
import {
  Box,
  FormControl,
  FormControlProps,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  ListProps,
} from "@chakra-ui/react";
import { IconChevronDown, IconChevronUp } from "./Icons";

type Props<T> = {
  label?: string;
  items?: T[];
  searchBehavior?: "reduce" | "highlight";
  labelProps?: UseComboboxGetLabelPropsOptions;
  inputProps?: UseComboboxGetInputPropsOptions;
  menuProps?: UseComboboxGetMenuPropsOptions & ListProps;
  formControlProps?: FormControlProps;
  defaultSelectedItem?: T | null;
  itemToString?: (item: T | null) => string;
  renderItem?: (item: T | null) => React.ReactNode;
  labelRightElement?: React.ReactNode;
  onSelectItem?: (item: T | null | undefined) => void;
  itemsFilter?: (inputValue: string) => (item: T) => boolean;
  renderEmptyState?: (
    inputValue: string,
    searchResult?: T[]
  ) => React.ReactNode;
};

function ComboSelect<T>({
  label,
  labelProps,
  labelRightElement,
  inputProps,
  menuProps,
  formControlProps,
  items: defaultItems,
  searchBehavior = "highlight",
  defaultSelectedItem,
  itemsFilter,
  renderEmptyState = () => null,
  itemToString = () => "",
  renderItem = () => null,
  onSelectItem = () => null,
}: Props<T>) {
  function ComboBox() {
    const [items, setItems] = useState(defaultItems);
    const [, _setSelectedItem] = useState<T | null | undefined>(null);
    const {
      isOpen,
      inputValue,
      setInputValue,
      selectItem,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      getInputProps,
      highlightedIndex,
      setHighlightedIndex,
      getItemProps,
      selectedItem,
    } = useCombobox({
      ...(defaultSelectedItem && { defaultSelectedItem }),
      ...(inputProps?.defaultValue && {
        defaultInputValue: inputProps?.defaultValue as string,
      }),
      onInputValueChange({ inputValue }) {
        if (inputValue && itemsFilter) {
          if (searchBehavior === "reduce") {
            setItems(defaultItems?.filter(itemsFilter(inputValue)));
          } else {
            const index = defaultItems?.findIndex(itemsFilter(inputValue));
            setHighlightedIndex(index === undefined ? -1 : index);
          }
        } else {
          setItems(defaultItems);
          selectItem(null);
        }
      },
      items: items || [],
      itemToString,
      onSelectedItemChange(changes) {
        _setSelectedItem(changes.selectedItem);
        onSelectItem(changes.selectedItem);
      },
    });

    const onBlur = () => {
      if (inputValue !== itemToString(selectedItem)) {
        if (searchBehavior === "highlight") {
          selectItem(null);
          setInputValue("");
        }
      }
    };

    return (
      <Box w="full" position="relative">
        <FormControl {...formControlProps}>
          <HStack justify="space-between" align="baseline">
            <FormLabel {...getLabelProps(labelProps)}>
              {label || "Select item"}
            </FormLabel>
            {labelRightElement}
          </HStack>
          <InputGroup>
            <Input
              placeholder="Select item"
              {...getInputProps({ ...inputProps, onBlur })}
            />
            <InputRightElement {...getToggleButtonProps()}>
              {isOpen ? (
                <IconChevronUp size={18} />
              ) : (
                <IconChevronDown size={18} />
              )}
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <List
          position="absolute"
          bg="white"
          w="full"
          mt={1}
          mx={0}
          overflow="scroll"
          maxH="80"
          zIndex="99"
          visibility={(!isOpen || items?.length === 0) && "hidden"}
          borderRadius="lg"
          borderStyle="solid"
          borderWidth="1px"
          borderColor="gray.300"
          boxShadow="lg"
          {...getMenuProps(menuProps)}
        >
          {isOpen &&
            items &&
            items.map((item, index) => (
              <ListItem
                key={`${index}`}
                bgColor={highlightedIndex === index && "gray.100"}
                py={2}
                px={4}
                cursor="pointer"
                {...getItemProps({ item, index })}
              >
                {renderItem(item)}
              </ListItem>
            ))}
        </List>
        {renderEmptyState(inputValue, items)}
      </Box>
    );
  }
  return <ComboBox />;
}

export default ComboSelect;
