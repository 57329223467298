export const IconJobProgress = ({
  size,
  color,
}: {
  size: string;
  color: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill={color}
    width={size}
    height={size}
  >
    <circle
      cx="12"
      cy="12"
      r="10"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path d="M12,12V4.6A7.4,7.4,0,1,1,5.4,15.4Z" />
  </svg>
);
