import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import LayoutSession from "../Components/LayoutSession";
import Onboarding from "../Pages/Onboarding";
import Dashboard from "../Pages/Dashboard";
import Login from "../Pages/Login";
import SignUp from "../Pages/SignUp";
import { Invite } from "../Pages/Invite";
import Reports from "../Pages/Reports";

const AppRoutes = () => (
  <Routes>
    <Route path="/login" Component={Login} />
    <Route path="/signup" Component={SignUp} />
    <Route path="/invite" Component={Invite} />
    <Route
      path="/onboard"
      element={
        <ProtectedRoute>
          <LayoutSession>
            <Onboarding />
          </LayoutSession>
        </ProtectedRoute>
      }
    />
    <Route
      path="/reports/:tag"
      element={
        <ProtectedRoute>
          <Reports />
        </ProtectedRoute>
      }
    />
    <Route
      path="*"
      element={
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      }
    />
  </Routes>
);

export default AppRoutes;
