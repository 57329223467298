export const convertPgPointToLatLng = (pt: any | null) => {
  if (!pt) return null;

  const ptStr = pt as string;
  const ptArr = ptStr.slice(1, -1).split(",").map(Number);
  const latLng = { lat: ptArr[0], lng: ptArr[1] };
  return latLng;
};

export const convertLatLngToPgPoint = (latLng?: {
  lat: number;
  lng: number;
}) => {
  if (!latLng) return null;

  return `(${latLng.lat},${latLng.lng})`;
};
