import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { GetReportByTagQuery } from "@farmevo/common/dist/graphql/graphql";
import {
  ReportJsonSummary,
  ReportJsonSummaryCropGermination,
} from "@farmevo/common/dist/types/reports";
import logoFull from "../../assets/logo-full.svg";
import { roundToTwo } from "../../utils/math";
import "react-circular-progressbar/dist/styles.css";

const CropGerminationReport = ({
  report,
}: {
  report: GetReportByTagQuery["reports"][0];
}) => {
  const jsonSummary =
    report.jsonSummary as ReportJsonSummary<ReportJsonSummaryCropGermination>;

  const germRate = roundToTwo(
    (jsonSummary.data.detectedCount / jsonSummary.data.expectedCount) * 100
  );
  const fieldArea = roundToTwo(report.seasonShot.polygon.size);
  const yieldLoss = roundToTwo(
    ((jsonSummary.data.expectedCount - jsonSummary.data.detectedCount) /
      jsonSummary.data.expectedCount) *
      100
  );
  return (
    <VStack align="unset" spacing={8}>
      <HStack justify="space-between">
        <Image src={logoFull} height={8} />
        <Box
          px={6}
          py={2}
          fontSize="sm"
          fontWeight="bold"
          borderWidth="1px"
          borderStyle="solid"
          borderRadius="full"
          borderColor="brand.400"
        >
          Field {report.seasonShot.name} | {fieldArea} acres
        </Box>
      </HStack>
      <HStack
        borderColor="gray.100"
        borderStyle="solid"
        borderWidth="1px"
        borderRadius="lg"
      >
        <Text
          py={1}
          px={4}
          flex={2}
          maxW="450px"
          borderRadius="lg"
          textAlign="center"
          bgColor="gray.100"
          fontWeight="semibold"
        >
          {jsonSummary.title}
        </Text>
        <Text flex={1} fontWeight="semibold" px={2}>
          {report.seasonShot.farm.name}
        </Text>
        <Box flex={1} px={2}>
          <Text as="span" fontWeight="semibold">
            Crop Type:
          </Text>{" "}
          <Text as="span">{report.seasonShot.cropName || "N/a"}</Text>
        </Box>
        <Box flex={1} px={2}>
          <Text as="span" fontWeight="semibold">
            Survey Date:
          </Text>{" "}
          <Text as="span">
            {new Date(report.createdAt).toLocaleDateString()}
          </Text>
        </Box>
      </HStack>
      <HStack align="flex-start">
        <VStack
          align="unset"
          flex={2}
          maxW="450px"
          p={4}
          bgColor="gray.100"
          borderRadius="2xl"
        >
          <Text
            p={2}
            w="full"
            bgColor="gray.600"
            color="white"
            fontWeight="semibold"
            borderRadius="xl"
            textAlign="center"
          >
            Total Crop Area: {fieldArea} Acres
          </Text>
          <Box p={4} borderRadius="xl" bgColor="white">
            <Text fontWeight="semibold" textAlign="center" fontSize="sm">
              Germination Rate
            </Text>
            <Box fontWeight="semibold" my={6} w="140px" mx="auto">
              <CircularProgressbar
                styles={buildStyles({
                  textColor: "#000",
                  trailColor: "#d6d6d6",
                  pathColor: "#FF9337",
                  textSize: "1.1rem",
                })}
                strokeWidth={7}
                text={`${germRate}%`}
                value={germRate}
              />
            </Box>
            <HStack
              height="150px"
              alignItems="end"
              spacing={4}
              justify="center"
            >
              <VStack
                spacing={0}
                justify="end"
                borderRadius="xl"
                height="100%"
                width="140px"
                bgColor="brand.500"
              >
                <Text fontSize="lg" fontWeight="semibold" color="white">
                  {jsonSummary.data.expectedCount.toLocaleString()}
                </Text>
                <Text pb={2} color="white" fontSize="sm">
                  100%
                </Text>
              </VStack>
              <VStack
                spacing={0}
                justify="end"
                borderRadius="xl"
                height={`${germRate}%`}
                minH="40%"
                width="140px"
                bgColor="brand.800"
              >
                <Text fontSize="lg" fontWeight="semibold" color="white">
                  {jsonSummary.data.detectedCount.toLocaleString()}
                </Text>
                <Text pb={2} color="white" fontSize="sm">
                  {germRate}%
                </Text>
              </VStack>
            </HStack>
            <HStack mt={4} spacing={10} justify="center">
              <HStack>
                <Box
                  w="10px"
                  h="10px"
                  borderRadius="full"
                  border="2px solid"
                  borderColor="brand.500"
                />
                <Text fontWeight="semibold" fontSize="sm">
                  Total Planted
                </Text>
              </HStack>
              <HStack>
                <Box
                  w="10px"
                  h="10px"
                  borderRadius="full"
                  border="2px solid"
                  borderColor="brand.800"
                />
                <Text fontWeight="semibold" fontSize="sm">
                  Total Detected
                </Text>
              </HStack>
            </HStack>
          </Box>
        </VStack>
        <VStack align="unset" flex={1}>
          <Box
            py={10}
            textAlign="center"
            borderRadius="2xl"
            bgColor="gray.100"
            fontWeight="semibold"
          >
            <Text fontSize="sm" mb={2}>
              Total Yield Loss
            </Text>
            <Text fontSize="2xl">
              {(
                jsonSummary.data.expectedCount - jsonSummary.data.detectedCount
              ).toLocaleString()}{" "}
              Plants = {yieldLoss}%
            </Text>
          </Box>
          <Box py={2} borderRadius="2xl" bgColor="gray.100">
            <Text
              mb={8}
              mt={4}
              textAlign="center"
              fontSize="sm"
              fontWeight="semibold"
            >
              Germination Status per Acre
            </Text>
            <HStack
              height="232px"
              alignItems="end"
              spacing={4}
              justify="center"
            >
              <VStack
                spacing={0}
                justify="end"
                borderRadius="xl"
                height="100%"
                width="200px"
                bgColor="teal.900"
              >
                <Text fontSize="2xl" fontWeight="semibold" color="white">
                  {Math.round(
                    jsonSummary.data.expectedCount /
                      report.seasonShot.polygon.size
                  ).toLocaleString()}
                </Text>
                <Text pb={2} color="white" fontSize="sm">
                  100%
                </Text>
              </VStack>
              <VStack
                spacing={0}
                justify="end"
                borderRadius="xl"
                height={`${germRate}%`}
                minH="40%"
                width="200px"
                bgColor="teal.800"
              >
                <Text fontSize="2xl" fontWeight="semibold" color="white">
                  {Math.round(
                    jsonSummary.data.detectedCount /
                      report.seasonShot.polygon.size
                  ).toLocaleString()}
                </Text>
                <Text pb={2} color="white" fontSize="sm">
                  {germRate}%
                </Text>
              </VStack>
            </HStack>
            <HStack mt={4} spacing={12} justify="center">
              <HStack>
                <Box
                  w="10px"
                  h="10px"
                  borderRadius="full"
                  border="2px solid"
                  borderColor="teal.900"
                />
                <Text fontWeight="semibold" fontSize="sm">
                  Total Planted per Acre
                </Text>
              </HStack>
              <HStack>
                <Box
                  w="10px"
                  h="10px"
                  borderRadius="full"
                  border="2px solid"
                  borderColor="teal.800"
                />
                <Text fontWeight="semibold" fontSize="sm">
                  Total Detected per Acre
                </Text>
              </HStack>
            </HStack>
          </Box>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default CropGerminationReport;
