import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormMode } from "../../constants/enums";
import {
  Entity_Status_Enum,
  GetChargeableUnitsQuery,
  UpdateMyProductStatusMutation,
  UpdateMyProductStatusMutationVariables,
  GetMyProductsPaginatedQuery,
  GetMyProductsPaginatedQueryVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { useMutation, useQuery } from "urql";
import { IconEdit, IconPlus } from "../../Components/Icons";
import { useSession } from "../../hooks/useSession";
import {
  GET_CHARGEABLE_UNITS,
  GET_MY_PRODUCTS_PAGINATED,
  UPDATE_MY_PRODUCT_STATUS,
} from "./products.graphql";
import ProductForm from "./Form";
import { usePaginatedUrqlQuery } from "../../hooks/usePaginatedQuery";

const Products = () => {
  const {
    isOpen: isFormOpen,
    onOpen: onFormOpen,
    onClose: onFormClose,
  } = useDisclosure({
    onClose: () => {
      setSelectedProduct(null);
    },
  });
  const { session } = useSession();
  const toast = useToast();

  const [formMode, setFormMode] = useState<FormMode>(FormMode.Add);
  const [statusFilter, setStatusFilter] = useState<Entity_Status_Enum>(
    Entity_Status_Enum.Active
  );
  const [searchFilter, setSearchFilter] = useState<{
    query: string;
    debounced: boolean;
  }>({ query: "", debounced: true });

  const [selectedProduct, setSelectedProduct] = useState<
    GetMyProductsPaginatedQuery["products"][0] | null
  >(null);

  //debouncing the search input
  useEffect(() => {
    //since react does not run a deep check,
    //the timeout will lead to infinite renders, the if condition prevents that.
    if (searchFilter.debounced) {
      return;
    }
    const setDebounce = setInterval(() => {
      setSearchFilter((prev) => {
        return { query: prev.query, debounced: true };
      });
    }, 300);

    return () => clearInterval(setDebounce);
  }, [searchFilter]);

  const userId = session?.id;

  //This is a query to fetch the updated list of chargeable units for products.
  const [{ data: cuData }] = useQuery<GetChargeableUnitsQuery>({
    query: GET_CHARGEABLE_UNITS,
    pause: !userId,
  });
  const { data, fetchMore, canFetchMore } = usePaginatedUrqlQuery<
    GetMyProductsPaginatedQuery,
    GetMyProductsPaginatedQueryVariables
  >(
    GET_MY_PRODUCTS_PAGINATED,
    {
      variables: {
        filter: {
          userId: { _eq: userId || -1 },
          ...(statusFilter && {
            status: { _eq: statusFilter as Entity_Status_Enum },
          }),
          ...(searchFilter && {
            name: { _ilike: `%${searchFilter.query}%` },
          }),
        },
        limit: 10,
      },
      pause: !userId || !searchFilter.debounced,
    },
    {
      getLength(data) {
        return data.products_aggregate.aggregate?.count || data.products.length;
      },
    }
  );

  const [, setProductStatus] = useMutation<
    UpdateMyProductStatusMutation,
    UpdateMyProductStatusMutationVariables
  >(UPDATE_MY_PRODUCT_STATUS);

  return (
    <Box p="2" m="2" mt="7">
      {" "}
      <Text ml="4" fontWeight="bold" fontSize="2xl">
        Products
      </Text>
      <Flex ml="2" p="2" my="2" align="flex-end" justifyContent="space-between">
        <Flex>
          <FormControl mx="2">
            <FormLabel>Search</FormLabel>
            <Input
              type="search"
              value={searchFilter.query}
              onChange={(e) =>
                setSearchFilter({ query: e.target.value, debounced: false })
              }
              placeholder="Search Products"
              w="sm"
              name="search"
            />
          </FormControl>
          <FormControl mx="2">
            <FormLabel>Status</FormLabel>
            <Select
              value={statusFilter}
              onChange={(e) => {
                setStatusFilter(e.target.value as Entity_Status_Enum);
              }}
            >
              <option value="active">Active</option>
              <option value="archived">Archived</option>
            </Select>
          </FormControl>
        </Flex>
        <Button
          mr="5"
          colorScheme="brand"
          size="md"
          onClick={onFormOpen}
          leftIcon={<IconPlus />}
        >
          {" "}
          Add Product
        </Button>
        <ProductForm
          mode={formMode}
          chargeableUnits={cuData?.chargeable_units || []}
          isOpen={isFormOpen}
          userId={userId || -1}
          onClose={onFormClose}
          selected={selectedProduct}
        />
      </Flex>
      {!!data?.products ? (
        <TableContainer>
          <Table mt="5">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Price Per Unit</Th>
                <Th>Chargeable Unit</Th>
                <Th>Status</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.products
                .slice(0)
                .reverse()
                .map((product) => {
                  return (
                    <Tr key={product.id}>
                      <Td w="30%">{product.name}</Td>
                      <Td>{product.pricePerUnit}</Td>
                      <Td>{product.chargeableUnit}</Td>
                      <Td w="15%">
                        <Select
                          onChange={async (e) => {
                            const result = await setProductStatus({
                              id: product.id,
                              status: e.target.value as Entity_Status_Enum,
                            });
                            toast({
                              title: result.error
                                ? `An error occured while updating vehicle status`
                                : `Vehicle status updated`,
                              status: result.error ? "error" : "success",
                              duration: 3000,
                              isClosable: true,
                            });
                          }}
                          defaultValue={product.status}
                        >
                          <option value="active">Active</option>
                          <option value="archived">Archived</option>
                        </Select>
                      </Td>
                      <Td w="5%">
                        <Button
                          onClick={() => {
                            setFormMode(FormMode.Edit);
                            setSelectedProduct(product);
                            onFormOpen();
                          }}
                          size="sm"
                        >
                          <IconEdit />
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
          <HStack ml="5" mt="5">
            <Text>
              Showing {data.products.length} of{" "}
              {data.products_aggregate.aggregate?.count} products
            </Text>
            (
            {canFetchMore && (
              <Text
                onClick={fetchMore}
                fontWeight="bold"
                textColor="brand.500"
                _hover={{ cursor: "pointer" }}
              >
                Load More
              </Text>
            )}
            )
          </HStack>
        </TableContainer>
      ) : statusFilter !== Entity_Status_Enum.Active || searchFilter.query ? (
        <Text ml="5">
          <b>No Products found.</b>
        </Text>
      ) : (
        <Text ml="5">
          <b>You don't have any products.</b>
          <br />
          Click on the "Add" button to start creating new products.{" "}
        </Text>
      )}
    </Box>
  );
};

export default Products;
