import { gql } from "urql";

export const GET_MY_WAREHOUSES = gql`
  query GetMyWarehouses($filter: warehouses_bool_exp!) {
    warehouses(where: $filter) {
      id
      address
      geoLocation
      name
      farm {
        id
        name
      }
    }
  }
`;

export const SAVE_WAREHOUSE_MUTATION = gql`
  mutation SaveWarehouse($warehouse: warehouses_insert_input!) {
    insert_warehouses_one(
      object: $warehouse
      on_conflict: {
        constraint: warehouses_pkey
        update_columns: [name, address, geoLocation, farmId]
      }
    ) {
      id
      address
      geoLocation
      name
      farm {
        id
        name
      }
    }
  }
`;
