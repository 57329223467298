import { KeyingConfig } from "@urql/exchange-graphcache";

export const keys: KeyingConfig = {
  chargeable_units: (data) => `${data.value}`,
  user_tax_rates: (data) => `${data.userId}:${data.label}`,
  polygons: (data) => (data.id ? `${data.id}` : null),
  field_season_shot: (data) => (data.id ? `${data.id}` : null),
  jobs_aggregate: () => null,
  jobs_aggregate_fields: () => null,
  job_extras: () => null,
  job_operators: () => null,
  job_operator_vehicles: () => null,
  job_operator_implements: () => null,
  job_products: (data) => `${data.jobId}:${data.productId}`,
  job_fields: () => null,
  inventory_aggregate: () => null,
  operation_products: (data) => `${data.operationId}:${data.productId}`,
  products_aggregate: () => null,
  products_aggregate_fields: () => null,
  team_members: () => null,
  user_prefs: () => null,
  user_profiles: () => null,
  user_roles: (data) => `${data.userId}:${data.role}`,
  vehicles_aggregate: () => null,
  vehicles_aggregate_fields: () => null,
};
