import { Navigate, useLocation } from "react-router-dom";
import { useSession } from "../hooks/useSession";

export const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const { session } = useSession();
  const location = useLocation();

  if (!session) {
    const redirectPath =
      location.pathname === "/" ? "" : `?redirect=${location.pathname}`;
    return <Navigate to={"/login" + redirectPath} />;
  }
  return children;
};
