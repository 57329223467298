import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  VisuallyHidden,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "urql";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { IconEdit, IconPlus } from "../../Components/Icons";
import {
  GetMyWarehousesQuery,
  GetMyWarehousesQueryVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { GET_MY_WAREHOUSES } from "./warehouses.graphql";
import { useSession } from "../../hooks/useSession";
import { LoadingSkeleton } from "../../Components/LoadingSkeleton";
import WarehouseForm from "./WarehouseForm";
const Warehouses = () => {
  const { session } = useSession();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedWarehouse, setSelectedWarehouse] = useState<
    GetMyWarehousesQuery["warehouses"][0] | null
  >(null);
  const {
    isOpen: isFormOpen,
    onOpen: onFormOpen,
    onClose: closeFormDrawer,
  } = useDisclosure();
  const [{ data: warehouseData, fetching: loadingWarehouses }] = useQuery<
    GetMyWarehousesQuery,
    GetMyWarehousesQueryVariables
  >({
    query: GET_MY_WAREHOUSES,
    variables: {
      filter: {
        userId: { _eq: session?.id || -1 },
        ...(searchQuery && {
          _or: [
            { name: { _ilike: `%${searchQuery}%` } },
            { address: { _ilike: `%${searchQuery}%` } },
            { farm: { name: { _ilike: `%${searchQuery}%` } } },
          ],
        }),
      },
    },
    pause: !session?.id,
  });

  const onSearch = useDebouncedCallback(setSearchQuery, 400);

  const onFormClose = () => {
    closeFormDrawer();
    setSelectedWarehouse(null);
  };

  return (
    <VStack align="unset" p={10} spacing={4}>
      <Text fontSize="2xl" fontWeight="bold">
        Warehouses
      </Text>
      <HStack align="end" justify="space-between">
        <HStack align="end">
          <FormControl>
            <FormLabel color="gray.500">Search</FormLabel>
            <Input
              placeholder="Search by keyword"
              type="text"
              onChange={(e) => onSearch(e.target.value)}
            />
          </FormControl>
        </HStack>
        <Box>
          <Button
            colorScheme="brand"
            leftIcon={<IconPlus />}
            onClick={onFormOpen}
          >
            Add Warehouse
          </Button>
        </Box>
      </HStack>
      <WarehouseForm
        isOpen={isFormOpen}
        onClose={onFormClose}
        selectedWarehouse={selectedWarehouse}
      />
      {loadingWarehouses ? (
        <LoadingSkeleton />
      ) : warehouseData?.warehouses && warehouseData?.warehouses.length > 0 ? (
        <TableContainer whiteSpace="normal">
          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Name</Th>
                <Th>Address</Th>
                <Th>
                  <VisuallyHidden>Actions</VisuallyHidden>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {warehouseData.warehouses.map((warehouse) => (
                <Tr key={warehouse.id}>
                  <Td>{warehouse.id}</Td>
                  <Td>{warehouse.name}</Td>
                  <Td>{warehouse.address || "-"}</Td>
                  <Td>
                    <HStack justify="end">
                      <IconButton
                        aria-label="edit warehouse"
                        icon={<IconEdit />}
                        onClick={() => {
                          setSelectedWarehouse(warehouse);
                          onFormOpen();
                        }}
                      />
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <VStack align="start" py={4}>
          <Text fontWeight="semibold" fontSize="lg">
            No warehouses yet.
          </Text>
          <Text>
            All of your warehouses appear here. Click the{" "}
            <Tag size="md" variant="solid" colorScheme="brand">
              <TagLeftIcon boxSize="12px" as={IconPlus} />
              <TagLabel>Add</TagLabel>
            </Tag>{" "}
            button to add a warehouse
          </Text>
        </VStack>
      )}
    </VStack>
  );
};
export default Warehouses;
