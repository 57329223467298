import { Box, Button, Spinner, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "urql";
import { FieldShot, GET_FIELD_SNAPSHOTS_QUERY } from "./fields.graphql";
import {
  GetFieldSnapshotsQuery,
  GetFieldSnapshotsQueryVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { useSession } from "../../hooks/useSession";
import { useMemo } from "react";

const SnapshotHistory = ({
  selectedShot,
  fieldId,
  isOpen,
  onSelectShot,
}: {
  selectedShot?: FieldShot;
  fieldId: number;
  isOpen: boolean;
  onSelectShot: (shot: FieldShot) => void;
}) => {
  const { session } = useSession();
  const [{ data, fetching }] = useQuery<
    GetFieldSnapshotsQuery,
    GetFieldSnapshotsQueryVariables
  >({
    query: GET_FIELD_SNAPSHOTS_QUERY,
    variables: { userId: session?.id, fieldId },
    pause: !isOpen,
    context: useMemo(
      () => ({ additionalTypenames: ["field_season_shot"] }),
      []
    ),
  });

  const onClickSeason = (shot: FieldShot) => {
    onSelectShot(shot);
  };

  if (fetching) {
    return (
      <Box>
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <VStack pr={2}>
        {data?.field_season_shot.map((shot) => (
          <Button
            key={shot.id}
            w="full"
            variant={selectedShot?.id === shot.id ? "solid" : "outline"}
            colorScheme="brand"
            onClick={() => onClickSeason(shot)}
            isDisabled={selectedShot?.id === shot.id}
            _disabled={{ backgroundColor: "brand.500" }}
          >
            <Text flex={1} textAlign="left">
              {shot.season}
            </Text>
            <Text fontSize="sm">{shot.cropName}</Text>
          </Button>
        ))}
      </VStack>
    </>
  );
};

export default SnapshotHistory;
