import { Flex, FlexProps, Spinner } from "@chakra-ui/react";

const LayoutLoading: React.FC<FlexProps> = ({ ...props }) => {
  return (
    <Flex height="100vh" justify="center" align="center" {...props}>
      <Spinner color="brand.900" size="lg" />
    </Flex>
  );
};

export default LayoutLoading;
