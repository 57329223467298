import { gql } from "urql";

export const GET_JOBS_QUERY = gql`
  query GetJobs($filter: jobs_bool_exp!, $limit: Int!, $offset: Int!) {
    count: jobs_aggregate(where: $filter) {
      aggregate {
        count
      }
    }
    operations: jobs_aggregate(distinct_on: operationName) {
      nodes {
        id: operationName
        operationName
      }
    }
    jobs(
      limit: $limit
      offset: $offset
      where: $filter
      order_by: { id: desc }
    ) {
      id
      operationName
      assignedUser {
        id
        firstName
      }
      from
      to
      status
    }
  }
`;
