import { Image, Text, VStack } from "@chakra-ui/react";
import Button from "../../Components/Button";
import IconGoogleDrive from "../../assets/google-drive.png";

const GoogleDriveButton = ({
  onClick,
  isDisabled,
  isSignedIn,
}: {
  onClick: () => void;
  isDisabled: boolean;
  isSignedIn: boolean;
}) => {
  return (
    <Button minH="110px" flex={1} onClick={onClick} isDisabled={isDisabled}>
      <VStack spacing={3}>
        <Image src={IconGoogleDrive} width="24px" />
        <Text>
          {isSignedIn ? "Browse file to upload" : "Upload to Google Drive"}
        </Text>
        <Text fontSize="sm" fontStyle="italic" color="gray.500">
          {isSignedIn ? "Signed in" : "Requires sign in"}
        </Text>
      </VStack>
    </Button>
  );
};

export default GoogleDriveButton;
