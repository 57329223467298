import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useMutation, useQuery } from "urql";
import { Formik, FormikHelpers, useFormikContext } from "formik";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanelProps,
  TabPanels,
  TabProps,
  Table,
  Tabs,
  Tag,
  TagCloseButton,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  VisuallyHidden,
} from "@chakra-ui/react";
import {
  Chargeable_Units_Enum,
  GetChargeableUnitsQuery,
  GetMyOperationsQuery,
  GetMyProductsQuery,
  InsertMyOperationMutation,
  InsertMyOperationMutationVariables,
  Operation_Products_Insert_Input,
  UpdateMyOperationMutation,
  UpdateMyOperationMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import {
  IconDatabase,
  IconEdit2,
  IconPackage,
  IconPlus,
  IconSettings,
  IconX,
} from "../../Components/Icons";
import Button from "../../Components/Button";
import ComboSelect from "../../Components/ComboSelect";
import { GET_CHARGEABLE_UNITS } from "../Products/products.graphql";
import { INSERT_MY_OPERATION, UPDATE_MY_OPERATION } from "./operations.graphql";
import { useSession } from "../../hooks/useSession";

type OperationFormExtra = Omit<
  GetMyOperationsQuery["operations"][0]["extras"][0],
  "id"
> & {
  id?: string;
};

interface ModalFormProps {
  type: string;
  description: string;
  name: string;
  billingRate: string;
  billingUnit: Chargeable_Units_Enum;
  products: string[];
  extras: OperationFormExtra[];
}

interface OperationModalProps {
  operation?: GetMyOperationsQuery["operations"][0] | null;
  products?: GetMyProductsQuery["products"];
  operationTypes: string[];
  isOpen: boolean;
  onClose: () => void;
}

export const OperationModal = ({
  operation,
  products,
  operationTypes,
  ...props
}: OperationModalProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const isEditMode = !!operation;
  const { session } = useSession();

  const [, insertOperation] = useMutation<
    InsertMyOperationMutation,
    InsertMyOperationMutationVariables
  >(INSERT_MY_OPERATION);

  const [, updateOperation] = useMutation<
    UpdateMyOperationMutation,
    UpdateMyOperationMutationVariables
  >(UPDATE_MY_OPERATION);

  const initialValues: ModalFormProps = {
    type: operation?.type || "",
    description: operation?.description || "",
    name: operation?.name || "",
    billingRate: operation?.billingRate || "",
    billingUnit: operation?.billingUnit || Chargeable_Units_Enum.Acre,
    products: operation?.products.map((op) => op.product.id) || [],
    extras: operation?.extras || [],
  };

  const onSubmit = async (
    values: ModalFormProps,
    helpers: FormikHelpers<ModalFormProps>
  ) => {
    if (!values.name.trim() || !values.type) {
      helpers.setErrors({
        type: !values.type ? "Please select a type" : undefined,
        name: !values.name.trim() ? "Please provide a name" : undefined,
      });
      setTabIndex(0);
      return;
    }
    if (isEditMode) {
      const deletedProductIds = operation.products
        .filter(
          (op) =>
            !values.products.find((productId) => productId === op.product.id)
        )
        .map((op) => op.product.id);

      const newProducts: Operation_Products_Insert_Input[] = values.products
        .filter(
          (productId) =>
            !operation.products.find((p) => p.product.id === productId)
        )
        .map((productId) => ({
          productId,
          operationId: operation.id,
          userId: session?.id,
        }));

      const deletedExtraIds = operation.extras
        .filter((extra) => !values.extras.find((ex) => ex.id === extra.id))
        .map((ex) => ex.id);

      const newExtras = values.extras.filter((ex) => !ex.id);
      const updatedExtras = values.extras.filter((ex) => !!ex.id);

      await updateOperation({
        id: operation?.id,
        updateOperationPayload: {
          name: values.name.trim(),
          type: values.type,
          description: values.description.trim(),
          billingRate: Number(values.billingRate),
          billingUnit: values.billingUnit,
        },
        updatedExtras: updatedExtras.map((extra) => ({
          where: { id: { _eq: extra.id } },
          _set: { value: extra.value?.trim(), unit: extra.unit },
        })),
        newExtras: newExtras.map((ex) => ({
          ...ex,
          operationId: operation.id,
        })),
        newProducts,
        deletedExtraIds,
        deletedProductIds,
      });
    } else {
      await insertOperation({
        saveOperationPayload: {
          name: values.name.trim(),
          type: values.type,
          userId: session?.id,
          description: values.description.trim(),
          billingRate: Number(values.billingRate),
          billingUnit: values.billingUnit,
          products: {
            data: values.products.map((productId) => ({
              productId,
              userId: session?.id,
            })),
          },
          extras: {
            data: values.extras.map((extra) => ({
              name: extra.name,
              value: extra.value,
              unit: extra.unit,
            })),
          },
        },
      });
    }
    props.onClose();
  };

  useEffect(() => {
    setTabIndex(0);
  }, [props.isOpen]);

  return (
    <Modal size="2xl" {...props}>
      <ModalOverlay />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <HStack>
              <Box>{isEditMode ? <IconEdit2 /> : <IconPlus />}</Box>
              <Text textTransform="capitalize">
                {isEditMode
                  ? `${operation.type}: ${operation.name}`
                  : "Add operation"}
              </Text>
            </HStack>
          </ModalHeader>
          <ModalBody minH="385px" pb={10}>
            <Tabs
              index={tabIndex}
              onChange={setTabIndex}
              orientation="vertical"
              variant="soft-rounded"
              colorScheme="orange"
            >
              <TabList>
                <StepTab label="General" icon={<IconSettings size={16} />} />
                <StepTab label="Products" icon={<IconPackage size={16} />} />
                <StepTab label="Extras" icon={<IconDatabase size={16} />} />
              </TabList>
              <TabPanels>
                <StepPanel>
                  <PanelContentGeneral operationTypes={operationTypes} />
                </StepPanel>
                <StepPanel>
                  <PanelContentProducts products={products} />
                </StepPanel>
                <StepPanel>
                  <PanelContentExtras />
                </StepPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter as={HStack}>
            <FormActions tabIndex={tabIndex} onChangeTabIndex={setTabIndex} />
          </ModalFooter>
        </ModalContent>
      </Formik>
    </Modal>
  );
};

const FormActions = ({
  tabIndex,
  onChangeTabIndex,
}: {
  tabIndex: number;
  onChangeTabIndex: Dispatch<SetStateAction<number>>;
}) => {
  const { submitForm, isSubmitting } = useFormikContext<ModalFormProps>();
  return (
    <>
      {tabIndex > 0 && (
        <Button
          minW="150px"
          onClick={() => tabIndex > 0 && onChangeTabIndex((index) => index - 1)}
        >
          Previous
        </Button>
      )}
      <Button
        colorScheme={tabIndex < 2 ? "gray" : "orange"}
        minW="150px"
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        onClick={(e) => {
          if (tabIndex < 2) {
            onChangeTabIndex((index) => (index + 1) % 3);
          } else {
            submitForm();
          }
        }}
      >
        {tabIndex < 2 ? "Continue" : "Save"}
      </Button>
    </>
  );
};

const StepTab = ({
  icon,
  label,
  ...props
}: { label: string; icon: ReactNode } & TabProps) => (
  <Tab justifyContent="start" {...props}>
    {icon}
    <Text pl={2} as="span">
      {label}
    </Text>
  </Tab>
);

const StepPanel = ({ children }: TabPanelProps) => {
  return (
    <TabPanel pl={6} pt={2}>
      <Box>{children}</Box>
    </TabPanel>
  );
};

const PanelContentGeneral = ({
  operationTypes,
}: {
  operationTypes: string[];
}) => {
  const formik = useFormikContext<ModalFormProps>();
  const [{ data: cuData }] = useQuery<GetChargeableUnitsQuery>({
    query: GET_CHARGEABLE_UNITS,
  });
  return (
    <VStack align="unset">
      <ComboSelect<string>
        items={operationTypes}
        itemsFilter={(v) => (type) => type.includes(v)}
        itemToString={(type) => type || ""}
        label="Operation Type"
        searchBehavior="reduce"
        formControlProps={{ isRequired: true, isInvalid: !!formik.errors.type }}
        inputProps={{
          id: "type",
          name: "type",
          placeholder: "Select type",
          style: { textTransform: "capitalize" },
        }}
        defaultSelectedItem={formik.values.type}
        onSelectItem={(type) => formik.setFieldValue("type", type)}
        renderItem={(item) => (
          <Text as="span" textTransform="capitalize">
            {item}
          </Text>
        )}
        menuProps={{ maxH: "200px" }}
      />
      <FormControl isInvalid={!!formik.errors.name} isRequired>
        <FormLabel>Name</FormLabel>
        <Input
          name="name"
          placeholder="e.g. Round bailing"
          value={formik.values.name}
          onChange={(e) => formik.handleChange(e)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Description</FormLabel>
        <Input
          name="description"
          placeholder="e.g. done for cotton crop"
          value={formik.values.description}
          onChange={(e) => formik.handleChange(e)}
        />
      </FormControl>
      <HStack>
        <FormControl>
          <FormLabel>Billing Rate</FormLabel>
          <Input
            name="billingRate"
            type="number"
            placeholder="0"
            min="0"
            value={formik.values.billingRate}
            onChange={(e) => formik.handleChange(e)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Billing Unit</FormLabel>
          <Select
            name="billingUnit"
            value={formik.values.billingUnit}
            onChange={(e) => formik.handleChange(e)}
          >
            {cuData?.chargeable_units.map((unit, idx) => {
              return (
                <option key={idx} value={unit.value}>
                  {unit.value}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </HStack>
    </VStack>
  );
};

const PanelContentProducts = ({
  products,
}: {
  products?: GetMyProductsQuery["products"];
}) => {
  const formik = useFormikContext<ModalFormProps>();
  return (
    <VStack align="unset">
      <ComboSelect<GetMyProductsQuery["products"][0]>
        items={products?.filter(
          (product) => !formik.values.products.includes(product.id)
        )}
        itemsFilter={(v) => (product) =>
          product.name.toLowerCase().includes(v.toLowerCase())}
        itemToString={(product) => product?.name || ""}
        label="Select Products"
        searchBehavior="reduce"
        inputProps={{
          id: "productSelector",
          name: "productSelector",
          placeholder: "Search products to add",
        }}
        onSelectItem={(product) =>
          formik.setValues((values) => ({
            ...values,
            products: [...values.products, product?.id],
          }))
        }
        renderItem={(item) => <Text as="span">{item?.name}</Text>}
        menuProps={{ maxH: "200px" }}
      />
      {!!formik.values.products.length && (
        <Grid templateColumns="repeat(3, 1fr)" rowGap={2} columnGap={2}>
          {formik.values.products.map((productId) => (
            <GridItem key={productId}>
              <Tag
                w="full"
                size="lg"
                borderRadius="full"
                variant="solid"
                colorScheme="orange"
              >
                <TagLabel flex={1}>
                  {products?.find((product) => product.id === productId)?.name}
                </TagLabel>
                <TagCloseButton
                  onClick={() => {
                    formik.setValues((prevValues) => ({
                      ...prevValues,
                      products: prevValues.products.filter(
                        (_productId) => _productId !== productId
                      ),
                    }));
                  }}
                />
              </Tag>
            </GridItem>
          ))}
        </Grid>
      )}
      <Box></Box>
    </VStack>
  );
};

const PanelContentExtras = () => {
  const [extraName, setExtraName] = useState("");
  const [extraUnit, setExtraUnit] = useState<Chargeable_Units_Enum>(
    Chargeable_Units_Enum.Acre
  );
  const formik = useFormikContext<ModalFormProps>();

  const onAddExtra = () => {
    const extraNameTrimmed = extraName.trim();
    if (!extraNameTrimmed) return;

    const alreadyExists = !!formik.values.extras.find(
      (extra) => extra.name === extraNameTrimmed
    );
    if (alreadyExists) return;

    formik.setValues((prevValues) => ({
      ...prevValues,
      extras: [
        ...prevValues.extras,
        {
          name: extraNameTrimmed,
          unit: extraUnit,
          value: "",
        },
      ],
    }));

    setExtraName("");
    setExtraUnit(Chargeable_Units_Enum.Acre);
  };

  return (
    <VStack align="unset">
      <Text fontWeight="medium">Add extras</Text>
      <HStack align="end">
        <FormControl isRequired>
          <FormLabel color="gray.500" fontSize="sm">
            Extra name
          </FormLabel>
          <Input
            placeholder="e.g. Imaging area"
            value={extraName}
            onChange={(e) => setExtraName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel color="gray.500" fontSize="sm">
            Select unit
          </FormLabel>
          <Select
            value={extraUnit}
            onChange={(e) =>
              setExtraUnit(e.target.value as Chargeable_Units_Enum)
            }
          >
            {Object.values(Chargeable_Units_Enum).map((unit) => (
              <option key={unit} value={unit}>
                {unit.replaceAll("_", " ")}
              </option>
            ))}
          </Select>
        </FormControl>
        <Button type="submit" onClick={onAddExtra}>
          Add
        </Button>
      </HStack>

      {!!formik.values.extras.length && (
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Value</Th>
              <Th>Unit</Th>
              <Th width="1%" whiteSpace="nowrap">
                <Text as={VisuallyHidden}>Actions</Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {formik.values.extras.map((extra, i) => (
              <Tr key={(extra.id || extra.name) + i}>
                <Td>{extra.name}</Td>
                <Td>
                  <Input
                    type="number"
                    placeholder="value"
                    value={extra.value || ""}
                    onChange={(e) => {
                      let extraIndex = formik.values.extras.findIndex(
                        (ex) => ex.name === extra.name
                      );
                      if (extraIndex < 0) return;

                      const newExtras = formik.values.extras.slice();
                      newExtras[extraIndex] = {
                        ...newExtras[extraIndex],
                        value: e.target.value.trim(),
                      };

                      formik.setValues((prevValues) => ({
                        ...prevValues,
                        extras: newExtras,
                      }));
                    }}
                  />
                </Td>
                <Td>
                  <Select
                    value={extra.unit || Chargeable_Units_Enum.Acre}
                    onChange={(e) => {
                      let extraIndex = formik.values.extras.findIndex(
                        (ex) => ex.name === extra.name
                      );
                      if (extraIndex < 0) return;

                      const newExtras = formik.values.extras.slice();
                      newExtras[extraIndex] = {
                        ...newExtras[extraIndex],
                        unit: e.target.value as Chargeable_Units_Enum,
                      };

                      formik.setValues((prevValues) => ({
                        ...prevValues,
                        extras: newExtras,
                      }));
                    }}
                  >
                    {Object.values(Chargeable_Units_Enum).map((unit) => (
                      <option key={unit} value={unit}>
                        {unit.replaceAll("_", " ")}
                      </option>
                    ))}
                  </Select>
                </Td>
                <Td>
                  <IconButton
                    aria-label="remove extra"
                    variant="ghost"
                    icon={<IconX />}
                    onClick={() =>
                      formik.setValues((prevValues) => ({
                        ...prevValues,
                        extras: prevValues.extras.filter(
                          (ex) => ex.name !== extra.name
                        ),
                      }))
                    }
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </VStack>
  );
};
