import React, { ChangeEvent, useState } from "react";
import { useClient } from "urql";
import {
  Link as RouteLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { parseFormData } from "parse-nested-form-data";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  Text,
  VStack,
  Link,
} from "@chakra-ui/react";
import { API_LOGIN_USER_QUERY } from "./login.graphql";
import {
  ApiLoginUserQuery,
  ApiLoginUserQueryVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { API_ERRORS } from "@farmevo/common/dist/constants/api-errors";
import { useSession } from "../../hooks/useSession";
import logoFull from "../../assets/logo-full.svg";

type Props = {};

type LoginFormData = {
  email: string;
  password: string;
};

const Login = (props: Props) => {
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const urqlClient = useClient();
  const { setSession } = useSession();

  const [params] = useSearchParams();
  const redirectRoute = params.get("redirect") || "";

  const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    const formData = parseFormData(new FormData(e.target)) as LoginFormData;

    const tokenResult = await urqlClient.executeQuery<
      ApiLoginUserQuery,
      ApiLoginUserQueryVariables
    >(
      {
        key: 252346,
        query: API_LOGIN_USER_QUERY,
        variables: {
          input: {
            username: formData.email,
            password: formData.password,
          },
        },
      },
      {
        requestPolicy: "network-only",
      }
    );

    const token = tokenResult.data?.api_login_user?.token;
    const id = tokenResult.data?.api_login_user?.id;
    if (
      tokenResult.error?.message ===
      `[GraphQL] ${API_ERRORS.INCORRECT_USERNAME_PASSWORD}`
    ) {
      setError("Incorrect email or password");
    } else if (tokenResult.error || !token || !id) {
      setError("An unknown error occurred");
    } else {
      setSession({
        token,
        id,
      });
      navigate(redirectRoute ? { pathname: redirectRoute } : "/");
    }
    setIsSubmitting(false);
  };

  return (
    <Box textAlign="center" maxW="container.sm" mx="auto" py={8} mt={16}>
      <Image src={logoFull} width={64} mx="auto" />
      <Text fontWeight="bold" fontSize="2xl" mt={10} mb={6}>
        Login
      </Text>
      <form onSubmit={onSubmit}>
        <VStack spacing={5}>
          {error && (
            <Text
              textDecoration="underline"
              fontSize="sm"
              w="full"
              textAlign="left"
              color="red.500"
            >
              {error}
            </Text>
          )}
          <FormControl isRequired>
            <FormLabel>Email:</FormLabel>
            <Input
              name="email"
              type="text"
              placeholder="e.g. john@example.com"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Password:</FormLabel>
            <Input
              name="password"
              type="password"
              placeholder="Enter Password"
            />
          </FormControl>
          <Button type="submit" isLoading={isSubmitting}>
            Login
          </Button>
          <Box>
            Don't have an account?{" "}
            <Link
              as={RouteLink}
              to="/signup"
              color="brand.500"
              fontWeight="bold"
            >
              Sign Up
            </Link>
          </Box>
        </VStack>
      </form>
    </Box>
  );
};

export default Login;
