import { useState } from "react";
import { Box, Button, Flex, useDisclosure } from "@chakra-ui/react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  ArrowLeft as IconArrowLeft,
  ArrowRight as IconArrowRight,
} from "react-feather";
import LayoutLoading from "../../Components/LayoutLoading";
import TopNav from "../../Components/TopNav";
import SideNav from "../../Components/SideNav";
import Welcome from "../Welcome";
import Account from "../Account";
import Products from "../Products";
import Implements from "../Implements";
import Vehicles from "../Vehicles";
import Contractors from "../Contractors";
import TeamMembers from "../TeamMembers";
import Timesheets from "../Timesheets";
import Operations from "../Operations";
import Fields from "../Fields";
import JobsTable from "../Jobs/JobsTable";
import JobDetails from "../Jobs/JobDetails";
import AddJob from "../Jobs/AddJob";
import { useSession } from "../../hooks/useSession";
import { SideNavContext } from "../../hooks/useSideNav";
import { GoogleApiTokenContext } from "../../hooks/useGoogleApiToken";
import Inventory from "../Inventory";
import NotFound from "../NotFound";
import Customers from "../Customers";
import Warehouses from "../Warehouses";

const Dashboard = () => {
  const [tokenResponse, setTokenResponse] =
    useState<google.accounts.oauth2.TokenResponse | null>(null);
  const { session, sessionPrefs, setSessionPrefs, fetchingSession } =
    useSession();
  const sideNavDisclosure = useDisclosure({
    defaultIsOpen: !!sessionPrefs ? sessionPrefs.isSideNavOpen : true,
    onClose() {
      setSessionPrefs({
        isSideNavOpen: false,
      });
    },
    onOpen() {
      setSessionPrefs({
        isSideNavOpen: true,
      });
    },
  });

  if (fetchingSession || !session?.user) {
    return <LayoutLoading />;
  }

  const isOperator = session.user.isOperator;
  const isManager = session.user.isManager;
  const isContractor = session.user.isContractor;

  if (
    session &&
    session.user &&
    (!session.user.isEmailVerified ||
      (!isOperator &&
        !isManager &&
        (!session.user.prefs || !session.user.profile)))
  ) {
    return <Navigate to="/onboard" />;
  }

  return (
    <Box minH="calc(100vh - 64px)">
      <TopNav />
      <Flex>
        <SideNav
          isOpen={sideNavDisclosure.isOpen}
          {...sideNavDisclosure.getDisclosureProps()}
        />
        <SideNavContext.Provider value={sideNavDisclosure}>
          <GoogleApiTokenContext.Provider
            value={{
              token: tokenResponse,
              setToken: setTokenResponse,
            }}
          >
            <Box flex={1}>
              <Routes>
                <Route path="/jobs">
                  <Route index={true} element={<JobsTable />} />
                  <Route path=":jobId" element={<JobDetails />} />
                  <Route path=":jobId/edit" element={<AddJob />} />
                  {!isOperator && <Route path="new" element={<AddJob />} />}
                </Route>
                {!isOperator && (
                  <>
                    <Route path="/fields" element={<Fields />} />
                    <Route path="/operations" element={<Operations />} />
                    <Route path="/timesheets" element={<Timesheets />} />
                    <Route path="/members" element={<TeamMembers />} />
                    {isContractor ? (
                      <Route path="/customers" element={<Customers />} />
                    ) : (
                      <Route path="/contractors" element={<Contractors />} />
                    )}
                    <Route path="/vehicles" element={<Vehicles />} />
                    <Route path="/implements" element={<Implements />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/inventory" element={<Inventory />} />
                    <Route path="/warehouses" element={<Warehouses />} />
                    <Route path="/account" element={<Account />} />
                  </>
                )}
                <Route path="/" element={<Welcome />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Box>
          </GoogleApiTokenContext.Provider>
        </SideNavContext.Provider>
      </Flex>
      <Button
        position="fixed"
        bottom="1em"
        left="0.4em"
        {...sideNavDisclosure.getButtonProps()}
      >
        {sideNavDisclosure.isOpen ? <IconArrowLeft /> : <IconArrowRight />}
      </Button>
    </Box>
  );
};

export default Dashboard;
