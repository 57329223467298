import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useQuery } from "urql";
import * as Yup from "yup";
import { useApi } from "../../hooks/useApi";
import { GET_MY_TEAM_MEMBERS } from "../TeamMembers/teams.graphql";
import { useSession } from "../../hooks/useSession";
import {
  GetMyTeamMembersQuery,
  GetMyTeamMembersQueryVariables,
  Roles_Enum,
} from "@farmevo/common/dist/graphql/graphql";
import { IconPlus, IconSearch } from "../../Components/Icons";
import { API_ERRORS } from "@farmevo/common/dist/constants/api-errors";

const inviteMemberSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required."),
});

const Contractors = () => {
  const { session } = useSession();
  const api = useApi();

  const userId = session?.id;

  const toast = useToast();

  const [{ data: teamMembersData, fetching }] = useQuery<
    GetMyTeamMembersQuery,
    GetMyTeamMembersQueryVariables
  >({
    query: GET_MY_TEAM_MEMBERS,
    variables: { userId: userId || -1, roles: [Roles_Enum.Contractor] },
    pause: !userId,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: inviteMemberSchema,
    onSubmit: async (values) => {
      try {
        const response = await api.post("auth/invite", {
          ...values,
          role: Roles_Enum.Contractor,
        });
        if (response.ok) {
          toast({
            title: "Invite sent",
            description: "Your invite has been sent to " + values.email,
            isClosable: true,
            status: "success",
          });
          onClose();
        } else {
          const error = await response.text();
          toast({
            title: "Error occured while sending the invite",
            description:
              error === API_ERRORS.USER_ALREADY_EXISTS && "User already exists",
            status: "error",
          });
        }
      } catch (error) {
        Promise.reject(error);
      }
    },
  });

  if (fetching) {
    return <Spinner />;
  }

  return (
    <VStack p={10} align="unset">
      <Text fontSize="2xl" fontWeight="bold">
        Contractors
      </Text>
      <Box>
        <HStack justifyContent="space-between">
          <InputGroup maxW="container.sm">
            <Input placeholder="Search for contractors" />
            <InputRightElement>
              <IconSearch />
            </InputRightElement>
          </InputGroup>
          <Box>
            <Button
              mr="5"
              size="md"
              colorScheme="brand"
              onClick={onOpen}
              leftIcon={<IconPlus />}
            >
              Invite Contractor
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Invite contractor</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <form onSubmit={formik.handleSubmit}>
                    <FormControl
                      isInvalid={!!formik.errors.email}
                      my={4}
                      isRequired
                    >
                      <FormLabel htmlFor="email">Contractor Email</FormLabel>
                      <Input
                        {...formik.getFieldProps("email")}
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter the email of contractor you'd like to invite"
                      />
                      <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    </FormControl>
                    <ModalFooter>
                      <Button
                        isLoading={formik.isSubmitting}
                        colorScheme="brand"
                        isDisabled={formik.isSubmitting || !formik.isValid}
                        type="submit"
                      >
                        Invite
                      </Button>
                    </ModalFooter>
                  </form>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        </HStack>
      </Box>
      <Box>
        {teamMembersData?.team_members.length ? (
          <TableContainer whiteSpace="normal">
            <Table>
              <Thead>
                <Tr>
                  <Th>User</Th>
                </Tr>
              </Thead>
              <Tbody>
                {teamMembersData?.team_members
                  .filter(
                    (member) =>
                      !!member.teamMember.roles.find(
                        (userRole) => userRole.role === Roles_Enum.Contractor
                      )
                  )
                  .map((member) => {
                    return (
                      <Tr key={member.teamMember.id}>
                        <Td>{member.teamMember.username}</Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Box>
            <Text ml="5">
              <b>You do not have any contractors.</b>
            </Text>
          </Box>
        )}
      </Box>
    </VStack>
  );
};

export default Contractors;
