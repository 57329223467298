import { gql } from "urql";
import { GetFieldSnapshotsQuery } from "@farmevo/common/dist/graphql/graphql";

export type FieldShot = GetFieldSnapshotsQuery["field_season_shot"][0];

export const GET_FARMS_QUERY = gql`
  query GetFarms($userId: bigint!) {
    farms(where: { userId: { _eq: $userId } }) {
      id
      name
    }
  }
`;

export const GET_FIELDS_QUERY = gql`
  query GetFields($filter: field_season_shot_bool_exp!) {
    field_season_shot(
      order_by: [{ fieldId: asc }, { id: desc }]
      distinct_on: fieldId
      where: $filter
    ) {
      id
      name
      notes
      farm {
        id
        userId
      }
      season
      fieldId
      cropName
      description
      ownershipType
      polygon {
        id
        size
        geometry
      }
    }
  }
`;

export const GET_FIELD_SNAPSHOTS_QUERY = gql`
  query GetFieldSnapshots($userId: bigint!, $fieldId: bigint!) {
    field_season_shot(
      order_by: [{ fieldId: asc }, { id: desc }]
      where: { fieldId: { _eq: $fieldId }, farm: { userId: { _eq: $userId } } }
    ) {
      id
      name
      notes
      farm {
        id
        userId
      }
      season
      fieldId
      cropName
      description
      ownershipType
      polygon {
        id
        size
        geometry
      }
    }
  }
`;

export const GET_SNAPSHOT_REPORTS_QUERY = gql`
  query GetSnapshotReports($shotId: bigint!) {
    reports(
      where: { seasonShotId: { _eq: $shotId } }
      order_by: { createdAt: desc }
    ) {
      id
      name
      link
      tag
      createdAt
    }
  }
`;

export const INSERT_FARM_MUTATION = gql`
  mutation InsertFarm($farm: farms_insert_input!) {
    insert_farms_one(object: $farm) {
      id
      name
    }
  }
`;

export const INSERT_FIELD_SEASON_SHOT_MUTATION = gql`
  mutation InsertFieldSeasonShot($shot: field_season_shot_insert_input!) {
    insert_field_season_shot_one(object: $shot) {
      id
      name
      notes
      farm {
        id
        userId
      }
      season
      fieldId
      cropName
      description
      ownershipType
      polygon {
        id
        size
        geometry
      }
    }
  }
`;

export const SAVE_FIELD_SEASON_SHOT_AND_POLYGON_MUTATION = gql`
  mutation SaveFieldShotAndPolygon(
    $shotId: bigint!
    $polygonId: bigint!
    $shot: field_season_shot_set_input!
    $polygon: polygons_set_input!
  ) {
    update_polygons_by_pk(pk_columns: { id: $polygonId }, _set: $polygon) {
      id
    }
    update_field_season_shot_by_pk(pk_columns: { id: $shotId }, _set: $shot) {
      id
      name
      notes
      farm {
        id
        userId
      }
      season
      fieldId
      cropName
      description
      ownershipType
      polygon {
        id
        size
        geometry
      }
    }
  }
`;

export const SAVE_FIELD_SEASON_SHOT_MUTATION = gql`
  mutation SaveFieldSeasonShot(
    $shotId: bigint!
    $shot: field_season_shot_set_input!
  ) {
    update_field_season_shot_by_pk(pk_columns: { id: $shotId }, _set: $shot) {
      id
      name
      notes
      farm {
        id
        userId
      }
      season
      fieldId
      cropName
      description
      ownershipType
      polygon {
        id
        size
        geometry
      }
    }
  }
`;

export const SAVE_SNAPSHOT_REPORT_MUTATION = gql`
  mutation SaveSnapshotReport($report: reports_insert_input!) {
    insert_reports_one(
      object: $report
      on_conflict: { constraint: reports_pkey, update_columns: [link] }
    ) {
      id
      name
      link
      tag
      createdAt
    }
  }
`;

export const GET_SNAPSHOT_JOBS_QUERY = gql`
  query GetSnapshotJobs($shotId: bigint!) {
    job_fields(where: { seasonShotId: { _eq: $shotId } }) {
      jobId
      job {
        id
        operationName
        from
        status
      }
    }
  }
`;
