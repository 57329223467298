import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import LayoutOnboarding from "../../Components/LayoutOnboarding";
import ComboSelect from "../../Components/ComboSelect";
import { Country, countries } from "../../constants/country";
import { useSession } from "../../hooks/useSession";
import { CompleteForm } from "./onboard.graphql";

type Props = {
  formData: Partial<CompleteForm> | null;
};

const countriesFilter = (inputValue: string) => {
  return (item: Country) => {
    const lowered = inputValue.toLowerCase();
    const str = item.name.toLowerCase() + item.code3.toLowerCase();
    return !inputValue || str.includes(lowered);
  };
};

const countryToString = (item: Country | null) => (!item ? "" : `${item.name}`);

const Business = ({ formData }: Props) => {
  const { session } = useSession();
  const firstName = session?.user?.firstName || "";
  const email = session?.user?.email || "";

  return (
    <LayoutOnboarding
      title={`Hi ${firstName}, tell us about your business`}
      subtitle="This is an initial information of your business. You can change it later."
    >
      <Box flex={1} mb={10}>
        <Text fontSize="xl" fontWeight="semibold" mb={4}>
          General details
        </Text>
        <VStack spacing={5}>
          <FormControl isRequired>
            <FormLabel>Email Address</FormLabel>
            <Input type="text" id="email" name="email" value={email} disabled />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Company name</FormLabel>
            <Input
              type="text"
              id="companyName"
              name="companyName"
              placeholder="e.g. Douglas Farms Pvt Ltd"
              defaultValue={formData?.companyName}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Phone</FormLabel>
            <Input
              type="text"
              id="phone"
              name="phone"
              placeholder="e.g. +1 444 1234567"
              defaultValue={formData?.phone}
            />
          </FormControl>
        </VStack>
      </Box>

      <Box flex={1}>
        <Text fontSize="xl" fontWeight="semibold" mb={4}>
          Company address
        </Text>
        <VStack spacing={5}>
          <FormControl isRequired>
            <FormLabel>Address line 1</FormLabel>
            <Input
              type="text"
              id="address1"
              name="address1"
              placeholder="e.g. Farmers Road"
              defaultValue={formData?.address1}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Address line 2</FormLabel>
            <Input
              type="text"
              id="address2"
              name="address2"
              placeholder="e.g. 45 Avenue"
              defaultValue={formData?.address2}
            />
          </FormControl>

          <HStack w="full">
            <FormControl>
              <FormLabel>Town/City</FormLabel>
              <Input
                type="text"
                id="city"
                name="city"
                placeholder="e.g. Pembroke"
                defaultValue={formData?.city}
              />
            </FormControl>
            <FormControl>
              <FormLabel>State/County</FormLabel>
              <Input
                type="text"
                id="state"
                name="state"
                placeholder="e.g. Sehar County"
                defaultValue={formData?.state}
              />
            </FormControl>
          </HStack>

          <HStack w="full">
            <ComboSelect<Country>
              items={countries}
              itemsFilter={countriesFilter}
              itemToString={countryToString}
              formControlProps={{ isRequired: true }}
              label="Country"
              inputProps={{
                id: "country",
                name: "country",
                placeholder: "Select country",
                defaultValue: formData?.country,
              }}
              menuProps={{
                maxH: "200px",
              }}
              renderItem={(item) => <span>{item?.name}</span>}
            />
            <FormControl isRequired>
              <FormLabel>Postal code</FormLabel>
              <Input
                type="text"
                id="postal"
                name="postal"
                placeholder="e.g. 44525"
                defaultValue={formData?.postal}
              />
            </FormControl>
          </HStack>
        </VStack>
      </Box>
    </LayoutOnboarding>
  );
};

export default Business;
