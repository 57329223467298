import { gql } from "urql";

export const GET_MY_OPERATIONS = gql`
  query GetMyOperations($userId: Int!) {
    operations(where: { userId: { _eq: $userId } }) {
      billingRate
      billingUnit
      description
      createdAt
      id
      name
      status
      type
      userId
      products {
        productId
        operationId
        product {
          id
          name
        }
      }
      extras {
        id
        name
        value
        unit
      }
    }
  }
`;

export const UPDATE_MY_OPERATION = gql`
  mutation UpdateMyOperation(
    $id: bigint!
    $updateOperationPayload: operations_set_input!
    $newProducts: [operation_products_insert_input!]!
    $newExtras: [operation_extras_insert_input!]!
    $updatedExtras: [operation_extras_updates!]!
    $deletedProductIds: [uuid!]
    $deletedExtraIds: [uuid!]
  ) {
    delete_operation_products(
      where: { productId: { _in: $deletedProductIds } }
    ) {
      affected_rows
    }
    delete_operation_extras(where: { id: { _in: $deletedExtraIds } }) {
      affected_rows
    }
    insert_operation_products(objects: $newProducts) {
      affected_rows
    }
    insert_operation_extras(
      objects: $newExtras
      on_conflict: {
        constraint: operation_extras_id_name_key
        update_columns: [value, unit]
      }
    ) {
      affected_rows
    }
    update_operation_extras_many(updates: $updatedExtras) {
      affected_rows
    }
    update_operations_by_pk(
      pk_columns: { id: $id }
      _set: $updateOperationPayload
    ) {
      billingRate
      billingUnit
      description
      createdAt
      id
      name
      status
      type
      userId
      products {
        productId
        operationId
        product {
          id
          name
        }
      }
      extras {
        name
        value
        unit
        id
      }
    }
  }
`;

export const UPSERT_MY_OPERATION_EXTRA = gql`
  mutation UpsertMyOperationExtra(
    $upsertOperationExtraPayload: operation_extras_insert_input!
  ) {
    insert_operation_extras_one(
      object: $upsertOperationExtraPayload
      on_conflict: {
        constraint: operation_extras_pkey
        update_columns: [unit, value]
      }
    ) {
      id
    }
  }
`;

export const DELETE_MY_OPERATION_EXTRA = gql`
  mutation DeleteMyOperationExtra($id: uuid!) {
    delete_operation_extras_by_pk(id: $id) {
      operationId
    }
  }
`;

export const DELETE_MY_OPERATION_PRODUCT = gql`
  mutation DeleteMyOperationProduct($operationId: bigint!, $productId: uuid!) {
    delete_operation_products_by_pk(
      operationId: $operationId
      productId: $productId
    ) {
      product {
        chargeableUnit
        id
        name
        pricePerUnit
        status
        userId
      }
      operationId
      productId
      userId
    }
  }
`;

export const SAVE_MY_OPERATION_PRODUCT = gql`
  mutation SaveMyOperationProduct(
    $saveOperationProductPayload: operation_products_insert_input!
  ) {
    insert_operation_products_one(object: $saveOperationProductPayload) {
      operationId
      productId
      userId
      product {
        chargeableUnit
        id
        name
        pricePerUnit
        status
        userId
      }
    }
  }
`;

export const INSERT_MY_OPERATION = gql`
  mutation InsertMyOperation($saveOperationPayload: operations_insert_input!) {
    insert_operations_one(object: $saveOperationPayload) {
      billingRate
      billingUnit
      description
      createdAt
      id
      name
      status
      type
      userId
      products {
        productId
        operationId
        product {
          id
          name
        }
      }
      extras {
        name
        value
        unit
        id
      }
    }
  }
`;
