import { Exchange } from "urql";
import { cacheExchange } from "@urql/exchange-graphcache";
import { getIntrospectedSchema } from "@urql/introspection";

import { keys } from "./keys";
import { resolvers } from "./resolvers";
import { updates } from "./updates";
import { optimistic } from "./optimistic";

import schema from "@farmevo/common/src/graphql/schema.json";

export const cache = () => {
  return cacheExchange({
    schema: getIntrospectedSchema(JSON.stringify(schema)),
    keys,
    resolvers,
    updates,
    optimistic,
  }) as Exchange;
};
