import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  Progress,
  Select,
  SkeletonText,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import { useQuery } from "urql";
import { useSession } from "@farmevo/web/src/hooks/useSession";
import { IconPlus } from "../../../Components/Icons";
import Button from "../../../Components/Button";
import ComboSelect from "../../../Components/ComboSelect";
import { LoadingSkeleton } from "../../../Components/LoadingSkeleton";
import {
  GetJobsQuery,
  GetJobsQueryVariables,
  GetOperatorsQuery,
  GetOperatorsQueryVariables,
  Job_Status_Enum,
} from "@farmevo/common/dist/graphql/graphql";
import { JobStatusIcon } from "@farmevo/web/src/Components/JobStatusIcon";
import { usePaginatedUrqlQuery } from "@farmevo/web/src/hooks/usePaginatedQuery";
import { GET_JOBS_QUERY } from "./jobs-table.graphql";
import { GET_OPERATORS_QUERY } from "../AddJob/addjob.graphql";

const JobsTable = () => {
  const { session } = useSession();
  const navigate = useNavigate();
  const [selectedOperation, setSelectedOperation] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [selectedOperator, setSelectedOperator] = useState<
    GetOperatorsQuery["team_members"][0] | null
  >(null);
  const [selectedFrom, setSelectedFrom] = useState("");
  const [selectedTo, setSelectedTo] = useState("");

  const isOperator = !!session?.user?.isOperator;
  const [{ data: operatorsData, fetching: loadingOperators }] = useQuery<
    GetOperatorsQuery,
    GetOperatorsQueryVariables
  >({
    query: GET_OPERATORS_QUERY,
    variables: {
      userId: session?.id || -1,
    },
    pause: !session?.id || isOperator,
  });

  const {
    data: jobsData,
    fetching: loadingJobs,
    fetchMore,
    isFetchingMore,
  } = usePaginatedUrqlQuery<GetJobsQuery, GetJobsQueryVariables>(
    GET_JOBS_QUERY,
    {
      variables: {
        filter: {
          ...(selectedOperation && {
            operationName: { _eq: selectedOperation },
          }),
          ...(selectedStatus && {
            status: { _eq: selectedStatus as Job_Status_Enum },
          }),
          ...(selectedOperator && {
            operators: { userId: { _eq: selectedOperator.teamMember.id } },
          }),
          ...(selectedFrom && {
            from: { _gte: new Date(selectedFrom).toISOString() },
          }),
          ...(selectedTo && {
            to: { _lte: new Date(selectedTo).toISOString() },
          }),
          ...(isOperator
            ? {
                operators: { userId: { _eq: session?.id || -1 } },
              }
            : {
                userId: { _eq: session?.id || -1 },
              }),
        },
        limit: 10,
      },
      pause: !session?.id,
    },
    {
      getLength(data) {
        return data.count.aggregate?.count || data.jobs.length;
      },
    }
  );

  const canFetchMore =
    (jobsData?.jobs.length || 0) < (jobsData?.count.aggregate?.count || 0);

  const onAddJob = () => {
    navigate("/jobs/new");
  };

  return (
    <VStack p={10} align="unset" spacing={4}>
      <Text fontWeight="bold" fontSize="2xl">
        Jobs
      </Text>
      <HStack align="end">
        <FormControl>
          <FormLabel color="gray.500">Status</FormLabel>
          <Select
            value={selectedStatus || ""}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value="">All</option>
            {Object.values(Job_Status_Enum).map((status) => (
              <option key={status} value={status}>
                {status[0].toUpperCase() + status.replaceAll("_", " ").slice(1)}
              </option>
            ))}
          </Select>
        </FormControl>
        <ComboSelect<string>
          items={jobsData?.operations.nodes.map((n) => n.operationName)}
          itemsFilter={(v) => (operationName) => operationName.includes(v)}
          itemToString={(operationName) => operationName || ""}
          label="Operation"
          searchBehavior="reduce"
          labelProps={{ htmlFor: "operation", color: "gray.500" }}
          labelRightElement={
            selectedOperation && (
              <Button
                variant="link"
                colorScheme="brand"
                onClick={() => setSelectedOperation("")}
              >
                Clear
              </Button>
            )
          }
          inputProps={{
            id: "operation",
            name: "operation",
            placeholder: "Select operation",
          }}
          defaultSelectedItem={selectedOperation}
          onSelectItem={(operation) => setSelectedOperation(operation || "")}
          renderItem={(item) => <span>{item}</span>}
          menuProps={{ maxH: "200px" }}
          renderEmptyState={() =>
            loadingJobs && (
              <Progress
                mt="-1px"
                position="absolute"
                width="calc(100% - 10px)"
                ml="5px"
                size="xs"
                height="1.25px"
                bgColor="unset"
                colorScheme="brand"
                isIndeterminate
              />
            )
          }
        />
        <ComboSelect<GetOperatorsQuery["team_members"][0]>
          items={operatorsData?.team_members}
          itemsFilter={(v) => (operator) =>
            operator.teamMember.firstName
              .toLowerCase()
              .includes(v.toLowerCase()) ||
            !!operator.teamMember.lastName
              ?.toLowerCase()
              .includes(v.toLowerCase())}
          itemToString={(operator) => operator?.teamMember.firstName || ""}
          label="Operator"
          searchBehavior="reduce"
          labelProps={{ htmlFor: "operator", color: "gray.500" }}
          labelRightElement={
            selectedOperator && (
              <Button
                variant="link"
                colorScheme="brand"
                onClick={() => setSelectedOperator(null)}
              >
                Clear
              </Button>
            )
          }
          inputProps={{
            id: "operator",
            name: "operator",
            placeholder: "Select operator",
          }}
          defaultSelectedItem={selectedOperator}
          onSelectItem={(operator) => setSelectedOperator(operator || null)}
          renderItem={(item) => <span>{item?.teamMember.firstName}</span>}
          menuProps={{ maxH: "200px" }}
          renderEmptyState={() =>
            loadingOperators && (
              <Progress
                mt="-1px"
                position="absolute"
                width="calc(100% - 10px)"
                ml="5px"
                size="xs"
                height="1.25px"
                bgColor="unset"
                colorScheme="brand"
                isIndeterminate
              />
            )
          }
        />
        <FormControl>
          <HStack justify="space-between" align="baseline">
            <FormLabel color="gray.500">From</FormLabel>
            {selectedFrom && (
              <Button
                size="sm"
                colorScheme="brand"
                variant="link"
                onClick={() => setSelectedFrom("")}
              >
                Clear
              </Button>
            )}
          </HStack>
          <Input
            type="date"
            value={selectedFrom}
            onChange={(e) => setSelectedFrom(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <HStack justify="space-between" align="baseline">
            <FormLabel color="gray.500">To</FormLabel>
            {selectedTo && (
              <Button
                size="sm"
                colorScheme="brand"
                variant="link"
                onClick={() => setSelectedTo("")}
              >
                Clear
              </Button>
            )}
          </HStack>
          <Input
            type="date"
            value={selectedTo}
            onChange={(e) => setSelectedTo(e.target.value)}
          />
        </FormControl>
        <Box>
          <Button leftIcon={<IconPlus />} onClick={onAddJob}>
            Add Job
          </Button>
        </Box>
      </HStack>
      {loadingJobs ? (
        <LoadingSkeleton />
      ) : jobsData?.jobs && jobsData.jobs.length > 0 ? (
        <>
          <Table>
            <Thead>
              <Tr>
                <Th>JOB ID</Th>
                <Th>Operation</Th>
                {!isOperator && <Th>Contractor</Th>}
                <Th>From - to</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {jobsData.jobs.map((job) => (
                <Tr _hover={{ bgColor: "brand.50" }} key={job.id}>
                  <Td>
                    <Link
                      color="blue.600"
                      as={RouteLink}
                      to={`/jobs/${job.id}`}
                    >
                      #{job.id}
                    </Link>
                  </Td>
                  <Td>{job.operationName}</Td>
                  {job.assignedUser && <Td>{job.assignedUser.firstName}</Td>}
                  <Td>
                    <HStack>
                      <Tooltip label={job.from?.toLocaleString()}>
                        <Text>
                          {job.from?.toLocaleDateString().slice(0, -5)}
                        </Text>
                      </Tooltip>
                      {job.to && <Text>-</Text>}
                      <Tooltip label={job.to?.toLocaleString()}>
                        <Text>{job.to?.toLocaleDateString().slice(0, -5)}</Text>
                      </Tooltip>
                    </HStack>
                  </Td>
                  <Td>
                    <HStack>
                      <JobStatusIcon status={job.status} hideTooltip />
                      <Text textTransform="capitalize">
                        {job.status.replace("_", " ")}
                      </Text>
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {isFetchingMore ? (
            <SkeletonText noOfLines={1} />
          ) : (
            <HStack>
              <Text>
                Showing {jobsData.jobs.length} of{" "}
                {jobsData.count.aggregate?.count} jobs
              </Text>
              {canFetchMore && (
                <Button variant="link" colorScheme="brand" onClick={fetchMore}>
                  Load more
                </Button>
              )}
            </HStack>
          )}
        </>
      ) : (
        <VStack align="start" py={4}>
          <Text fontWeight="semibold" fontSize="lg">
            No jobs yet.
          </Text>
          <Text>
            All of your jobs appear here. Click the "+ Add" button to create a
            new job
          </Text>
        </VStack>
      )}
    </VStack>
  );
};

export default JobsTable;
