import { VStack } from "@chakra-ui/react";
import { useQuery } from "urql";
import { GET_REPORT_BY_TAG_QUERY } from "./reports.graphql";
import {
  GetReportByTagQuery,
  GetReportByTagQueryVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { useParams } from "react-router-dom";
import LayoutLoading from "../../Components/LayoutLoading";
import NotFound from "../NotFound";
import CropGerminationReport from "./CropGerminationReport";

const Reports = () => {
  const params = useParams();

  const [{ fetching, data }] = useQuery<
    GetReportByTagQuery,
    GetReportByTagQueryVariables
  >({
    query: GET_REPORT_BY_TAG_QUERY,
    variables: {
      tag: params.tag,
    },
    requestPolicy: "network-only",
  });

  const report = data?.reports[0];

  if (fetching) {
    return <LayoutLoading />;
  }

  if (!report) {
    return <NotFound />;
  }

  return (
    <VStack p={10} align="unset" spacing={4}>
      <CropGerminationReport report={report} />
    </VStack>
  );
};

export default Reports;
