export const IconJobPendingCompletion = ({
  size,
  color,
}: {
  size: string;
  color: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill={color}
    width={size}
    height={size}
  >
    <path
      d="M5.27,19.4A10,10,0,0,1,16.07,2.87"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M11.09,2h1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M15.85,2.77A10,10,0,1,1,2,12a10.66,10.66,0,0,1,.08-1.26"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      strokeDasharray="1.93 3.86"
    />
    <path
      d="M2.5,8.86c.11-.31.23-.62.37-.93"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M14.91,7.86V9.39a.91.91,0,0,1-.35.72l-5.12,4a.91.91,0,0,0-.35.72v1.34"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M9.09,7.86V9.39a.91.91,0,0,0,.35.72l5.12,4a.91.91,0,0,1,.35.72v1.34"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <line
      x1="8.04"
      y1="7.36"
      x2="15.96"
      y2="7.36"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <line
      x1="8.04"
      y1="16.64"
      x2="15.96"
      y2="16.64"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);
