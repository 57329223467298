import { gql } from "urql";

export type TaxRate = {
  name: string;
  rate: string;
};

export type CompleteForm = {
  email: string;
  companyName: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postal: string;
  currency: string;
  timezone: string;
  unit: string;
  color: string;
  latLng: string;
  invoiceDays: string;
  paymentInfo: string;
  taxName: string;
  salesTaxNumber: string;
  rates: TaxRate[];
};

export const SAVE_USER_PROFILE_MUTATION = gql`
  mutation SaveUserProfile(
    $userProfile: user_profiles_insert_input!
    $userPrefs: user_prefs_insert_input!
    $rates: [user_tax_rates_insert_input!]!
  ) {
    insert_user_profiles_one(
      object: $userProfile
      on_conflict: {
        constraint: user_profiles_pkey
        update_columns: [
          companyName
          phone
          address1
          address2
          city
          country
          postalCode
          state
          taxNumber
        ]
      }
    ) {
      userId
    }
    insert_user_prefs_one(
      object: $userPrefs
      on_conflict: {
        constraint: user_prefs_pkey
        update_columns: [
          currency
          fieldColor
          fieldUnit
          geoLocation
          invoicePayInfo
          invoiceTerms
          timezone
        ]
      }
    ) {
      userId
    }
    insert_user_tax_rates(
      objects: $rates
      on_conflict: { constraint: user_tax_rates_pkey, update_columns: [rate] }
    ) {
      affected_rows
    }
  }
`;
