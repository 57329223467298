import { Text, VStack } from "@chakra-ui/react";

const Customers = () => (
  <VStack p={10} align="unset" spacing={4}>
    <Text fontWeight="bold" fontSize="2xl">
      Customers
    </Text>
  </VStack>
);

export default Customers;
