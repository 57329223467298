import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  GetSnapshotReportsQuery,
  GetSnapshotReportsQueryVariables,
  SaveSnapshotReportMutation,
  SaveSnapshotReportMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { GET_SNAPSHOT_REPORTS_QUERY } from "@farmevo/web/src/Pages/Fields/fields.graphql";

export const insert_reports_one: UpdateResolver = (result, args, cache) => {
  const variables =
    args.object as SaveSnapshotReportMutationVariables["report"];
  const newReport = result as SaveSnapshotReportMutation;

  cache.updateQuery<GetSnapshotReportsQuery, GetSnapshotReportsQueryVariables>(
    {
      query: GET_SNAPSHOT_REPORTS_QUERY,
      variables: { shotId: variables.seasonShotId },
    },
    (data) => {
      if (newReport.insert_reports_one) {
        data?.reports.push(newReport.insert_reports_one);
      }
      return data;
    }
  );
};
