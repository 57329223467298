import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  GetJobDetailsQuery,
  GetJobDetailsQueryVariables,
  GetMyUserDetailsQuery,
  GetMyUserDetailsQueryVariables,
  Job_Extras_Updates,
  Job_Products_Updates,
  Roles_Enum,
  UpdateJobAsOperatorMutation,
} from "@farmevo/common/dist/graphql/graphql";
import { GET_JOB_DETAILS_QUERY } from "@farmevo/web/src/Pages/Jobs/JobDetails/job-details.graphql";
import {
  GET_MY_USER_DETAILS_QUERY,
  SESSION_KEY,
  Session,
} from "@farmevo/web/src/hooks/useSession";

export const update_job_extras_many: UpdateResolver = (
  result: UpdateJobAsOperatorMutation,
  args: { updates: Job_Extras_Updates[] },
  cache
) => {
  if (args.updates.length > 0) {
    const session = JSON.parse(
      localStorage.getItem(SESSION_KEY) || "null"
    ) as Session;

    const userDetails = cache.readQuery<
      GetMyUserDetailsQuery,
      GetMyUserDetailsQueryVariables
    >({
      query: GET_MY_USER_DETAILS_QUERY,
      variables: { userId: session.id },
    });

    const isOperator = !!userDetails?.users_by_pk?.roles.find(
      (userRole) => userRole.role === Roles_Enum.Operator
    );

    const jobId = args.updates[0].where.jobId?._eq;

    const newExtras = result.update_job_extras_many
      ?.filter((mutationResponse) => !!mutationResponse?.returning?.[0])
      ?.map((mutationResponse) => mutationResponse?.returning?.[0]!);

    if (newExtras?.length) {
      cache.updateQuery<GetJobDetailsQuery, GetJobDetailsQueryVariables>(
        {
          query: GET_JOB_DETAILS_QUERY,
          variables: { jobId, isOperator },
        },
        (data) => {
          if (data?.jobs_by_pk) {
            data.jobs_by_pk.extras = newExtras;
          }

          return data;
        }
      );
    }
  }
};

export const update_job_products_many: UpdateResolver = (
  result: UpdateJobAsOperatorMutation,
  args: { updates: Job_Products_Updates[] },
  cache
) => {
  if (args.updates.length > 0) {
    const session = JSON.parse(
      localStorage.getItem(SESSION_KEY) || "null"
    ) as Session;

    const userDetails = cache.readQuery<
      GetMyUserDetailsQuery,
      GetMyUserDetailsQueryVariables
    >({
      query: GET_MY_USER_DETAILS_QUERY,
      variables: { userId: session.id },
    });

    const isOperator = !!userDetails?.users_by_pk?.roles.find(
      (userRole) => userRole.role === Roles_Enum.Operator
    );

    const jobId = args.updates[0].where.jobId?._eq;

    const newProducts = result.update_job_products_many
      ?.filter((mutationResponse) => !!mutationResponse?.returning?.[0])
      ?.map((mutationResponse) => mutationResponse?.returning?.[0]!);

    if (newProducts?.length) {
      cache.updateQuery<GetJobDetailsQuery, GetJobDetailsQueryVariables>(
        {
          query: GET_JOB_DETAILS_QUERY,
          variables: { jobId, isOperator },
        },
        (data) => {
          if (data?.jobs_by_pk) {
            newProducts.forEach((newProduct) => {
              const foundProduct = !!data.jobs_by_pk?.products.find(
                (p) => p.productId === newProduct.productId
              );
              if (!foundProduct) {
                data.jobs_by_pk?.products.push(newProduct);
              }
            });
          }

          return data;
        }
      );
    }
  }
};
