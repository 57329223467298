import { gql } from "urql";

export const GET_MY_VEHICLES = gql`
  query GetMyVehicles(
    $filter: vehicles_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    vehicles_aggregate(where: $filter) {
      aggregate {
        count
      }
    }

    vehicles(
      where: $filter
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
    ) {
      brand
      length
      model
      name
      ownership
      registrationNumber
      status
      weight
      width
      id
      userId
    }
  }
`;

export const UPDATE_MY_VEHICLE = gql`
  mutation UpdateMyVehicle(
    $updateVehiclePayload: vehicles_set_input!
    $id: uuid!
  ) {
    update_vehicles_by_pk(
      pk_columns: { id: $id }
      _set: $updateVehiclePayload
    ) {
      brand
      length
      model
      name
      ownership
      registrationNumber
      status
      weight
      width
      id
      userId
    }
  }
`;

export const SAVE_MY_VEHICLE = gql`
  mutation SaveMyVehicle($saveVehiclePayload: vehicles_insert_input!) {
    insert_vehicles_one(object: $saveVehiclePayload) {
      brand
      length
      model
      name
      ownership
      registrationNumber
      status
      weight
      width
      id
      userId
    }
  }
`;

export const UPDATE_MY_VEHICLE_STATUS = gql`
  mutation UpdateMyVehicleStatus($status: entity_status_enum!, $id: uuid!) {
    update_vehicles_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
      status
      id
    }
  }
`;
