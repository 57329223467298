import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VisuallyHidden,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useMutation } from "urql";
import { useSession } from "../../hooks/useSession";
import { IconEdit, IconPlus } from "../../Components/Icons";
import {
  Entity_Status_Enum,
  GetMyVehiclesQuery,
  GetMyVehiclesQueryVariables,
  UpdateMyVehicleStatusMutation,
  UpdateMyVehicleStatusMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { GET_MY_VEHICLES, UPDATE_MY_VEHICLE_STATUS } from "./vehicles.graphql";
import { FormMode } from "../../constants/enums";
import VehicleForm from "./Form";
import { usePaginatedUrqlQuery } from "../../hooks/usePaginatedQuery";

const Vehicles = () => {
  const { session } = useSession();
  const toast = useToast();
  const userId = session?.id;

  const [statusFilter, setStatusFilter] = useState<Entity_Status_Enum>(
    Entity_Status_Enum.Active
  );
  const [searchFilter, setSearchFilter] = useState<{
    query: string;
    debounced: boolean;
  }>({ query: "", debounced: true });

  //debouncing the search input
  useEffect(() => {
    if (searchFilter.debounced) {
      return;
    }
    const setDebounce = setTimeout(() => {
      setSearchFilter((prev) => {
        return { query: prev.query, debounced: true };
      });
    }, 300);

    return () => clearTimeout(setDebounce);
  }, [searchFilter]);

  const { data, fetchMore, canFetchMore } = usePaginatedUrqlQuery<
    GetMyVehiclesQuery,
    GetMyVehiclesQueryVariables
  >(
    GET_MY_VEHICLES,
    {
      variables: {
        filter: {
          userId: { _eq: userId || -1 },
          ...(statusFilter && {
            status: { _eq: statusFilter as Entity_Status_Enum },
            ...(searchFilter.query && {
              name: { _ilike: `%${searchFilter.query}%` },
            }),
          }),
        },
        limit: 10,
      },
      pause: !userId || !searchFilter.debounced,
    },
    {
      getLength(data) {
        return data.vehicles_aggregate.aggregate?.count || data.vehicles.length;
      },
    }
  );

  const {
    isOpen: isFormOpen,
    onOpen: onFormOpen,
    onClose: onFormClose,
  } = useDisclosure({
    onClose: () => {
      setSelectedVehicle(null);
    },
  });

  const [formMode, setFormMode] = useState<FormMode>(FormMode.Add);

  const [selectedVehicle, setSelectedVehicle] = useState<
    GetMyVehiclesQuery["vehicles"][0] | null
  >(null);

  const [, setVehicleStatus] = useMutation<
    UpdateMyVehicleStatusMutation,
    UpdateMyVehicleStatusMutationVariables
  >(UPDATE_MY_VEHICLE_STATUS);

  return (
    <Box p="2" m="2" mt="7">
      <Text ml="4" fontSize="2xl" fontWeight="bold">
        Vehicles
      </Text>
      <Flex ml="2" p="2" my="2" align="end" justifyContent="space-between">
        <Flex>
          <FormControl mx="2">
            <FormLabel>Search</FormLabel>
            <Input
              type="search"
              value={searchFilter.query}
              onChange={(e) =>
                setSearchFilter({ query: e.target.value, debounced: false })
              }
              placeholder="Search Vehicles"
              w="sm"
              name="search"
            />
          </FormControl>
          <FormControl mx="2">
            <FormLabel>Search</FormLabel>
            <Select
              value={statusFilter}
              onChange={(e) =>
                setStatusFilter(e.target.value as Entity_Status_Enum)
              }
            >
              <option value="active">Active</option>
              <option value="archived">Archived</option>
            </Select>
          </FormControl>
        </Flex>
        <Button
          mr="5"
          size="md"
          colorScheme="brand"
          onClick={() => {
            setFormMode(FormMode.Add);
            onFormOpen();
          }}
          leftIcon={<IconPlus />}
        >
          {" "}
          Add Vehicle
        </Button>
      </Flex>
      <VehicleForm
        mode={formMode}
        userId={userId || -1}
        onClose={onFormClose}
        isOpen={isFormOpen}
        selected={selectedVehicle}
      />
      {data?.vehicles_aggregate.aggregate?.count ? (
        <TableContainer whiteSpace="normal">
          <Table mt="5">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Brand</Th>
                <Th>Model</Th>
                <Th>Ownership</Th>
                <Th>Reg. #</Th>
                <Th>Status</Th>
                <Th>
                  <VisuallyHidden>Action</VisuallyHidden>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.vehicles.map((vehicle) => {
                return (
                  <Tr key={vehicle.id}>
                    <Td>{vehicle.name}</Td>
                    <Td>{vehicle.brand}</Td>
                    <Td>{vehicle.model}</Td>
                    <Td>{vehicle.ownership}</Td>
                    <Td>{vehicle.registrationNumber}</Td>
                    <Td w="15%">
                      <Select
                        onChange={async (e) => {
                          const result = await setVehicleStatus({
                            id: vehicle.id,
                            status: e.target.value as Entity_Status_Enum,
                          });
                          toast({
                            title: result.error
                              ? `An error occured while updating vehicle status`
                              : `Vehicle status updated`,
                            status: result.error ? "error" : "success",
                            duration: 3000,
                            isClosable: true,
                          });
                        }}
                        defaultValue={vehicle.status}
                      >
                        <option value="active">Active</option>
                        <option value="archived">Archived</option>
                      </Select>
                    </Td>
                    <Td w="5%">
                      <Button
                        onClick={() => {
                          setFormMode(FormMode.Edit);
                          setSelectedVehicle(vehicle);
                          onFormOpen();
                        }}
                        size="sm"
                      >
                        <IconEdit />
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <HStack ml="5" mt="5">
            <Text>
              Showing {data.vehicles.length} of{" "}
              {data.vehicles_aggregate.aggregate?.count} vehicles
            </Text>
            (
            {canFetchMore && (
              <Text
                onClick={fetchMore}
                fontWeight="bold"
                textColor="brand.500"
                _hover={{ cursor: "pointer" }}
              >
                Load More
              </Text>
            )}
            )
          </HStack>
        </TableContainer>
      ) : statusFilter !== Entity_Status_Enum.Active || searchFilter.query ? (
        <Text ml="5">
          <b>No vehicles found.</b>
        </Text>
      ) : (
        <Text ml="5">
          <b>You don't have any vehicles.</b>
          <br /> Click on the "Add" button to create a new vehicle.{" "}
        </Text>
      )}
    </Box>
  );
};
export default Vehicles;
