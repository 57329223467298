import { gql } from "urql";

export const API_LOGIN_USER_QUERY = gql`
  query ApiLoginUser($input: api_login_user_input) {
    api_login_user(input: $input) {
      token
      id
    }
  }
`;
