import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  GetMyWarehousesQuery,
  GetMyWarehousesQueryVariables,
  SaveWarehouseMutation,
} from "@farmevo/common/dist/graphql/graphql";
import { GET_MY_WAREHOUSES } from "../../../Pages/Warehouses/warehouses.graphql";

export const insert_warehouses_one: UpdateResolver = (result, args, cache) => {
  const newWarehouse = (result as SaveWarehouseMutation).insert_warehouses_one;

  if (!newWarehouse) return;

  cache
    .inspectFields("query_root")
    .filter((field) => field.fieldName === newWarehouse?.__typename)
    .forEach((field) => {
      cache.updateQuery<GetMyWarehousesQuery, GetMyWarehousesQueryVariables>(
        {
          query: GET_MY_WAREHOUSES,
          variables: {
            filter: field.arguments?.where as any,
          },
        },
        (data) => {
          const warehouseAlreadyExists = !!data?.warehouses.find(
            (warehouse) => warehouse.id === newWarehouse?.id
          );
          if (!warehouseAlreadyExists) {
            data?.warehouses.unshift(newWarehouse);
          }
          return data;
        }
      );
    });
};
