export const IconJobPartlyCompleted = ({
  size,
  color,
}: {
  size: string;
  color: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill={color}
    width={size}
    height={size}
  >
    <path
      d="M5.27,19.4A10,10,0,0,1,16.07,2.87"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M22,11.09v1"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M21.23,15.85A10,10,0,1,1,12,2a10.66,10.66,0,0,1,1.26.08"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      strokeDasharray="1.93 3.86"
    />
    <path
      d="M15.14,2.5c.31.11.62.23.93.37"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <polyline
      points="22 4.01 12 14.02 9 11.02"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);
