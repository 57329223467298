import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  Entity_Status_Enum,
  GetMyImplementsQuery,
  GetMyImplementsQueryVariables,
  UpdateMyImplementStatusMutation,
  UpdateMyImplementStatusMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { useMutation } from "urql";
import { IconEdit, IconPlus } from "../../Components/Icons";
import { useSession } from "../../hooks/useSession";
import {
  GET_MY_IMPLEMENTS,
  UPDATE_MY_IMPLEMENT_STATUS,
} from "./implements.graphql";
import { FormMode } from "../../constants/enums";
import ImplementForm from "./Form";
import { useEffect, useState } from "react";
import { usePaginatedUrqlQuery } from "../../hooks/usePaginatedQuery";

const Implements = () => {
  const { session } = useSession();

  const [formMode, setFormMode] = useState<FormMode>(FormMode.Add);
  const [statusFilter, setStatusFilter] = useState<Entity_Status_Enum>(
    Entity_Status_Enum.Active
  );
  const [searchFilter, setSearchFilter] = useState<{
    query: string;
    debounced: boolean;
  }>({ query: "", debounced: true });

  //debouncing the search input
  useEffect(() => {
    if (searchFilter.debounced) {
      return;
    }
    const setDebounce = setTimeout(() => {
      setSearchFilter((prev) => {
        return { query: prev.query, debounced: true };
      });
    }, 300);

    return () => clearTimeout(setDebounce);
  }, [searchFilter]);

  const userId = session?.id;
  const { data, fetchMore, canFetchMore } = usePaginatedUrqlQuery<
    GetMyImplementsQuery,
    GetMyImplementsQueryVariables
  >(
    GET_MY_IMPLEMENTS,
    {
      variables: {
        filter: {
          userId: { _eq: userId || -1 },
          ...(statusFilter && {
            status: { _eq: statusFilter as Entity_Status_Enum },
          }),
          ...(searchFilter.query && {
            name: { _similar: `%${searchFilter}%` },
          }),
        },
        limit: 10,
      },
      pause: !userId || !searchFilter.debounced,
    },
    {
      getLength(data) {
        return (
          data.implements_aggregate.aggregate?.count || data.implements.length
        );
      },
    }
  );

  const {
    isOpen: isFormOpen,
    onOpen: onFormOpen,
    onClose: onFormClose,
  } = useDisclosure({
    onClose: () => {
      //clear the selected whenever the form is closed.
      setSelectedImplement(null);
    },
  });

  const [selectedImplement, setSelectedImplement] = useState<
    GetMyImplementsQuery["implements"][0] | null
  >(null);

  const [, setImplementStatus] = useMutation<
    UpdateMyImplementStatusMutation,
    UpdateMyImplementStatusMutationVariables
  >(UPDATE_MY_IMPLEMENT_STATUS);

  const toast = useToast();

  return (
    <Box p="2" m="2" mt="7">
      <Text ml="4" fontWeight="bold" fontSize="2xl">
        Implements
      </Text>
      <Flex ml="2" p="2" my="2" align="end" justifyContent="space-between">
        <Flex>
          <FormControl mx="2">
            <FormLabel>Search</FormLabel>
            <Input
              type="search"
              value={searchFilter.query}
              onChange={(e) =>
                setSearchFilter({ query: e.target.value, debounced: false })
              }
              placeholder="Search Implements"
              w="sm"
              name="search"
            />
          </FormControl>
          <FormControl mx="2">
            <FormLabel>Status</FormLabel>
            <Select
              value={statusFilter}
              onChange={(e) => {
                setStatusFilter(e.target.value as Entity_Status_Enum);
              }}
            >
              <option value="active">Active</option>
              <option value="archived">Archived</option>
            </Select>
          </FormControl>
        </Flex>
        <Button
          mr="5"
          size="md"
          colorScheme="brand"
          onClick={() => {
            setFormMode(FormMode.Add);
            onFormOpen();
          }}
          leftIcon={<IconPlus />}
        >
          {" "}
          Add Implement
        </Button>
      </Flex>
      <ImplementForm
        userId={userId || -1}
        selected={selectedImplement}
        isOpen={isFormOpen}
        onClose={onFormClose}
        mode={formMode}
      />
      {data?.implements.length ? (
        <Box>
          <TableContainer whiteSpace="normal">
            <Table mt="5">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Brand</Th>
                  <Th>Model</Th>
                  <Th>Ownership</Th>
                  <Th>Status</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.implements.map((implement) => {
                  return (
                    <Tr key={implement.id}>
                      <Td>{implement.name}</Td>
                      <Td>{implement.brand}</Td>
                      <Td>{implement.model}</Td>
                      <Td>{implement.ownership}</Td>
                      <Td>
                        <Select
                          onChange={async (e) => {
                            const result = await setImplementStatus({
                              id: implement.id,
                              status: e.target.value as Entity_Status_Enum,
                            });
                            toast({
                              title: result.error
                                ? `An error occured while updating vehicle status`
                                : `Vehicle status updated`,
                              status: result.error ? "error" : "success",
                              duration: 3000,
                              isClosable: true,
                            });
                          }}
                          defaultValue={implement.status}
                        >
                          <option value="active">Active</option>
                          <option value="archived">Archived</option>
                        </Select>
                      </Td>
                      <Td w="5%">
                        <Button
                          onClick={() => {
                            setFormMode(FormMode.Edit);
                            setSelectedImplement(implement);
                            onFormOpen();
                          }}
                          size="sm"
                        >
                          <IconEdit />
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
          <HStack ml="5" mt="5">
            <Text>
              Showing {data.implements.length} of{" "}
              {data.implements_aggregate.aggregate?.count} Implements
            </Text>
            (
            {canFetchMore && (
              <Text
                onClick={fetchMore}
                fontWeight="bold"
                textColor="brand.500"
                _hover={{ cursor: "pointer" }}
              >
                Load More
              </Text>
            )}
            )
          </HStack>
        </Box>
      ) : statusFilter !== Entity_Status_Enum.Active || searchFilter.query ? (
        <Text ml="5">
          <b>No implements found.</b>
        </Text>
      ) : (
        <Text ml="5">
          <b>You don't have any implements.</b>
          <br /> Click on the "Add" button to create a new Implement.{" "}
        </Text>
      )}
    </Box>
  );
};
export default Implements;
