import { useEffect, useState } from "react";
import {
  createClient,
  Exchange,
  fetchExchange,
  Provider as UrqlProvider,
  useQuery,
} from "urql";
import { BrowserRouter } from "react-router-dom";
import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import { StepsTheme } from "chakra-ui-steps";
import { useLoadScript } from "@react-google-maps/api";
import { devtoolsExchange } from "@urql/devtools";
import {
  GetMyUserDetailsQuery,
  GetMyUserDetailsQueryVariables,
  Roles_Enum,
} from "@farmevo/common/dist/graphql/graphql";
import {
  GET_MY_USER_DETAILS_QUERY,
  SESSION_KEY,
  SESSION_PREFS_KEY,
  Session,
  SessionContext,
  SessionPrefs,
} from "./hooks/useSession";
import { SpinnerTheme } from "./themes/Components/Spinner";
import { THEME_COLORS } from "./constants/theme";
import AppRoutes from "./Routes";
import { convertPgPointToLatLng } from "./utils/point";
import { cache } from "./cacheExchange";

const urqlExchanges = [cache(), fetchExchange];

if (process.env.NODE_ENV === "development") {
  urqlExchanges.unshift(devtoolsExchange as Exchange);
}

const urqlClient = createClient({
  url: process.env.REACT_APP_HASURA_URI || "",
  exchanges: urqlExchanges,
  fetchOptions: () => {
    const session = JSON.parse(
      localStorage.getItem("@farmevo/session") || "null"
    );
    if (session) {
      return {
        headers: { authorization: session ? `Bearer ${session.token}` : "" },
      };
    }
    return {};
  },
});

const theme = extendTheme({
  colors: THEME_COLORS,
  shadows: {
    inner: {
      lg: "inset 0 2px 4px 0 rgba(0,0,0,0.2)",
      xl: "inset 0 3px 5px 2px rgba(0,0,0,0.3)",
    },
    outerTop: {
      md: "0 -3px 5px -1px rgba(0, 0, 0, 0.06)",
    },
  },
  components: {
    Steps: StepsTheme,
    Spinner: SpinnerTheme,
  },
});

function App() {
  return (
    <UrqlProvider value={urqlClient}>
      <ChakraProvider theme={theme}>
        <AppRouter />
      </ChakraProvider>
    </UrqlProvider>
  );
}

const libraries: any = ["places", "geometry", "drawing"];

const AppRouter = () => {
  const [session, _setSession] = useState<Session | null>(() =>
    JSON.parse(localStorage.getItem(SESSION_KEY) || "null")
  );
  const [sessionPrefs, _setSessionPrefs] = useState<SessionPrefs | null>(() =>
    JSON.parse(localStorage.getItem(SESSION_PREFS_KEY) || "null")
  );
  const [{ data: userDetails, error, fetching }, refreshUser] = useQuery<
    GetMyUserDetailsQuery,
    GetMyUserDetailsQueryVariables
  >({
    query: GET_MY_USER_DETAILS_QUERY,
    variables: {
      userId: session?.id || -1,
    },
    pause: !session?.id,
    requestPolicy: "network-only",
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries,
  });

  const setSession = (session: Session | null) => {
    _setSession((prev) => Object.assign({}, prev, session));
    //reExecute the query if the session has been set. This will set fetching state
    //to true allowing for a proper loading screen to be displayed.
    if (session) {
      refreshUser({
        requestPolicy: "network-only",
      });
      localStorage.setItem(SESSION_KEY, JSON.stringify(session));
    }
  };

  const setSessionPrefs = (sessionPrefs: SessionPrefs | null) => {
    _setSessionPrefs((prev) => Object.assign({}, prev, sessionPrefs));

    if (sessionPrefs) {
      localStorage.setItem(SESSION_PREFS_KEY, JSON.stringify(sessionPrefs));
    }
  };

  const setIsEmailVerified = () => {
    _setSession((prev) =>
      Object.assign({}, prev, {
        user: { ...prev?.user, isEmailVerified: true },
      })
    );
  };

  const logout = () => {
    _setSession(null);
    localStorage.removeItem(SESSION_KEY);
  };

  useEffect(() => {
    if (userDetails?.users_by_pk) {
      _setSession((prev) =>
        Object.assign({}, prev, {
          user: {
            isManager: userDetails.users_by_pk?.roles.find(
              (userRole) => userRole.role === Roles_Enum.Manager
            ),
            isOperator: userDetails.users_by_pk?.roles.find(
              (userRole) => userRole.role === Roles_Enum.Operator
            ),
            isContractor: userDetails.users_by_pk?.roles.find(
              (userRole) => userRole.role === Roles_Enum.Contractor
            ),
            isFarmer: userDetails.users_by_pk?.roles.find(
              (userRole) => userRole.role === Roles_Enum.Farmer
            ),
            ...userDetails.users_by_pk,
            fullName: `${userDetails.users_by_pk?.firstName} ${
              userDetails.users_by_pk?.lastName || ""
            }`,
            prefs: {
              ...userDetails.users_by_pk?.prefs,
              geoLocation: convertPgPointToLatLng(
                userDetails.users_by_pk?.prefs?.geoLocation
              ),
            },
          },
        })
      );
    }
  }, [userDetails?.users_by_pk]);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <BrowserRouter>
      <SessionContext.Provider
        value={{
          session,
          sessionPrefs,
          fetchingSession: fetching,
          error,
          setSession,
          setSessionPrefs,
          setIsEmailVerified,
          logout,
        }}
      >
        <AppRoutes />
      </SessionContext.Provider>
    </BrowserRouter>
  );
};

export default App;
