export const IconJobPending = ({
  size,
  color,
}: {
  size: string;
  color: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    color={color}
    width={size}
    height={size}
  >
    <path
      d="M11.78,10.9H4.93A2.91,2.91,0,0,0,2,13.83v4.38"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="7.94"
      cy="5.05"
      r="2.93"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="15.8"
      cy="15.68"
      r="6.16"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <polyline
      points="15.8 13.24 15.8 15.68 17.66 16.54"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);
