import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormMode } from "../../constants/enums";
import {
  GetMyImplementsQuery,
  Ownership_Types_Enum,
  SaveMyImplementMutation,
  SaveMyImplementMutationVariables,
  UpdateMyImplementMutation,
  UpdateMyImplementMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { useMutation } from "urql";
import { SAVE_MY_IMPLEMENT, UPDATE_MY_IMPLEMENT } from "./implements.graphql";

const createImplementValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  width: Yup.number().moreThan(0),
  length: Yup.number().moreThan(0),
  weight: Yup.number().moreThan(0),
});
const ImplementForm = (props: {
  mode: FormMode;
  selected?: GetMyImplementsQuery["implements"][0] | null;
  isOpen: boolean;
  onClose: () => void;
  userId: number;
}) => {
  const toast = useToast();

  const [, updateImplement] = useMutation<
    UpdateMyImplementMutation,
    UpdateMyImplementMutationVariables
  >(UPDATE_MY_IMPLEMENT);

  const [, insertImplement] = useMutation<
    SaveMyImplementMutation,
    SaveMyImplementMutationVariables
  >(SAVE_MY_IMPLEMENT);

  const formik = useFormik({
    initialValues: props.selected
      ? {
          name: props.selected.name,
          brand: props.selected.brand,
          length: props.selected.length,
          width: props.selected.width,
          model: props.selected.model,
          weight: props.selected.weight,
          ownership: props.selected.ownership,
        }
      : {
          name: "",
          brand: "",
          length: "",
          width: "",
          model: "",
          weight: "",
          ownership: Ownership_Types_Enum.Owned,
        },
    enableReinitialize: true,
    onSubmit: async (values) => {
      switch (props.mode) {
        case FormMode.Add:
          const insertResult = await insertImplement({
            saveImplementPayload: { ...values, userId: props.userId },
          });
          toast({
            title: insertResult.error
              ? `An error occured while saving implement ${values.name}.`
              : `${values.name} saved.`,
            status: insertResult.error ? "error" : "success",
            duration: 3000,
            isClosable: true,
          });
          break;
        case FormMode.Edit:
          const updateResult = await updateImplement({
            updateImplementPayload: { ...values },
            id: props.selected?.id || "-1",
          });
          toast({
            title: updateResult.error
              ? `An error occured while saving changes to implement ${values.name}.`
              : `${values.name} updated.`,
            status: updateResult.error ? "error" : "success",
            duration: 3000,
            isClosable: true,
          });
      }
      props.onClose();
      formik.resetForm();
    },
    validationSchema: createImplementValidationSchema,
  });

  if (!props.selected && props.mode === FormMode.Edit) {
    return null;
  }

  return (
    <Drawer size="sm" isOpen={props.isOpen} onClose={props.onClose}>
      <DrawerOverlay />
      <form onSubmit={formik.handleSubmit}>
        <DrawerContent>
          <DrawerHeader>
            {props.mode === FormMode.Add
              ? "Add a New Implement"
              : `Edit Implement : ${props.selected?.name}`}
          </DrawerHeader>
          <DrawerBody>
            <Box m="2">
              <FormControl
                isRequired
                isInvalid={!!formik.errors.name && formik.touched.name}
                my="2"
              >
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                  placeholder="e.g Seed Drill"
                  {...formik.getFieldProps("name")}
                />
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              </FormControl>
              <FormControl my="2">
                <FormLabel htmlFor="model">Model</FormLabel>
                <Input
                  placeholder="e.g Horsch"
                  {...formik.getFieldProps("model")}
                />
              </FormControl>
              <FormControl my="2">
                <FormLabel htmlFor="brand">Brand</FormLabel>
                <Input
                  placeholder="e.g Horsch Avatar SD"
                  {...formik.getFieldProps("brand")}
                />
              </FormControl>
              <FormControl my="2">
                <FormLabel htmlFor="ownership">Ownership</FormLabel>
                <Select
                  placeholder="..."
                  {...formik.getFieldProps("ownership")}
                >
                  <option value="rented">Rented</option>
                  <option value="owned">Owned</option>
                  <option value="other">Other</option>
                </Select>
              </FormControl>
              <Flex gap="2" my="3" justifyContent="space-between">
                <FormControl
                  isInvalid={
                    (!!formik.errors.width && formik.touched.width) as boolean
                  }
                >
                  <FormLabel htmlFor="width">Width</FormLabel>
                  <InputGroup>
                    <Input {...formik.getFieldProps("width")} />
                    <InputRightElement>m</InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {formik.errors.width?.toString()}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={
                    (!!formik.errors.length && formik.touched.length) as boolean
                  }
                >
                  <FormLabel htmlFor="length">Length</FormLabel>
                  <InputGroup>
                    <Input {...formik.getFieldProps("length")} />
                    <InputRightElement>m</InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {formik.errors.length?.toString()}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={
                    (!!formik.errors.weight && formik.touched.weight) as boolean
                  }
                >
                  <FormLabel htmlFor="weight">Weight</FormLabel>
                  <InputGroup>
                    <Input {...formik.getFieldProps("weight")} />
                    <InputRightElement>kg</InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {formik.errors.weight?.toString()}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
            </Box>
          </DrawerBody>
          <DrawerFooter>
            <Button mr={4} onClick={props.onClose}>
              Close
            </Button>
            <Button
              mr={4}
              isDisabled={!formik.isValid}
              type="submit"
              variant="solid"
              colorScheme="brand"
            >
              {props.mode === FormMode.Add ? "Submit" : "Update"}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};

export default ImplementForm;
