import { UpdatesConfig } from "@urql/exchange-graphcache";
import { insert_jobs_one } from "./mutation/insert_jobs_one";
import { insert_field_season_shot_one } from "./mutation/insert_field_season_shot_one";
import { insert_farms_one } from "./mutation/insert_farms_one";
import { insert_reports_one } from "./mutation/insert_reports_one";
import { insert_inventory_one } from "./mutation/insert_inventory_one";
import { insert_implements_one } from "./mutation/insert_implement_one";
import { insert_products_one } from "./mutation/products";
import { insert_vehicles_one } from "./mutation/insert_vehicles_one";
import { insert_warehouses_one } from "./mutation/insert_warehouses_one";
import {
  insert_operations_one,
  update_operations_by_pk,
  delete_operation_products_by_pk,
  insert_operation_products_one,
} from "./mutation/operations";
import {
  update_job_extras_many,
  update_job_products_many,
} from "./mutation/jobs";

export const updates: Partial<UpdatesConfig> = {
  mutation_root: {
    insert_jobs_one,
    insert_field_season_shot_one,
    insert_farms_one,
    insert_products_one,
    insert_implements_one,
    insert_reports_one,
    insert_vehicles_one,
    insert_inventory_one,
    update_operations_by_pk,
    update_job_extras_many,
    update_job_products_many,
    insert_operations_one,
    delete_operation_products_by_pk,
    insert_operation_products_one,
    insert_warehouses_one,
  },
};
