import { createContext, useContext } from "react";
import { CombinedError, gql } from "urql";
import { GetMyUserDetailsQuery } from "@farmevo/common/dist/graphql/graphql";

export type Session = {
  token: string;
  id: number;
  user?: GetMyUserDetailsQuery["users_by_pk"] & {
    fullName?: string;
    isManager?: boolean;
    isOperator?: boolean;
    isContractor?: boolean;
    isFarmer?: boolean;
  };
};

export type SessionPrefs = {
  isSideNavOpen: boolean;
};

type SessionProps = {
  session: Session | null;
  sessionPrefs: SessionPrefs | null;
  fetchingSession: boolean;
  error?: CombinedError;
  setSession: (session: Session | null) => void;
  setSessionPrefs: (sessionPrefs: SessionPrefs | null) => void;
  setIsEmailVerified: () => void;
  logout: () => void;
};

export const SESSION_KEY = "@farmevo/session";
export const SESSION_PREFS_KEY = "@farmevo/session/prefs";

export const GET_MY_USER_DETAILS_QUERY = gql`
  query GetMyUserDetails($userId: Int!) {
    users_by_pk(id: $userId) {
      id
      email
      username
      firstName
      lastName
      isEmailVerified
      prefs {
        userId
        geoLocation
        fieldColor
        currency
      }
      profile {
        userId
        companyName
      }
      roles {
        role
      }
    }
  }
`;

export const SessionContext = createContext<SessionProps>({
  session: null,
  sessionPrefs: null,
  fetchingSession: false,
  setSession: () => {},
  setSessionPrefs: () => {},
  setIsEmailVerified: () => {},
  logout: () => {},
});

export const useSession = () => {
  return useContext(SessionContext);
};
