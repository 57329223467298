import {
  Avatar,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useSession } from "../hooks/useSession";
import logoFull from "../assets/logo-full.svg";

const TopNav = () => {
  const { session, logout } = useSession();

  const isOperator = !!session?.user?.isOperator;

  return (
    <Flex
      px={6}
      py={2}
      alignItems="center"
      justify="space-between"
      boxShadow="sm"
      position="sticky"
      top="0"
      bgColor="white"
      zIndex="99"
    >
      <Link to="/" onDoubleClick={() => window.location.assign("/")}>
        <Image src={logoFull} height={8} />
      </Link>
      <Menu>
        <Avatar
          as={MenuButton}
          name={session?.user?.fullName}
          cursor="pointer"
          userSelect="none"
          _hover={{
            boxShadow: "var(--chakra-shadows-inner-lg)",
            background: "var(--avatar-background)",
          }}
          _active={{ boxShadow: "var(--chakra-shadows-inner-xl)" }}
        />
        <MenuList>
          {!isOperator && (
            <>
              <MenuItem as={Link} to="/account">
                Account
              </MenuItem>
              <MenuItem>Subscription</MenuItem>
              <MenuDivider />
            </>
          )}
          <MenuItem onClick={logout}>Logout</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default TopNav;
