import { ChangeEvent, useContext, useState } from "react";
import { useMutation } from "urql";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import { parseFormData } from "parse-nested-form-data";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Text,
  VStack,
  Link,
  Select,
} from "@chakra-ui/react";
import {
  ApiSignUpUserMutation,
  ApiSignUpUserMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { API_ERRORS } from "@farmevo/common/dist/constants/api-errors";
import { API_SIGN_UP_USER_MUTATION } from "./signup.graphql";
import { SessionContext } from "../../hooks/useSession";
import logoFull from "../../assets/logo-full.svg";

type Props = {};

type SignUpFormData = {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  role: string;
};

const SignUp = (props: Props) => {
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setSession } = useContext(SessionContext);
  const navigate = useNavigate();
  const [, signUp] = useMutation<
    ApiSignUpUserMutation,
    ApiSignUpUserMutationVariables
  >(API_SIGN_UP_USER_MUTATION);

  const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    const formData = parseFormData(new FormData(e.target)) as SignUpFormData;

    if (formData.password.length < 6) {
      setError("Password should be at least 6 characters");
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    setIsSubmitting(true);

    const result = await signUp(
      {
        input: {
          email: formData.email,
          password: formData.password,
          firstName: formData.firstName,
          lastName: formData.lastName,
          role: formData.role,
        },
      },
      {}
    );

    const token = result.data?.api_sign_up_user?.token;
    const id = result.data?.api_sign_up_user?.id;

    if (
      result.error?.message === `[GraphQL] ${API_ERRORS.USER_ALREADY_EXISTS}`
    ) {
      setError(
        "An account with this email already exists. Try logging in or use a different email"
      );
    } else if (result.error || !token || !id) {
      setError("An unknown error occurred");
    } else {
      setSession({
        token,
        id,
      });
      navigate("/onboard");
    }
    setIsSubmitting(false);
  };

  return (
    <Box textAlign="center" maxW="container.sm" mx="auto" py={8} mt={16}>
      <Image src={logoFull} width={64} mx="auto" />
      <Text fontWeight="bold" fontSize="2xl" mt={10} mb={6}>
        Create an Account
      </Text>
      <form onSubmit={onSubmit}>
        <VStack spacing={5}>
          {error && (
            <Text
              textDecoration="underline"
              fontSize="sm"
              w="full"
              textAlign="left"
              color="red.500"
            >
              {error}
            </Text>
          )}
          <HStack w="full">
            <FormControl isRequired>
              <FormLabel>First Name:</FormLabel>
              <Input name="firstName" type="text" placeholder="e.g John" />
            </FormControl>
            <FormControl>
              <FormLabel>Last Name:</FormLabel>
              <Input
                name="lastName"
                type="text"
                autoComplete="off"
                placeholder="e.g Doe"
              />
            </FormControl>
          </HStack>
          <FormControl isRequired>
            <FormLabel>Email:</FormLabel>
            <Input
              name="email"
              type="email"
              placeholder="e.g. john@example.com"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>I am a:</FormLabel>
            <Select name="role">
              <option value="farmer">Farmer</option>
              <option value="contractor">Contractor</option>
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Password:</FormLabel>
            <Input
              name="password"
              type="password"
              placeholder="Enter Password"
              autoComplete="new-password"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Confirm Password:</FormLabel>
            <Input
              name="confirmPassword"
              type="password"
              placeholder="Re-enter password"
              autoComplete="new-password"
            />
          </FormControl>
          <Button type="submit" isLoading={isSubmitting}>
            Sign Up
          </Button>
          <Box>
            Already have an account?{" "}
            <Link
              as={RouteLink}
              to="/login"
              color="brand.500"
              fontWeight="bold"
            >
              Login
            </Link>
          </Box>
        </VStack>
      </form>
    </Box>
  );
};

export default SignUp;
