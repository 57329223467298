import { gql } from "urql";

export const GET_MY_INVENTORY = gql`
  query GetMyInventory(
    $filter: inventory_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    inventory_aggregate(where: $filter) {
      aggregate {
        count
      }
    }

    inventory(
      where: $filter
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
    ) {
      amount
      createdAt
      id
      price
      remaining
      userId
      warehouse {
        id
        name
      }
      product {
        id
        name
        chargeableUnit
        pricePerUnit
      }
    }
  }
`;

export const UPDATE_MY_PRODUCT_PRICE = gql`
  mutation UpdateMyProductPrice($id: uuid!, $price: numeric!) {
    update_products_by_pk(
      pk_columns: { id: $id }
      _set: { pricePerUnit: $price }
    ) {
      id
    }
  }
`;

export const SAVE_MY_INVENTORY = gql`
  mutation SaveMyInventory($saveInventoryPayload: inventory_insert_input!) {
    insert_inventory_one(object: $saveInventoryPayload) {
      amount
      createdAt
      id
      price
      remaining
      userId
      warehouse {
        id
        name
      }
      product {
        id
        name
        chargeableUnit
        pricePerUnit
      }
    }
  }
`;
