import { Tooltip } from "@chakra-ui/react";
import {
  IconCheckCircle,
  IconJobPartlyCompleted,
  IconJobPending,
  IconJobPendingCompletion,
  IconJobProgress,
  IconJobReady,
  IconXCircle,
} from "./Icons";
import { Job_Status_Enum } from "@farmevo/common/dist/graphql/graphql";

export const JobStatusIcon = ({
  status,
  hideTooltip = false,
}: {
  status: Job_Status_Enum;
  hideTooltip?: boolean;
}) => {
  let label = "";
  let iconColor = "";
  let Icon = null;
  switch (status) {
    case Job_Status_Enum.Pending:
      label = "Pending approval";
      iconColor = "var(--chakra-colors-gray-500)";
      Icon = IconJobPending;
      break;
    case Job_Status_Enum.Ready:
      label = "Ready";
      iconColor = "var(--chakra-colors-gray-500)";
      Icon = IconJobReady;
      break;
    case Job_Status_Enum.PartlyCompleted:
      label = "Completed by operator";
      iconColor = "var(--chakra-colors-gray-500)";
      Icon = IconJobPartlyCompleted;
      break;
    case Job_Status_Enum.PendingCompletion:
      label = "Pending final approval";
      iconColor = "var(--chakra-colors-gray-500)";
      Icon = IconJobPendingCompletion;
      break;
    case Job_Status_Enum.Completed:
      label = "Completed";
      iconColor = "var(--chakra-colors-green-500)";
      Icon = IconCheckCircle;
      break;
    case Job_Status_Enum.InProgress:
      label = "In progress";
      iconColor = "var(--chakra-colors-gray-500)";
      Icon = IconJobProgress;
      break;
    case Job_Status_Enum.Cancelled:
      label = "Cancelled";
      iconColor = "var(--chakra-colors-red-500)";
      Icon = IconXCircle;
      break;
    default:
      return null;
  }
  return (
    <Tooltip label={label} hidden={hideTooltip}>
      <span>
        <Icon size="18px" color={iconColor} />
      </span>
    </Tooltip>
  );
};
