import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  Badge,
  Box,
  BoxProps,
  Collapse,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Link,
  Select,
  Text,
  Textarea,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { parseFormData } from "parse-nested-form-data";
import { Link as RouteLink } from "react-router-dom";
import { CombinedError, useMutation, useQuery } from "urql";
import {
  GetFarmsQuery,
  GetFarmsQueryVariables,
  GetFieldsQuery,
  InsertFarmMutation,
  InsertFarmMutationVariables,
  InsertFieldSeasonShotMutation,
  InsertFieldSeasonShotMutationVariables,
  SaveFieldShotAndPolygonMutation,
  SaveFieldShotAndPolygonMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { IconArrowLeft, IconCheck, IconX } from "../../Components/Icons";
import ComboSelect from "../../Components/ComboSelect";
import Button from "../../Components/Button";
import SnapshotHistory from "./SnapshotHistory";
import {
  GET_FARMS_QUERY,
  INSERT_FARM_MUTATION,
  INSERT_FIELD_SEASON_SHOT_MUTATION,
  SAVE_FIELD_SEASON_SHOT_AND_POLYGON_MUTATION,
} from "./fields.graphql";
import { useSession } from "../../hooks/useSession";
import { computePolygonAreaInAcres, toDbPolygon } from "../../utils/polygon";
import SnapshotReports from "./SnapshotReports";
import UploadReportModal from "./UploadReportModal";
import SnapshotJobs from "./SnapshotJobs";
import { roundToTwo } from "../../utils/math";

type FieldForm = {
  name: string;
  description: string;
  ownershipType: string;
  cropName: string;
  season: string;
  farmId: string;
  notes: string;
};

export type FieldSidePanelMode = "add" | "view" | "edit" | null;

type Props = {
  newPolygon?: google.maps.Polygon;
  mode: FieldSidePanelMode;
  fieldsData?: GetFieldsQuery;
  fieldShotId: GetFieldsQuery["field_season_shot"][0]["id"] | null;
  defaultSelectedFarm: GetFarmsQuery["farms"][0] | null;
  onClose: () => void;
  onOpenEditFieldForm: () => void;
  onSubmitForm: (
    newFieldShot?: InsertFieldSeasonShotMutation["insert_field_season_shot_one"]
  ) => void;
};

enum FormError {
  MISSING_POLYGON = "1",
}

function getDefaultSeasons() {
  const currentYear = new Date().getFullYear();
  const arr = [];
  for (let i = currentYear; i > currentYear - 10; i--) {
    arr.push(String(i));
  }
  return arr;
}

const FieldSidePanel = ({
  newPolygon,
  mode,
  fieldShotId,
  fieldsData,
  defaultSelectedFarm,
  onClose,
  onOpenEditFieldForm,
  onSubmitForm,
  ...props
}: BoxProps & Props) => {
  const toast = useToast();
  const [formError, setFormError] = useState<FormError | "">("");
  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedFarm, setSelectedFarm] = useState<
    GetFarmsQuery["farms"][0] | null
  >(null);
  const [justInsertedFarm, setJustInsertedFarm] = useState(false);
  const { session } = useSession();
  let [seasons, setSeasons] = useState(getDefaultSeasons);
  const formRef = useRef<HTMLFormElement | null>(null);
  const fieldShot = useMemo(() => {
    return fieldsData?.field_season_shot.find(
      (shot) => shot.id === fieldShotId
    );
  }, [fieldShotId, fieldsData]);

  const [selectedFieldShot, setSelectedFieldShot] = useState(fieldShot);

  useEffect(() => {
    setSelectedFieldShot(fieldShot);
  }, [fieldShot]);

  const [{ data: farmsData }] = useQuery<GetFarmsQuery, GetFarmsQueryVariables>(
    {
      query: GET_FARMS_QUERY,
      variables: {
        userId: session?.id,
      },
    }
  );

  const [{ fetching: insertingFarm }, insertFarm] = useMutation<
    InsertFarmMutation,
    InsertFarmMutationVariables
  >(INSERT_FARM_MUTATION);

  const [{ fetching: insertingFieldShot }, insertFieldSeasonShot] = useMutation<
    InsertFieldSeasonShotMutation,
    InsertFieldSeasonShotMutationVariables
  >(INSERT_FIELD_SEASON_SHOT_MUTATION);

  const [{ fetching: savingFieldShot }, saveFieldSeasonShot] = useMutation<
    SaveFieldShotAndPolygonMutation,
    SaveFieldShotAndPolygonMutationVariables
  >(SAVE_FIELD_SEASON_SHOT_AND_POLYGON_MUTATION);

  const { isOpen: isSnapshotHistoryOpen, onToggle: onToggleSnapshotHistory } =
    useDisclosure();

  const { isOpen: isSnapshotReportsOpen, onToggle: onToggleSnapshotReports } =
    useDisclosure();

  const { isOpen: isSnapshotJobsOpen, onToggle: onToggleSnapshotJobs } =
    useDisclosure();

  const {
    onOpen: openUploadReportModal,
    isOpen: isUploadReportModalOpen,
    onClose: closeUploadReportModal,
  } = useDisclosure();

  useEffect(() => {
    if (!farmsData || !fieldShot || !!selectedFarm) return;

    setSelectedFarm(
      farmsData.farms.find((farm) => farm.id === fieldShot.farm.id) || null
    );
  }, [farmsData, fieldShot, selectedFarm]);

  useEffect(() => {
    if (mode !== "add") {
      return;
    }
    if (defaultSelectedFarm?.id < 0) {
      setSelectedFarm(null);
    } else {
      setSelectedFarm(defaultSelectedFarm);
    }
  }, [mode, defaultSelectedFarm]);

  useEffect(() => {
    if (!fieldShot) return;

    setSeasons((prev) => Array.from(new Set([fieldShot.season, ...prev])));
    setSelectedSeason(fieldShot.season);
  }, [fieldShot]);

  useEffect(() => {
    if (mode === "add" && formRef.current) {
      formRef.current.reset();
    }
  }, [formRef, mode]);

  seasons = useMemo(() => {
    if (!fieldsData) return seasons;
    return Array.from(
      new Set([
        ...seasons,
        ...fieldsData.field_season_shot.map((shot) => shot.season),
      ])
    );
  }, [fieldsData, seasons]);

  const onCreateFarm = async (farmName: string) => {
    const { data } = await insertFarm({
      farm: {
        name: farmName,
        userId: session?.id,
      },
    });

    const farm = data?.insert_farms_one;

    if (!farm) return;
    setSelectedFarm(farm);
    setJustInsertedFarm(true);
    setTimeout(() => setJustInsertedFarm(false), 1000);
  };

  const onCreateSeason = (season: string) => {
    setSeasons((prev) => [season, ...prev]);
    setSelectedSeason(season);
  };

  const renderFarmsEmptyState = (
    inputValue: string,
    searchResult?: GetFarmsQuery["farms"]
  ) => {
    if (!inputValue && !insertingFarm && !justInsertedFarm) {
      return null;
    }

    if (searchResult?.length !== 0) {
      return null;
    }

    return (
      <Box
        position="absolute"
        w="full"
        zIndex="99"
        bgColor="white"
        pb={2}
        mt={2}
      >
        <Button
          w="full"
          variant="solid"
          isDisabled={insertingFarm || justInsertedFarm}
          isLoading={insertingFarm}
          onClick={() => onCreateFarm(inputValue)}
        >
          {justInsertedFarm ? <IconCheck /> : `Create ${inputValue}`}
        </Button>
      </Box>
    );
  };

  const renderSeasonsEmptyState = (
    inputValue: string,
    searchResult?: string[]
  ) => {
    if (!inputValue) {
      return null;
    }

    if (searchResult?.length !== 0) {
      return null;
    }

    return (
      <Box
        position="absolute"
        w="full"
        zIndex="99"
        bgColor="white"
        pb={2}
        mt={2}
      >
        <Button
          w="full"
          variant="solid"
          onClick={() => onCreateSeason(inputValue)}
        >
          Create {inputValue}
        </Button>
      </Box>
    );
  };

  const handleSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!newPolygon && mode === "add") {
      setFormError(FormError.MISSING_POLYGON);
      setTimeout(() => setFormError(""), 5000);
      return;
    }

    setFormError("");
    const formData = parseFormData(
      new FormData(e.target)
    ) as Partial<FieldForm>;

    let error: CombinedError | undefined;

    const polygonData = newPolygon
      ? {
          size: computePolygonAreaInAcres(newPolygon),
          geometry: toDbPolygon(newPolygon),
        }
      : {};

    const shotData = {
      cropName: formData.cropName,
      description: formData.description,
      farmId: formData.farmId,
      name: formData.name,
      notes: formData.notes,
      ownershipType: formData.ownershipType,
    };

    let newFieldShot;
    if (mode === "add") {
      const result = await insertFieldSeasonShot({
        shot: {
          ...shotData,
          season: formData.season,
          field: {
            data: { defaultFarmId: formData.farmId },
          },
          polygon: {
            data: {
              ...polygonData,
              defaultFarmId: formData.farmId,
            },
          },
        },
      });

      error = result.error;
      newFieldShot = result.data?.insert_field_season_shot_one;
    } else {
      if (fieldShot?.season !== formData.season) {
        const result = await insertFieldSeasonShot({
          shot: {
            ...shotData,
            fieldId: fieldShot?.fieldId,
            season: formData.season,
            ...(newPolygon
              ? {
                  polygon: {
                    data: {
                      ...polygonData,
                      defaultFarmId: formData.farmId,
                    },
                  },
                }
              : {
                  polygonId: fieldShot?.polygon.id,
                }),
          },
        });

        newFieldShot = result.data?.insert_field_season_shot_one;
        error = result.error;
      } else {
        const result = await saveFieldSeasonShot({
          polygon: polygonData,
          polygonId: fieldShot?.polygon.id,
          shotId: fieldShot?.id,
          shot: shotData,
        });

        error = result.error;
      }
    }

    if (error) {
      toast({
        status: "error",
        title: "An error occurred",
        description: "Try again or contact support",
      });
      return;
    }

    onSubmitForm(newFieldShot);
  };

  if (mode === "view" && fieldShot) {
    return (
      <>
        <VStack
          align="unset"
          overflow="hidden"
          maxH="calc(100vh - 64px)"
          {...props}
        >
          <HStack py={2} pr={2}>
            <IconButton
              aria-label="hide right panel"
              icon={<IconX />}
              variant="ghost"
              onClick={onClose}
            />
            <Text flex={1} fontWeight="700">
              {fieldShot.name}
            </Text>
            <Button variant="ghost" onClick={onOpenEditFieldForm}>
              Edit
            </Button>
          </HStack>
          <HStack>
            {fieldShot.polygon.size && (
              <Badge
                fontSize="md"
                borderRadius="md"
                bgColor="brand.100"
                color="gray.500"
                px={2}
                py={1}
              >
                {roundToTwo(fieldShot.polygon.size)} acres
              </Badge>
            )}
            <Text>{fieldShot.season}</Text>
          </HStack>
          {fieldShot.description && (
            <Text py={1} fontStyle="italic">
              {fieldShot.description}
            </Text>
          )}
          <Divider />
          <HStack py={4} pr={5}>
            <Text fontSize="lg" fontWeight="500" flex={1}>
              History
            </Text>
            <Button
              colorScheme="brand"
              variant="link"
              onClick={onToggleSnapshotHistory}
              fontSize="sm"
            >
              {isSnapshotHistoryOpen ? "Hide" : "Show"}
            </Button>
          </HStack>
          <Collapse in={isSnapshotHistoryOpen}>
            <SnapshotHistory
              isOpen={isSnapshotHistoryOpen}
              fieldId={fieldShot.fieldId}
              selectedShot={selectedFieldShot}
              onSelectShot={setSelectedFieldShot}
            />
          </Collapse>
          <Divider />
          <HStack pt={4} pr={5}>
            <Text fontSize="lg" fontWeight="500" flex={1}>
              Reports{" "}
              <Text as="span" fontSize="xs" color="gray.600">
                ({selectedFieldShot?.season})
              </Text>
            </Text>
            <Button
              variant="link"
              fontSize="sm"
              onClick={openUploadReportModal}
            >
              Upload
            </Button>
            <Button
              colorScheme="brand"
              variant="link"
              onClick={onToggleSnapshotReports}
              fontSize="sm"
            >
              {isSnapshotReportsOpen ? "Hide" : "Show"}
            </Button>
          </HStack>
          <Collapse in={isSnapshotReportsOpen}>
            <SnapshotReports
              isOpen={isSnapshotReportsOpen}
              fieldId={fieldShot.fieldId}
              selectedShot={selectedFieldShot}
            />
          </Collapse>
          <Divider />
          <HStack pt={4} pr={5}>
            <Text fontSize="lg" fontWeight="500" flex={1}>
              Jobs{" "}
              <Text as="span" fontSize="xs" color="gray.600">
                ({selectedFieldShot?.season})
              </Text>
            </Text>
            <Link
              color="gray.500"
              fontWeight="semibold"
              as={RouteLink}
              to="/jobs/new"
              fontSize="sm"
            >
              Create
            </Link>
            <Button
              colorScheme="brand"
              variant="link"
              onClick={onToggleSnapshotJobs}
              fontSize="sm"
            >
              {isSnapshotJobsOpen ? "Hide" : "Show"}
            </Button>
          </HStack>
          <Collapse in={isSnapshotJobsOpen}>
            <SnapshotJobs
              isOpen={isSnapshotJobsOpen}
              selectedShot={selectedFieldShot}
            />
          </Collapse>
          <Divider />
          <Box py={4}>
            <Text fontSize="lg" fontWeight="500" mb={2}>
              Notes{" "}
              <Text as="span" fontSize="xs" color="gray.600">
                ({selectedFieldShot?.season})
              </Text>
            </Text>
            {selectedFieldShot?.notes ? (
              <Text>{selectedFieldShot.notes}</Text>
            ) : (
              <Text fontSize="sm" fontStyle="italic" color="gray.600">
                Not available
              </Text>
            )}
          </Box>
        </VStack>
        <UploadReportModal
          shot={selectedFieldShot}
          isOpen={isUploadReportModalOpen}
          onClose={closeUploadReportModal}
        />
      </>
    );
  }

  return (
    <VStack
      align="unset"
      overflow="hidden"
      maxH="calc(100vh - 64px)"
      {...props}
    >
      <HStack py={2}>
        <IconButton
          aria-label="hide right panel"
          icon={<IconX />}
          variant="ghost"
          onClick={onClose}
        />
        <Text fontWeight="700">
          {mode === "edit" ? `Edit ${fieldShot?.name}` : "Add a field"}
        </Text>
      </HStack>
      {mode === "add" && (
        <HStack
          px={2}
          animation={
            formError === FormError.MISSING_POLYGON
              ? "blinker 0.75s linear infinite"
              : ""
          }
        >
          <IconArrowLeft size={18} />
          <Text fontSize="sm" fontStyle="italic">
            Click anywhere on the map to start drawing
          </Text>
        </HStack>
      )}
      <Box overflowY="auto">
        <form onSubmit={handleSubmit} ref={formRef}>
          <VStack px={2} pr={3} mt={4} spacing={4}>
            <FormControl isRequired>
              <FormLabel>Field name</FormLabel>
              <Input
                id="name"
                name="name"
                defaultValue={fieldShot?.name || ""}
                placeholder="e.g. My rice field"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Input
                id="description"
                name="description"
                placeholder="Optional"
                defaultValue={fieldShot?.description || ""}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Ownership</FormLabel>
              <Select
                id="ownershipType"
                name="ownershipType"
                defaultValue={fieldShot?.ownershipType || "rented"}
              >
                <option value="rented">Rented</option>
                <option value="owned">Owned</option>
                <option value="other">Other</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Crop</FormLabel>
              <Input
                id="cropName"
                name="cropName"
                placeholder="e.g Rice"
                defaultValue={fieldShot?.cropName || ""}
              />
            </FormControl>
            <ComboSelect<string>
              items={seasons}
              itemsFilter={(v) => (season) => season.includes(v)}
              itemToString={(season) => season || ""}
              label="Season"
              searchBehavior="reduce"
              formControlProps={{ isRequired: true }}
              inputProps={{
                id: "season",
                name: "season",
                placeholder: "Select season",
              }}
              defaultSelectedItem={selectedSeason}
              onSelectItem={(season) => setSelectedSeason(season || "")}
              renderItem={(item) => <span>{item}</span>}
              menuProps={{ maxH: "200px" }}
              renderEmptyState={renderSeasonsEmptyState}
            />
            <Input
              display="none"
              id="farmId"
              name="farmId"
              value={selectedFarm?.id || ""}
              readOnly
            />
            <ComboSelect<GetFarmsQuery["farms"][0]>
              items={farmsData?.farms || []}
              searchBehavior="reduce"
              itemsFilter={(v) => (farm) =>
                farm.name.toLowerCase().startsWith(v.toLowerCase())}
              itemToString={(farm) => farm?.name || ""}
              label="Farm"
              formControlProps={{ isRequired: true }}
              inputProps={{
                placeholder: "Select farm",
              }}
              defaultSelectedItem={selectedFarm}
              onSelectItem={(item) => setSelectedFarm(item || null)}
              renderItem={(item) => <span>{item?.name}</span>}
              menuProps={{ maxH: "200px" }}
              renderEmptyState={renderFarmsEmptyState}
            />
            <FormControl>
              <FormLabel>Notes</FormLabel>
              <Textarea
                id="notes"
                name="notes"
                defaultValue={fieldShot?.notes || ""}
              ></Textarea>
            </FormControl>
            <Box py={4} bgColor="white" w="full" position="sticky" bottom="0">
              <Button
                type="submit"
                isDisabled={insertingFieldShot || savingFieldShot}
                isLoading={insertingFieldShot || savingFieldShot}
                w="full"
              >
                {mode === "edit" ? "Save" : "Create"}
              </Button>
            </Box>
          </VStack>
        </form>
      </Box>
    </VStack>
  );
};

export default FieldSidePanel;
