import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import LayoutOnboarding from "../../Components/LayoutOnboarding";
import { CompleteForm, TaxRate } from "./onboard.graphql";

type Props = {
  formData: Partial<CompleteForm> | null;
};

const Invoicing = (props: Props) => {
  const [rates, setRates] = useState<TaxRate[]>(props.formData?.rates || []);
  const [newRateName, setNewRateName] = useState("");
  const [newRatePerc, setNewRatePerc] = useState("");
  return (
    <LayoutOnboarding
      title={`Setup invoicing and taxes`}
      subtitle="You can change these settings later."
    >
      <Box flex={1} mb={10}>
        <Text fontSize="xl" fontWeight="semibold" mb={4}>
          Default invoice settings
        </Text>
        <VStack spacing={5}>
          <FormControl>
            <FormLabel>Invoice terms (in days)</FormLabel>
            <Input
              type="number"
              id="invoiceDays"
              placeholder="e.g. 15"
              name="invoiceDays"
            />
          </FormControl>

          <FormControl>
            <FormLabel>Payment instructions</FormLabel>
            <Textarea id="paymentInfo" name="paymentInfo" />
          </FormControl>
        </VStack>
      </Box>

      <Box flex={1}>
        <Text fontSize="xl" fontWeight="semibold" mb={4}>
          Tax information
        </Text>
        <VStack spacing={5}>
          <HStack w="full">
            <FormControl>
              <FormLabel>Tax name</FormLabel>
              <Select id="taxName" name="taxName">
                <option value="VAT">VAT</option>
                <option value="GST">GST</option>
                <option value="Sales Tax">Sales Tax</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Sales Tax number</FormLabel>
              <Input
                type="text"
                id="salesTaxNumber"
                name="salesTaxNumber"
                placeholder="eg. 3242423"
              />
            </FormControl>
          </HStack>
          {rates.map((taxRate, rateIndex) => (
            <HStack w="full" key={taxRate.name + taxRate.rate}>
              <FormControl>
                {rateIndex === 0 && <FormLabel>Rate name</FormLabel>}
                <Input
                  type="text"
                  id={`rates[${rateIndex}].name`}
                  name={`rates[${rateIndex}].name`}
                  placeholder="e.g. Standard"
                  value={taxRate.name}
                  onChange={(e) =>
                    setRates((prev) => {
                      const newRates = [...prev];
                      newRates[rateIndex].name = e.target.value;
                      return newRates;
                    })
                  }
                />
              </FormControl>
              <FormControl>
                {rateIndex === 0 && <FormLabel>Rate percentage (%)</FormLabel>}
                <Input
                  type="text"
                  id={`rates[${rateIndex}].rate`}
                  name={`rates[${rateIndex}].rate`}
                  placeholder="e.g. 2.5"
                  value={taxRate.rate}
                  onChange={(e) =>
                    setRates((prev) => {
                      const newRates = [...prev];
                      newRates[rateIndex].rate = e.target.value;
                      return newRates;
                    })
                  }
                />
              </FormControl>
            </HStack>
          ))}
          <VStack w="full" alignItems="start">
            <HStack w="full">
              <FormControl>
                {rates.length === 0 && <FormLabel>Rate name</FormLabel>}
                <Input
                  type="text"
                  placeholder="e.g. Standard"
                  value={newRateName}
                  onChange={(e) => setNewRateName(e.target.value)}
                />
              </FormControl>
              <FormControl>
                {rates.length === 0 && (
                  <FormLabel>Rate percentage (%)</FormLabel>
                )}
                <Input
                  type="text"
                  placeholder="e.g. 2.5"
                  value={newRatePerc}
                  onChange={(e) => setNewRatePerc(e.target.value)}
                />
              </FormControl>
            </HStack>
            <Button
              onClick={() => {
                setRates((prev) => [
                  ...prev,
                  { name: newRateName, rate: newRatePerc },
                ]);
                setNewRateName("");
                setNewRatePerc("");
              }}
              isDisabled={!newRateName || !newRatePerc}
            >
              + Add
            </Button>
          </VStack>
        </VStack>
      </Box>
    </LayoutOnboarding>
  );
};

export default Invoicing;
