import { Currency } from "../constants/currency";
import { Timezone } from "../constants/timezone";

export const currenciesFilter = (inputValue: string) => {
  return (item: Currency) => {
    const lowered = inputValue.toLowerCase();
    const str =
      item.code.toLowerCase() +
      item.name.toLowerCase() +
      item.symbol.toLowerCase();
    return !inputValue || str.includes(lowered);
  };
};

export const timezonesFilter = (inputValue: string) => {
  return (item: Timezone) => {
    const lowered = inputValue.toLowerCase();
    const str =
      item.utc.toLowerCase() +
      item.name.toLowerCase() +
      item.label.toLowerCase() +
      item.tzCode.toLowerCase();
    return !inputValue || str.includes(lowered);
  };
};

export const currencyToString = (cur: Currency | null) =>
  !cur ? "" : `${cur.code}`;

export const timezoneToString = (item: Timezone | null) =>
  !item ? "" : `${item.tzCode}`;
