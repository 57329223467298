import {
  useToast,
  Spinner,
  Flex,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Box,
  Text,
} from "@chakra-ui/react";
import {
  GetMyUserProfileQuery,
  GetMyUserProfileQueryVariables,
  SaveMyBasicProfileMutation,
  SaveMyBasicProfileMutationVariables,
  SaveMyUserProfileMutation,
  SaveMyUserProfileMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { useFormik } from "formik";
import { useQuery, useMutation } from "urql";
import { countries } from "../../constants/country";
import { useSession } from "../../hooks/useSession";
import {
  GET_MY_USER_PROFILE,
  SAVE_MY_BASIC_PROFILE,
  SAVE_MY_USER_PROFILE,
} from "./accounts.graphql";

const ProfileForm = () => {
  const { session } = useSession();
  const toast = useToast();

  const userId = session?.id || -1;

  const [{ data, fetching, error }] = useQuery<
    GetMyUserProfileQuery,
    GetMyUserProfileQueryVariables
  >({
    query: GET_MY_USER_PROFILE,
    variables: { userId },
    pause: !userId,
  });

  const [, updateBasicProfile] = useMutation<
    SaveMyBasicProfileMutation,
    SaveMyBasicProfileMutationVariables
  >(SAVE_MY_BASIC_PROFILE);
  const [, updateUserProfile] = useMutation<
    SaveMyUserProfileMutation,
    SaveMyUserProfileMutationVariables
  >(SAVE_MY_USER_PROFILE);

  const basicProfileFormik = useFormik({
    initialValues: {
      email: session?.user?.email || "",
      firstName: session?.user?.firstName || "",
      lastName: session?.user?.lastName || "",
      username: session?.user?.username || "",
    },
    onSubmit: async (values) => {
      const result = await updateBasicProfile({
        userId: userId,
        updateUserPayload: {
          firstName: values.firstName,
          lastName: values.lastName,
          username: values.username,
        },
      });

      toast({
        title: result.error
          ? "An error occurred while updating profile."
          : "Profile has been updated.",
        status: result.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const userProfileFormik = useFormik({
    enableReinitialize: true,
    initialValues: data?.user_profiles_by_pk || {
      address1: "",
      address2: "",
      city: "",
      companyName: "",
      country: "",
      phone: "",
      postalCode: "",
      state: "",
      taxNumber: "",
    },
    onSubmit: async (values) => {
      if (!userId) {
        return;
      }

      const result = await updateUserProfile({
        userId: userId,
        updateUserProfilePayload: values,
      });

      toast({
        title: result.error
          ? "An error occurred while updating user profile."
          : "User Profile has been updated",
        status: result.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
      });
    },
  });

  if (fetching) {
    return <Spinner />;
  }

  if (error) {
    return <Text>{error.message}</Text>;
  }

  return (
    <Box margin={5}>
      <Box>
        <Flex justifyContent="space-around" w="full" padding={5} my="5">
          <Text margin={4} fontWeight="bold" fontSize="larger">
            Basic Profile Settings
          </Text>
          <VStack mt={10} spacing={4} w="2xl">
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                value={basicProfileFormik.values.email}
                readOnly
                isDisabled
                id="email"
                name="email"
                placeholder="e.g john.doe@gmail.com"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="firstName">First Name</FormLabel>
              <Input
                placeholder="e.g John"
                {...basicProfileFormik.getFieldProps("firstName")}
                id="firstName"
                name="firstName"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="lastName">Last Name</FormLabel>
              <Input
                placeholder="e.g Doe"
                {...basicProfileFormik.getFieldProps("lastName")}
                id="lastName"
                name="lastName"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="username">Username</FormLabel>
              <Input
                placeholder="john.doe"
                {...basicProfileFormik.getFieldProps("username")}
                id="username"
                name="username"
              />
            </FormControl>
          </VStack>
        </Flex>
      </Box>
      <Box>
        <Flex justifyContent="space-around" w="full" padding={5} my="5">
          <Text margin={4} fontWeight="bold" fontSize="larger">
            User Profile Settings
          </Text>
          <VStack mt={10} spacing={4} w="2xl" mb="20">
            <FormControl>
              <FormLabel htmlFor="companyName">Company Name</FormLabel>
              <Input
                placeholder="e.g. Douglas Farms Pvt Ltd"
                {...userProfileFormik.getFieldProps("companyName")}
                id="companyName"
                name="companyName"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="address1">Address 1</FormLabel>
              <Input
                placeholder="e.g. (555) 555-1234"
                {...userProfileFormik.getFieldProps("address1")}
                id="address1"
                name="address1"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="address2">Address 2</FormLabel>
              <Input
                {...userProfileFormik.getFieldProps("address2")}
                id="address2"
                name="address2"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="country">Country</FormLabel>
              <Select
                {...userProfileFormik.getFieldProps("country")}
                id="country"
                name="country"
              >
                {countries.map((country) => {
                  return (
                    <option key={country.code3} value={country.name}>
                      {country.name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="city">City</FormLabel>
              <Input
                {...userProfileFormik.getFieldProps("city")}
                id="city"
                name="city"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="state">State</FormLabel>
              <Input
                {...userProfileFormik.getFieldProps("state")}
                id="state"
                name="state"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="phone">Phone</FormLabel>
              <Input
                {...userProfileFormik.getFieldProps("phone")}
                id="phone"
                name="phone"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="postalCode">Postal Code</FormLabel>
              <Input
                {...userProfileFormik.getFieldProps("postalCode")}
                id="postalCode"
                name="postalCode"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="taxNumber">Tax Number</FormLabel>
              <Input
                {...userProfileFormik.getFieldProps("taxNumber")}
                id="taxNumber"
                name="taxNumber"
              />
            </FormControl>
          </VStack>
        </Flex>
        <Flex
          zIndex="1"
          mb="0.5"
          ml="-10"
          justify="center"
          py="6"
          bgColor="Background"
          pos="fixed"
          bottom="0"
          w="full"
          justifyContent="center"
        >
          <Button
            w="md"
            py="2"
            isLoading={
              userProfileFormik.isSubmitting || basicProfileFormik.isSubmitting
            }
            type="button"
            colorScheme="brand"
            fontWeight={"normal"}
            size="lg"
            isDisabled={
              userProfileFormik.isSubmitting || basicProfileFormik.isSubmitting
            }
            onClick={() => {
              if (userProfileFormik.initialValues !== userProfileFormik.values)
                userProfileFormik.handleSubmit();
              if (
                basicProfileFormik.initialValues !== basicProfileFormik.values
              )
                basicProfileFormik.handleSubmit();
            }}
          >
            Save Changes
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default ProfileForm;
