import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  useToast,
  useToken,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useQuery } from "urql";
import * as Yup from "yup";
import { GET_MY_TEAM_INVITES, GET_MY_TEAM_MEMBERS } from "./teams.graphql";
import { useApi } from "../../hooks/useApi";
import { useSession } from "../../hooks/useSession";
import {
  GetMyTeamInvitesQuery,
  GetMyTeamInvitesQueryVariables,
  GetMyTeamMembersQuery,
  GetMyTeamMembersQueryVariables,
  Roles_Enum,
} from "@farmevo/common/dist/graphql/graphql";
import { IconPlus, IconSearch } from "../../Components/Icons";
import { API_ERRORS } from "@farmevo/common/dist/constants/api-errors";

const inviteMemberSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required."),
  role: Yup.string().oneOf(
    [Roles_Enum.Operator, Roles_Enum.Manager],
    "Invalid role."
  ),
});

const TeamMembers = () => {
  const { session } = useSession();
  const api = useApi();
  const gray500 = useToken("colors", "gray.500");
  const userId = session?.id;
  const toast = useToast();

  const [{ data: teamMembersData, fetching }] = useQuery<
    GetMyTeamMembersQuery,
    GetMyTeamMembersQueryVariables
  >({
    query: GET_MY_TEAM_MEMBERS,
    variables: {
      userId: userId || -1,
      roles: [Roles_Enum.Manager, Roles_Enum.Operator],
    },
    pause: !userId,
  });

  const [{ data: inviteData }] = useQuery<
    GetMyTeamInvitesQuery,
    GetMyTeamInvitesQueryVariables
  >({
    query: GET_MY_TEAM_INVITES,
    variables: { userId: userId || -1 },
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const formik = useFormik({
    initialValues: {
      email: "",
      role: "",
    },
    validationSchema: inviteMemberSchema,
    onSubmit: async (values) => {
      try {
        const response = await api.post("auth/invite", values);
        if (response.ok) {
          toast({
            title: "Invite sent",
            description: "Your invite has been sent to " + values.email,
            isClosable: true,
            status: "success",
          });
          onClose();
        } else {
          const error = await response.text();
          toast({
            title: "Error occured while sending the invite",
            description:
              response.status === 400 &&
              error === API_ERRORS.USER_ALREADY_EXISTS
                ? "User already exists"
                : "A server side error occured",
            status: "error",
          });
        }
      } catch (error) {
        Promise.reject(error);
      }
    },
  });

  if (fetching) {
    return <Spinner />;
  }

  return (
    <VStack p={10} align="unset" spacing={4}>
      <Text fontSize="2xl" fontWeight="bold">
        Team Members
      </Text>
      <Box>
        <HStack justifyContent="space-between">
          <InputGroup maxW="container.sm">
            <Input placeholder="Search for team members" />
            <InputRightElement>
              <IconSearch color={gray500} />
            </InputRightElement>
          </InputGroup>
          <Box>
            <Button
              mr="5"
              size="md"
              colorScheme="brand"
              onClick={onOpen}
              leftIcon={<IconPlus />}
            >
              Add Member
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add a new team member</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <form onSubmit={formik.handleSubmit}>
                    <FormControl
                      isInvalid={!!formik.errors.email}
                      my={4}
                      isRequired
                    >
                      <FormLabel htmlFor="email">Invitee Email</FormLabel>
                      <Input
                        {...formik.getFieldProps("email")}
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter the email of member you'd like to invite"
                      />
                      <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={!!formik.errors.role && formik.touched.role}
                      isRequired
                    >
                      <FormLabel htmlFor="role">User role</FormLabel>
                      <Select
                        my={4}
                        {...formik.getFieldProps("role")}
                        name="role"
                        id="role"
                        placeholder="Select the role you'd like this user to have"
                      >
                        <option value="manager">Manager</option>
                        <option value="operator">Operator</option>
                      </Select>
                      <FormErrorMessage>{formik.errors.role}</FormErrorMessage>
                    </FormControl>
                    <ModalFooter>
                      <Button
                        isLoading={formik.isSubmitting}
                        colorScheme="brand"
                        isDisabled={formik.isSubmitting || !formik.isValid}
                        type="submit"
                      >
                        Invite
                      </Button>
                    </ModalFooter>
                  </form>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        </HStack>
      </Box>
      <Box>
        {!!teamMembersData?.team_members &&
        teamMembersData?.team_members.length > 0 ? (
          <TableContainer whiteSpace="normal">
            <Table>
              <Thead>
                <Tr>
                  <Th>User</Th>
                  <Th>Role</Th>
                </Tr>
              </Thead>
              <Tbody>
                {teamMembersData?.team_members.map((member) => {
                  return (
                    <Tr key={member.teamMember.id}>
                      <Td>{member.teamMember.username}</Td>
                      <Td>{member.teamMember.roles[0].role}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Box>
            <Text ml="5">
              <b>You do not have any team members yet.</b>
              <br /> Click on the add member button to get started
            </Text>
          </Box>
        )}
      </Box>
      {!!inviteData?.user_invites && inviteData?.user_invites.length > 0 && (
        <VStack py={8} align="unset">
          <Text color="gray.600" fontWeight="semibold">
            Pending invites
          </Text>
          <TableContainer whiteSpace="normal">
            <Table>
              <Thead>
                <Tr>
                  <Th>Email</Th>
                  <Th>Role</Th>
                </Tr>
              </Thead>
              <Tbody>
                {inviteData?.user_invites.map((invitee) => {
                  return (
                    <Tr key={invitee.id}>
                      <Td>{invitee.invitee_email}</Td>
                      <Td>{invitee.invitee_role}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </VStack>
      )}
    </VStack>
  );
};

export default TeamMembers;
