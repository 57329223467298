export const IconJobReady = ({
  size,
  color,
}: {
  size: string;
  color: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill={color}
    width={size}
    height={size}
  >
    <polyline
      points="15.68 10.7 10.02 16.37 8.32 14.67"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <rect
      x="2"
      y="7"
      width="20"
      height="14"
      rx="2"
      strokeWidth="2"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M16,7V5a2,2,0,0,0-2-2H10A2,2,0,0,0,8,5V7"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);
