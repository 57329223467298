import { UseDisclosureReturn } from "@chakra-ui/react";
import { createContext, useContext } from "react";

export const SideNavContext = createContext<UseDisclosureReturn>({
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
  onToggle: () => {},
  isControlled: false,
  getButtonProps: () => {},
  getDisclosureProps: () => {},
});

export const useSideNav = () => {
  return useContext(SideNavContext);
};
