import { useSession } from "./useSession";

export const useApi = () => {
  const { session } = useSession();
  return {
    post: (path: string, body?: object) => {
      const initOpts: RequestInit = {
        method: "POST",
        headers: {
          authorization: `Bearer ${session?.token}`,
          ...(body && { "Content-Type": "application/json" }),
        },
      };

      if (body) {
        initOpts.body = JSON.stringify(body);
      }

      return fetch(`${process.env.REACT_APP_API_URL}/${path}`, initOpts);
    },
  };
};
