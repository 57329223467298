import { HStack, Image, Text, VStack } from "@chakra-ui/react";
import { IconBookmark, IconHeart, IconRotateCcw } from "../../Components/Icons";
import placeholderChart from "../../assets/placeholder_chart.svg";
import placeholderChart2 from "../../assets/placeholder_cost_of_production.svg";
import { useSession } from "../../hooks/useSession";
import { Roles_Enum } from "@farmevo/common/dist/graphql/graphql";
import { Navigate } from "react-router-dom";

const Welcome = () => {
  const { session } = useSession();

  const isOperator = !!session?.user?.roles.find(
    (r) => r.role === Roles_Enum.Operator
  );

  if (isOperator) {
    return <Navigate to="/jobs" />;
  }

  return (
    <VStack p={10} align="unset" spacing={4}>
      <Text fontWeight="bold" fontSize="2xl">
        Welcome to Farmevo
      </Text>
      <Image src={placeholderChart} />
      <Image src={placeholderChart2} />
      <Text pt={4}>
        Make the most of farmevo using pinned items, pages you visited and our
        customers' favorite tutorials
      </Text>
      <HStack py={10} spacing={6}>
        <LayoutWelcomeStack title="Your pinned items" icon={<IconBookmark />} />
        <LayoutWelcomeStack
          title="Recently visited items"
          icon={<IconRotateCcw />}
        />
        <LayoutWelcomeStack title="Popular tutorials" icon={<IconHeart />} />
      </HStack>
    </VStack>
  );
};

const LayoutWelcomeStack = ({
  title,
  icon,
}: {
  title: string;
  icon: React.ReactNode;
}) => (
  <VStack flex={1} maxWidth="240px" align="start">
    <HStack>
      {icon}
      <Text fontWeight="bold" fontSize="sm">
        {title}
      </Text>
    </HStack>
    <Text fontSize="sm" pl={8} pt={2} color="gray.500" w="full">
      Nothing here yet
    </Text>
  </VStack>
);

export default Welcome;
