import { gql } from "urql";

export const GET_MY_IMPLEMENTS = gql`
  query GetMyImplements(
    $filter: implements_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    implements_aggregate(where: $filter) {
      aggregate {
        count
      }
    }
    implements(
      where: $filter
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
    ) {
      brand
      length
      model
      name
      ownership
      status
      weight
      width
      id
      userId
    }
  }
`;

export const UPDATE_MY_IMPLEMENT = gql`
  mutation UpdateMyImplement(
    $updateImplementPayload: implements_set_input!
    $id: uuid!
  ) {
    update_implements_by_pk(
      pk_columns: { id: $id }
      _set: $updateImplementPayload
    ) {
      brand
      length
      model
      name
      ownership
      status
      weight
      width
      id
      userId
    }
  }
`;

export const SAVE_MY_IMPLEMENT = gql`
  mutation SaveMyImplement($saveImplementPayload: implements_insert_input!) {
    insert_implements_one(object: $saveImplementPayload) {
      brand
      length
      model
      name
      ownership
      status
      weight
      width
      id
      userId
    }
  }
`;

export const UPDATE_MY_IMPLEMENT_STATUS = gql`
  mutation UpdateMyImplementStatus($status: entity_status_enum!, $id: uuid!) {
    update_implements_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status }
    ) {
      status
      id
    }
  }
`;
