import { gql } from "urql";

export const GET_JOB_DETAILS_QUERY = gql`
  query GetJobDetails($jobId: bigint!, $isOperator: Boolean!) {
    jobs_by_pk(id: $jobId) {
      id
      userId @skip(if: $isOperator)
      user @skip(if: $isOperator) {
        id
        profile {
          companyName
          address1
          address2
          city
          state
          country
          postalCode
          phone
        }
      }
      operationName
      operation @skip(if: $isOperator) {
        id
        billingRate
        billingUnit
      }
      instructions
      assignedUser @skip(if: $isOperator) {
        id
        firstName
        profile {
          companyName
          address1
          address2
          city
          state
          country
          postalCode
          phone
        }
      }
      extras {
        name
        unit
        value
      }
      from
      to
      status
      createdAt
      completedAt
      locations {
        id
        notes
        geoLocation
      }
      products {
        productId
        jobId
        locked
        value
        product {
          id
          name
          chargeableUnit
          pricePerUnit @skip(if: $isOperator)
        }
      }
      operators {
        teamLead
        user {
          id
          firstName
        }
        vehicles {
          vehicle {
            id
            name
            model
            brand
          }
        }
        implements {
          implement {
            id
            name
            model
            brand
          }
        }
      }
      fields {
        seasonShot {
          id
          name
          farmId
          polygon {
            id
            size
            geometry
          }
        }
      }
    }
  }
`;

export const UPDATE_JOB_STATUS_MUTATION = gql`
  mutation UpdateJobStatus($jobId: bigint!, $status: job_status_enum!) {
    update_jobs_by_pk(pk_columns: { id: $jobId }, _set: { status: $status }) {
      id
      status
      completedAt
    }
  }
`;
