import {
  Box,
  HStack,
  Link,
  Progress,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { GoogleMap } from "@react-google-maps/api";
import { useQuery } from "urql";
import { Link as RouteLink } from "react-router-dom";
import { IconPlus } from "../../Components/Icons";
import Button from "../../Components/Button";
import { useSession } from "../../hooks/useSession";
import { useSideNav } from "../../hooks/useSideNav";
import FieldSidePanel, { FieldSidePanelMode } from "./FieldSidePanel";
import { GET_FARMS_QUERY, GET_FIELDS_QUERY } from "./fields.graphql";
import {
  GetFarmsQuery,
  GetFarmsQueryVariables,
  GetFieldsQuery,
  GetFieldsQueryVariables,
} from "@farmevo/common/dist/graphql/graphql";
import {
  fromDbPolygonToPathArray,
  getPathBounds,
  getPolygonBounds,
  getPolygonCenter,
} from "../../utils/polygon";
import { roundToTwo } from "../../utils/math";
import ComboSelect from "../../Components/ComboSelect";

const defaultFarm = {
  id: -1,
  name: "All farms",
};

const Fields = () => {
  const { session } = useSession();
  const [sidePanelMode, setSidePanelMode] = useState<FieldSidePanelMode>(null);
  const [selectedFieldShotId, setSelectedFieldShotId] = useState<
    GetFieldsQuery["field_season_shot"][0]["id"] | null
  >(null);
  const [selectedFarm, setSelectedFarm] = useState<
    GetFarmsQuery["farms"][0] | null
  >(defaultFarm);
  const [newPolygon, setNewPolygon] = useState<google.maps.Polygon | null>(
    null
  );

  const [drawingManager, setDrawingManager] =
    useState<google.maps.drawing.DrawingManager | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const {
    isOpen: isFieldSidePanelOpen,
    onOpen: openFieldSidePanel,
    onClose: closeFieldSidePanel,
    getDisclosureProps: getFieldSidePanelProps,
  } = useDisclosure();
  const { onClose: closeSideNav } = useSideNav();

  const [{ data: fieldsData, fetching: loadingFields }] = useQuery<
    GetFieldsQuery,
    GetFieldsQueryVariables
  >({
    query: GET_FIELDS_QUERY,
    variables: {
      filter: {
        farm: { userId: { _eq: session?.id } },
        ...(selectedFarm?.id > 0 && { farmId: { _eq: selectedFarm?.id } }),
      },
    },
    pause: !session?.id,
  });

  const [{ data: farmsData, fetching: loadingFarms }] = useQuery<
    GetFarmsQuery,
    GetFarmsQueryVariables
  >({
    query: GET_FARMS_QUERY,
    variables: { userId: session?.id },
    pause: !session?.id,
  });

  useEffect(() => {
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        center: session?.user?.prefs?.geoLocation,
        zoom: 14,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        streetViewControl: false,
      }
    );

    const _drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        strokeColor: session?.user?.prefs?.fieldColor,
        fillColor: session?.user?.prefs?.fieldColor,
        editable: true,
      },
    });

    google.maps.event.addListener(
      _drawingManager,
      "polygoncomplete",
      (polygon: google.maps.Polygon) => {
        setNewPolygon(polygon);
        _drawingManager.setDrawingMode(null);
        _drawingManager.setOptions({
          drawingControl: false,
        });
      }
    );

    _drawingManager.setMap(map);
    setDrawingManager(_drawingManager);
    setMap(map);

    return () => {
      google.maps.event.clearListeners(_drawingManager, "polygoncomplete");
    };
  }, [session?.user]);

  const clearNewPolygon = () => {
    if (!drawingManager) return;

    drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    drawingManager.setOptions({
      drawingControl: true,
    });

    if (!newPolygon) return;

    newPolygon.setMap(null);
    setNewPolygon(null);
  };

  const onOpenAddFieldForm = () => {
    setSidePanelMode("add");
    closeSideNav();
    openFieldSidePanel();
    clearNewPolygon();
  };

  const onCloseFieldSidePanel = () => {
    if (sidePanelMode === "edit") {
      setSidePanelMode("view");
      return;
    }

    closeFieldSidePanel();
    resetDrawingMode();
    setSidePanelMode(null);
    setSelectedFieldShotId(null);
    if (!map) return;

    map.setCenter(session?.user?.prefs?.geoLocation);
    map.setZoom(14);
  };

  const onOpenEditFieldForm = () => {
    setSidePanelMode("edit");
  };

  const resetDrawingMode = () => {
    if (!drawingManager) return;

    drawingManager.setDrawingMode(null);
    drawingManager.setOptions({
      drawingControl: false,
    });

    if (!newPolygon) return;

    newPolygon.setMap(null);
    setNewPolygon(null);
  };

  useEffect(() => {
    if (!map || !fieldsData?.field_season_shot) {
      return;
    }

    const infowindow = new google.maps.InfoWindow({
      ariaLabel: "Field details",
    });

    const _allPolygons = fieldsData.field_season_shot.map((fieldShot) => {
      const polygon = new google.maps.Polygon({
        map,
        paths:
          // If the polygon is being edited and field being saved,
          // prevent from briefly showing the older state on a slow connection
          selectedFieldShotId === fieldShot.id &&
          newPolygon &&
          sidePanelMode === "edit"
            ? newPolygon?.getPath()
            : fromDbPolygonToPathArray(fieldShot.polygon.geometry),
        strokeColor: session?.user?.prefs?.fieldColor,
        fillColor: session?.user?.prefs?.fieldColor,
        fillOpacity: selectedFieldShotId === fieldShot.id ? 0.8 : 0.5,
        editable:
          selectedFieldShotId === fieldShot.id && sidePanelMode === "edit",
      });

      polygon.set("farmId", fieldShot.farm.id);

      polygon.getPath().addListener("set_at", () => {
        setNewPolygon(polygon);
      });

      polygon.getPath().addListener("insert_at", () => {
        setNewPolygon(polygon);
      });

      polygon.getPath().addListener("remove_at", () => {
        setNewPolygon(polygon);
      });

      // Disable click and hover events if:
      // 1. mode is add or edit
      // 2. current polygon is already selected
      if (
        (sidePanelMode && sidePanelMode !== "view") ||
        selectedFieldShotId === fieldShot.id
      ) {
        return polygon;
      }

      const polygonCenter = getPolygonCenter(polygon);

      const contentString = `<div style="min-width:180px;min-height:50px">
        <h1 style="font-weight:bold;">${fieldShot.name}</h1>
        <div style="display:inline-block;border-radius:0.2rem;padding:6px 8px;margin-top:0.5rem; background-color:var(--chakra-colors-brand-100);">${roundToTwo(
          fieldShot.polygon.size
        )} acre</div>
        <div style="display:inline-block;margin-left:1rem;">${
          fieldShot.season
        }</div>
        ${
          fieldShot.cropName &&
          `<div style="margin-top:1rem;">${fieldShot.cropName}</div>`
        }
      </div>`;

      polygon.addListener("mouseover", () => {
        polygon.setOptions({
          fillOpacity: 0.6,
        });
        infowindow.open({
          map,
          anchor: polygon,
        });
        infowindow.setContent(contentString);
        infowindow.setPosition(polygonCenter);
      });

      polygon.addListener("mouseout", () => {
        polygon.setOptions({
          fillOpacity: null,
        });
        infowindow.close();
      });

      polygon.addListener("click", () => {
        map.setCenter(polygonCenter);
        map.fitBounds(getPolygonBounds(polygon));
        openFieldSidePanel();
        setSidePanelMode("view");
        setSelectedFieldShotId(fieldShot.id);
        closeSideNav();
      });

      return polygon;
    });

    // Fit map to polygons
    if (sidePanelMode === null) {
      const farmPolygons = _allPolygons.filter(
        (p) => selectedFarm?.id < 0 || p.get("farmId") === selectedFarm?.id
      );

      if (farmPolygons.length > 0) {
        const bounds = farmPolygons.reduce(
          (finalBounds, nextPolygon) =>
            finalBounds.union(getPolygonBounds(nextPolygon)),
          new google.maps.LatLngBounds()
        );
        map.fitBounds(bounds, 0);
      }
    }

    return () => {
      _allPolygons.forEach((p) => {
        p.setMap(null);
        google.maps.event.clearInstanceListeners(p);
        google.maps.event.clearInstanceListeners(p.getPath());
      });
    };
  }, [
    map,
    fieldsData,
    selectedFieldShotId,
    newPolygon,
    session,
    sidePanelMode,
    openFieldSidePanel,
    closeSideNav,
    selectedFarm,
  ]);

  return (
    <VStack p={isFieldSidePanelOpen ? 0 : 10} align="unset" spacing={4}>
      {!isFieldSidePanelOpen && (
        <Text fontWeight="bold" fontSize="2xl">
          Fields
        </Text>
      )}
      <Box>
        {!isFieldSidePanelOpen && (
          <HStack mb={4}>
            <Box maxW="xs">
              <ComboSelect<GetFieldsQuery["field_season_shot"][0]>
                items={fieldsData?.field_season_shot}
                itemsFilter={(v) => (fieldShot) =>
                  !!fieldShot.name?.toLowerCase().includes(v.toLowerCase())}
                itemToString={(fieldShot) => fieldShot?.name || ""}
                label=""
                searchBehavior="reduce"
                labelProps={{ style: { display: "none" } }}
                inputProps={{
                  id: "field",
                  name: "field",
                  placeholder:
                    "Search fields" +
                    (selectedFarm?.id > -1
                      ? ` in ${selectedFarm?.name.toLowerCase()}`
                      : ""),
                }}
                onSelectItem={(fieldShot) => {
                  if (!fieldShot) return;
                  map?.fitBounds(
                    getPathBounds(
                      fromDbPolygonToPathArray(fieldShot.polygon.geometry)
                    )
                  );
                }}
                renderItem={(item) => (
                  <Box>
                    <span>{item?.name}</span>
                  </Box>
                )}
                menuProps={{ maxH: "200px" }}
                renderEmptyState={() =>
                  loadingFields && (
                    <Progress
                      mt="-1px"
                      position="absolute"
                      width="calc(100% - 10px)"
                      ml="5px"
                      size="xs"
                      height="1.25px"
                      bgColor="unset"
                      colorScheme="brand"
                      isIndeterminate
                    />
                  )
                }
              />
            </Box>
            <Box maxW="xs">
              <ComboSelect<GetFarmsQuery["farms"][0]>
                items={[defaultFarm, ...(farmsData?.farms || [])]}
                itemsFilter={(v) => (farm) =>
                  farm.name.toLowerCase().includes(v.toLowerCase())}
                itemToString={(farm) => farm?.name || ""}
                label=""
                searchBehavior="reduce"
                labelProps={{ style: { display: "none" } }}
                inputProps={{
                  id: "farm",
                  name: "farm",
                  placeholder: "Select farm",
                }}
                defaultSelectedItem={selectedFarm}
                onSelectItem={(farm) => setSelectedFarm(farm || null)}
                renderItem={(item) => (
                  <Box>
                    <span>{item?.name}</span>
                  </Box>
                )}
                menuProps={{ maxH: "200px" }}
                renderEmptyState={() =>
                  loadingFarms && (
                    <Progress
                      mt="-1px"
                      position="absolute"
                      width="calc(100% - 10px)"
                      ml="5px"
                      size="xs"
                      height="1.25px"
                      bgColor="unset"
                      colorScheme="brand"
                      isIndeterminate
                    />
                  )
                }
              />
            </Box>
            <Box flex={1} textAlign="right">
              <Button leftIcon={<IconPlus />} onClick={onOpenAddFieldForm}>
                Add Field
              </Button>
            </Box>
          </HStack>
        )}
        <HStack alignItems="start">
          <Box flex="1">
            <GoogleMap
              center={
                session?.user?.prefs?.geoLocation || {
                  lat: 43.45,
                  lng: -80.49,
                }
              }
              mapContainerStyle={{
                height: isFieldSidePanelOpen ? "calc(100vh - 64px)" : "450px",
                flex: 1,
              }}
              id="map"
            >
              {sidePanelMode === "add" && (
                <Button
                  position="absolute"
                  right="60px"
                  top="10px"
                  onClick={clearNewPolygon}
                >
                  Clear
                </Button>
              )}
            </GoogleMap>
            {!session?.user?.prefs?.geoLocation && (
              <Text mt={2}>
                Set your default location in{" "}
                <Link
                  as={RouteLink}
                  fontWeight="bold"
                  to="/account"
                  color="brand.500"
                >
                  Account Preferences
                </Link>
              </Text>
            )}
          </Box>
          <FieldSidePanel
            flex={1}
            maxW="320px"
            mode={sidePanelMode}
            onClose={onCloseFieldSidePanel}
            onOpenEditFieldForm={onOpenEditFieldForm}
            newPolygon={newPolygon}
            fieldShotId={selectedFieldShotId}
            fieldsData={fieldsData}
            defaultSelectedFarm={selectedFarm}
            onSubmitForm={(newFieldShot) => {
              setSidePanelMode("view");
              if (newFieldShot) {
                setSelectedFieldShotId(newFieldShot.id);
                resetDrawingMode();
              }
              if (newFieldShot && selectedFarm?.id !== newFieldShot?.farm.id) {
                setSelectedFarm(defaultFarm);
              }
              setNewPolygon(null);
            }}
            {...getFieldSidePanelProps()}
          />
        </HStack>
      </Box>
    </VStack>
  );
};

export default Fields;
