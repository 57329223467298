import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Link,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import {
  useNavigate,
  useSearchParams,
  Link as RouteLink,
  Navigate,
} from "react-router-dom";
import { useMutation } from "urql";
import { useFormik } from "formik";
import { useContext, useMemo, useState } from "react";
import * as Yup from "yup";
import {
  ApiAcceptInviteMutation,
  ApiAcceptInviteMutationVariables,
  ApiSignUpUserMutation,
  ApiSignUpUserMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { API_ERRORS } from "@farmevo/common/dist/constants/api-errors";
import {
  API_ACCEPT_INVITE_MUTATION,
  API_SIGN_UP_USER_MUTATION,
} from "../SignUp/signup.graphql";
import { SessionContext } from "../../hooks/useSession";

const inviteSignUpSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required !"),
  password: Yup.string()
    .min(6, "Password must be longer than 6 characters.")
    .required("Password is required."),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Passwords must match."
  ),
});

export const Invite = () => {
  const { session, setSession } = useContext(SessionContext);
  const [loading] = useState(false);
  const navigate = useNavigate();
  const [, signUp] = useMutation<
    ApiSignUpUserMutation,
    ApiSignUpUserMutationVariables
  >(API_SIGN_UP_USER_MUTATION);

  const [,] = useMutation<
    ApiAcceptInviteMutation,
    ApiAcceptInviteMutationVariables
  >(API_ACCEPT_INVITE_MUTATION);

  const toast = useToast();
  const params = useSearchParams();
  const [inviteCode, inviteeEmail, host] = useMemo(
    () => atob(params[0].get("code")!).split(":"),
    [params]
  );

  const formik = useFormik({
    initialValues: {
      email: inviteeEmail,
      password: "",
      firstName: "",
      lastName: "",
      confirmPassword: "",
    },
    validationSchema: inviteSignUpSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const result = await signUp({
        input: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
          inviteCode,
        },
      });

      const token = result.data?.api_sign_up_user?.token;
      const id = result.data?.api_sign_up_user?.id;

      if (!token || !id) {
        toast({
          title: "An error occurred",
          description: result.error?.graphQLErrors.find(
            (e) => e.message === API_ERRORS.USER_ALREADY_EXISTS
          )
            ? "User with given email already exists, try logging in instead"
            : "Unknown error occurred, please try again or contact support",
          status: "error",
          isClosable: true,
        });
        return;
      }

      toast({
        title: "Account created sucessfully",
        description:
          "Your account has been created sucessfully. Redirecting ...",
        status: "success",
        isClosable: true,
      });

      setSession({
        token,
        id,
      });

      navigate("/");
    },
  });

  // useEffect(() => {
  //   if (session?.token) {
  //     (async () => {
  //       setLoading(true);
  //       const result = await acceptInvite({
  //         input: {
  //           inviteCode,
  //         },
  //       });
  //       if (result.data?.api_accept_invite?.ok) {
  //         navigate("/");
  //       }
  //       setLoading(false);
  //     })();
  //   }
  // }, [inviteCode, session]);

  if (!inviteCode || !inviteeEmail || !host) {
    toast({
      title: "Invalid invite code",
      description:
        "Invite code is not valid, try opening the link again or contact our support team",
      status: "error",
    });
    return <Navigate to={session?.token ? "/" : "/login"} />;
  }

  if (loading) {
    return (
      <Flex
        h="100vh"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner color="brand.500" />
      </Flex>
    );
  }

  if (session?.token) {
    return (
      <VStack h="100vh" justifyContent="center" alignItems="center" spacing={4}>
        <Text>
          You have an invitation to join {host}'s team. Click accept to join
        </Text>
        <HStack>
          <Button>Cancel</Button>
          <Button colorScheme="blue">Accept</Button>
        </HStack>
      </VStack>
    );
  }

  return (
    <Box>
      <Flex
        w="full"
        h="100vh"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Text>
          Welcome {inviteeEmail}, you have been invited to join {host}'s team on
          Farmevo.
        </Text>
        <Box
          border="1px"
          borderRadius="2xl"
          p="4"
          my="5
        "
        >
          <form onSubmit={formik.handleSubmit}>
            <Text my="2" textAlign="center" fontSize="xl">
              We'll need some details to get you started.
            </Text>
            <Flex my="4">
              <FormControl
                mx="2"
                isRequired
                isInvalid={
                  !!formik.errors.firstName && formik.touched.firstName
                }
              >
                <FormLabel htmlFor="firstName">First Name</FormLabel>
                <Input
                  {...formik.getFieldProps("firstName")}
                  id="firstName"
                  name="firstName"
                />
                <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
              </FormControl>
              <FormControl mx="2">
                <FormLabel htmlFor="lastName">Last Name</FormLabel>
                <Input
                  {...formik.getFieldProps("lastName")}
                  id="lastName"
                  name="lastName"
                />
              </FormControl>
            </Flex>
            <FormControl
              my="2"
              isRequired
              isInvalid={!!formik.errors.password && formik.touched.password}
            >
              <FormLabel htmlFor="password">Password</FormLabel>
              <Input
                {...formik.getFieldProps("password")}
                name="password"
                id="password"
                type="password"
                placeholder="Password"
              />
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            </FormControl>
            <FormControl
              my="2"
              isRequired
              isInvalid={
                !!formik.errors.confirmPassword &&
                formik.touched.confirmPassword
              }
            >
              <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
              <Input
                type="password"
                {...formik.getFieldProps("confirmPassword")}
                name="confirmPassword"
                placeholder="Confirm your password."
              />
              <FormErrorMessage>
                {formik.errors.confirmPassword}
              </FormErrorMessage>
            </FormControl>
            <Button my="4" colorScheme="brand" type="submit">
              Submit
            </Button>
          </form>
        </Box>
        <HStack>
          <Text>Have an account?</Text>
          <Link
            as={RouteLink}
            to="/login"
            color="brand.500"
            fontWeight="semibold"
          >
            Login
          </Link>
        </HStack>
      </Flex>
    </Box>
  );
};
