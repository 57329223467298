import {
  useToast,
  Spinner,
  Flex,
  VStack,
  FormControl,
  FormLabel,
  Select,
  Input,
  Button,
  Box,
  Text,
  Divider,
} from "@chakra-ui/react";
import {
  GetMyUserPreferencesQuery,
  GetMyUserPreferencesQueryVariables,
  SaveMyUserPreferencesMutation,
  SaveMyUserPreferencesMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { useFormik } from "formik";
import { useState } from "react";
import { useQuery, useMutation } from "urql";
import ColorPickerField from "../../Components/ColorPickerField";
import ComboSelect from "../../Components/ComboSelect";
import { MapWithAutoComplete } from "../../Components/GoogleMap";
import { Currency, currencies } from "../../constants/currency";
import { Timezone, timezones } from "../../constants/timezone";
import { useSession } from "../../hooks/useSession";
import {
  currenciesFilter,
  currencyToString,
  timezonesFilter,
  timezoneToString,
} from "../../utils/filters";
import {
  GET_MY_USER_PREFERENCES,
  SAVE_MY_USER_PREFERENCES,
} from "./accounts.graphql";

const PreferencesForm = () => {
  const { session } = useSession();
  const [submitting, setSubmitting] = useState(false);

  const userId = session?.id || -1;

  const toast = useToast();

  const [{ data, fetching }] = useQuery<
    GetMyUserPreferencesQuery,
    GetMyUserPreferencesQueryVariables
  >({
    query: GET_MY_USER_PREFERENCES,
    variables: { userId },
    pause: !userId,
  });

  const [, updateUserPreferences] = useMutation<
    SaveMyUserPreferencesMutation,
    SaveMyUserPreferencesMutationVariables
  >(SAVE_MY_USER_PREFERENCES);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data?.user_prefs[0] || {
      currency: "",
      fieldColor: "",
      fieldUnit: "",
      geoLocation: "",
      invoicePayInfo: "",
      invoiceTerms: 0,
      timezone: "",
    },

    onSubmit: async (values) => {
      setSubmitting(true);
      const result = await updateUserPreferences({
        userId: userId,
        updateUserPreferencesPayload: {
          currency: values.currency,
          fieldColor: values.fieldColor,
          fieldUnit: values.fieldUnit,
          geoLocation: values.geoLocation,
          invoicePayInfo: values.invoicePayInfo,
          invoiceTerms: values.invoiceTerms,
          timezone: values.timezone,
        },
      });

      toast({
        title: result.error
          ? "An error occured during update."
          : "Settings Updated",
        status: result.error ? "error" : "success",
        duration: 3000,
        isClosable: true,
      });

      setSubmitting(false);
    },
  });

  if (fetching) {
    return <Spinner />;
  }

  return (
    <Box margin={5}>
      <form onSubmit={formik.handleSubmit}>
        <Flex justifyContent="space-around" w="full" padding={5} my="5">
          <Text margin={4} fontWeight="bold" fontSize="larger">
            General User Settings
          </Text>
          <VStack spacing={4} w="2xl" mt="10">
            <ComboSelect<Currency>
              items={currencies}
              itemsFilter={currenciesFilter}
              itemToString={currencyToString}
              label="Currency"
              inputProps={{
                id: "currency",
                name: "currency",
                placeholder: "Select currency",
                value: formik.values?.currency || "",
              }}
              onSelectItem={(item) =>
                formik.setFieldValue("currency", item?.code)
              }
              renderItem={(item) => (
                <span>
                  {item?.name} ({item?.symbol})
                </span>
              )}
            />
            <ComboSelect<Timezone>
              items={timezones}
              itemsFilter={timezonesFilter}
              itemToString={timezoneToString}
              label="Time zone"
              inputProps={{
                id: "timezone",
                name: "timezone",
                placeholder: "Select timezone",
                value: formik.values?.timezone || "",
              }}
              onSelectItem={(item) =>
                formik.setFieldValue("timezone", item?.tzCode)
              }
              renderItem={(item) => <span>{item?.label}</span>}
            />
          </VStack>
        </Flex>
        <Flex justifyContent="space-around" w="full" padding={5} my="5">
          <Text margin={4} fontWeight="bold" fontSize="larger">
            General Invoice Settings
          </Text>
          <VStack spacing={4} w="2xl" mt="10">
            <FormControl>
              <FormLabel htmlFor="invoiceTerms">Invoice Terms</FormLabel>
              <Input
                type="number"
                id="invoiceTerms"
                {...formik.getFieldProps("invoiceTerms")}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="invoicePayInfo">Invoice Pay Info</FormLabel>
              <Input
                id="invoicePayInfo"
                {...formik.getFieldProps("invoicePayInfo")}
              />
            </FormControl>
          </VStack>
        </Flex>
        <Flex justifyContent="space-around" w="full" padding={5} my="5">
          <Text margin={4} fontWeight="bold" fontSize="larger">
            General Default Settings
          </Text>
          <VStack spacing={4} w="2xl" mt="10">
            <ColorPickerField
              zIndex={1}
              // defaultValue={formik.initialValues.fieldColor || undefined}
              label="Default field color"
              onChange={(color) => {
                formik.setFieldValue("fieldColor", color);
              }}
            />

            <FormControl>
              <FormLabel htmlFor="unit">Field measurement unit</FormLabel>
              <Select
                id="unit"
                {...formik.getFieldProps("fieldUnit")}
                placeholder="Select unit"
              >
                <option value="hectare">Hectare</option>
                <option value="acre">Acre</option>
              </Select>
            </FormControl>
          </VStack>
        </Flex>
        <Divider my="10" />
        <Text
          mt="5"
          textAlign="center"
          margin={4}
          fontWeight="bold"
          fontSize="larger"
        >
          Default Map Location
        </Text>
        <Box mx="5" mb="36" p={2}>
          <MapWithAutoComplete
            onChangeLocation={formik.setFieldValue}
            defaultLocation={{
              lat: parseFloat(
                data?.user_prefs[0]?.geoLocation?.split(",")[0].substring(1)
              ),
              lng: parseFloat(
                data?.user_prefs[0]?.geoLocation?.split(",")[1].slice(0, -1)
              ),
            }}
          />
        </Box>
        <Flex
          zIndex="1"
          mb="0.5"
          ml="-10"
          justify="center"
          py="6"
          bgColor="Background"
          pos="fixed"
          bottom="0"
          w="full"
          justifyContent="center"
        >
          <Button
            w="md"
            py="2"
            isLoading={submitting}
            type="submit"
            colorScheme="brand"
            fontWeight={"normal"}
            size="lg"
          >
            Save Changes
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default PreferencesForm;
