import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  DeleteMyOperationProductMutation,
  GetMyOperationsQuery,
  GetMyOperationsQueryVariables,
  Mutation_RootDelete_Operation_Products_By_PkArgs,
  Mutation_RootInsert_Operation_Products_OneArgs,
  InsertMyOperationMutation,
  InsertMyOperationMutationVariables,
  SaveMyOperationProductMutation,
  UpdateMyOperationMutation,
} from "@farmevo/common/dist/graphql/graphql";
import { GET_MY_OPERATIONS } from "@farmevo/web/src/Pages/Operations/operations.graphql";

export const update_operations_by_pk: UpdateResolver = (
  result,
  args,
  cache
) => {
  const mutationResult = result as UpdateMyOperationMutation;

  cache.updateQuery<GetMyOperationsQuery, GetMyOperationsQueryVariables>(
    {
      query: GET_MY_OPERATIONS,
      variables: {
        userId: mutationResult.update_operations_by_pk?.userId || -1,
      },
    },
    (data) => {
      const opIdx =
        data?.operations.findIndex((op) => {
          return op.id === mutationResult.update_operations_by_pk?.id;
        }) || -1;
      if (opIdx !== -1 && data && mutationResult.update_operations_by_pk)
        data.operations[opIdx] = mutationResult.update_operations_by_pk;

      return data;
    }
  );
};

export const delete_operation_products_by_pk: UpdateResolver = (
  result,
  args,
  cache
) => {
  const variables = args as Mutation_RootDelete_Operation_Products_By_PkArgs;
  const mutationResult = result as DeleteMyOperationProductMutation;
  const _uid = mutationResult.delete_operation_products_by_pk?.product.userId;
  cache.updateQuery<GetMyOperationsQuery, GetMyOperationsQueryVariables>(
    {
      query: GET_MY_OPERATIONS,
      variables: {
        userId: _uid || -1,
      },
    },
    (data) => {
      const opIdx =
        data?.operations.findIndex((op) => {
          return op.id === variables.operationId;
        }) || -1;
      if (
        opIdx !== -1 &&
        data &&
        mutationResult.delete_operation_products_by_pk
      ) {
        data.operations[opIdx].products = data.operations[
          opIdx
        ].products.filter((val) => val.product.id !== variables.productId);
      }
      return data;
    }
  );
};

export const insert_operation_products_one: UpdateResolver = (
  result,
  args,
  cache
) => {
  const variables = args as Mutation_RootInsert_Operation_Products_OneArgs;
  const mutationResult = result as SaveMyOperationProductMutation;
  const _uid = variables.object.userId;

  cache.updateQuery<GetMyOperationsQuery, GetMyOperationsQueryVariables>(
    {
      query: GET_MY_OPERATIONS,
      variables: {
        userId: _uid || -1,
      },
    },
    (data) => {
      const opIdx = data?.operations.findIndex((op) => {
        return op.id === variables.object.operationId;
      });
      if (opIdx && data && mutationResult.insert_operation_products_one) {
        const productId =
          mutationResult.insert_operation_products_one.product.id;
        data.operations[opIdx].products.push({
          productId,
          operationId: data.operations[opIdx].id,
          product: {
            id: mutationResult.insert_operation_products_one.product.id,
            name: mutationResult.insert_operation_products_one.product.name,
          },
        });
      }
      return data;
    }
  );
};

export const insert_operations_one: UpdateResolver = (result, args, cache) => {
  const variables =
    args.object as InsertMyOperationMutationVariables["saveOperationPayload"];
  const newOperation = (result as InsertMyOperationMutation)
    .insert_operations_one;

  cache.updateQuery<GetMyOperationsQuery, GetMyOperationsQueryVariables>(
    {
      query: GET_MY_OPERATIONS,
      variables: {
        userId: variables.userId || -1,
      },
    },
    (data) => {
      const operationAlreadyExists = !!data?.operations.find((op) => {
        return op.id === newOperation?.id;
      });
      if (!operationAlreadyExists && newOperation) {
        data?.operations.push(newOperation);
      }
      return data;
    }
  );
};
