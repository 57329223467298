import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  GetFarmsQuery,
  GetFarmsQueryVariables,
  InsertFarmMutation,
  InsertFarmMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { GET_FARMS_QUERY } from "@farmevo/web/src/Pages/Fields/fields.graphql";

export const insert_farms_one: UpdateResolver = (result, args, cache) => {
  const variables = args.object as InsertFarmMutationVariables["farm"];
  const newFarm = result as InsertFarmMutation;

  cache.updateQuery<GetFarmsQuery, GetFarmsQueryVariables>(
    {
      query: GET_FARMS_QUERY,
      variables: {
        userId: variables.userId,
      },
    },
    (data) => {
      if (newFarm.insert_farms_one) {
        data?.farms.push(newFarm.insert_farms_one);
      }
      return data;
    }
  );
};
