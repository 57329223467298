import {
  Button,
  Text,
  useDisclosure,
  Input,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  HStack,
  FormControl,
  FormLabel,
  Select,
  VStack,
} from "@chakra-ui/react";
import { IconPlus } from "../../Components/Icons";
import { useEffect, useState } from "react";
import { useSession } from "../../hooks/useSession";
import InventoryForm from "./InventoryForm";
import {
  GetMyInventoryQuery,
  GetMyInventoryQueryVariables,
  GetMyProductsQuery,
  GetMyProductsQueryVariables,
  GetMyWarehousesQuery,
  GetMyWarehousesQueryVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { useQuery } from "urql";
import { GET_MY_INVENTORY } from "./inventory.graphql";
import { usePaginatedUrqlQuery } from "../../hooks/usePaginatedQuery";
import { GET_MY_PRODUCTS } from "../Products/products.graphql";
import { Link } from "react-router-dom";
import { GET_MY_WAREHOUSES } from "../Warehouses/warehouses.graphql";

const Inventory = () => {
  const {
    isOpen: isFormOpen,
    onOpen: onFormOpen,
    onClose: onFormClose,
  } = useDisclosure({
    onClose: () => {
      //refreshInventory();
    },
  });
  const { session } = useSession();
  const [productFilter, setProductFilter] = useState("");
  const [warehouseFilter, setWarehouseFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState<{
    query: string;
    debounced: boolean;
  }>({ query: "", debounced: true });

  //debouncing the search input
  useEffect(() => {
    if (searchFilter.debounced) {
      return;
    }
    const setDebounce = setTimeout(() => {
      setSearchFilter((prev) => {
        return { query: prev.query, debounced: true };
      });
    }, 300);

    return () => clearTimeout(setDebounce);
  }, [searchFilter]);

  const [selectedItem] = useState<GetMyInventoryQuery["inventory"][0] | null>(
    null
  );
  const { data, fetchMore, canFetchMore } = usePaginatedUrqlQuery<
    GetMyInventoryQuery,
    GetMyInventoryQueryVariables
  >(
    GET_MY_INVENTORY,
    {
      variables: {
        filter: {
          userId: { _eq: session?.id || -1 },
          ...(productFilter && { productId: { _eq: productFilter } }),
          ...(warehouseFilter && { warehouseId: { _eq: warehouseFilter } }),
          ...(searchFilter.query && {
            product: { name: { _ilike: `%${searchFilter.query}%` } },
          }),
        },
        limit: 10,
      },
      pause: !session?.id || !searchFilter.debounced,
    },
    {
      getLength(data) {
        return (
          data.inventory_aggregate.aggregate?.count || data.inventory.length
        );
      },
    }
  );

  const [{ data: productsData }] = useQuery<
    GetMyProductsQuery,
    GetMyProductsQueryVariables
  >({
    query: GET_MY_PRODUCTS,
    variables: { userId: session?.id || -1 },
    pause: !session?.id,
  });

  const [{ data: warehouseData }] = useQuery<
    GetMyWarehousesQuery,
    GetMyWarehousesQueryVariables
  >({
    query: GET_MY_WAREHOUSES,
    variables: { filter: { userId: { _eq: session?.id || -1 } } },
    pause: !session?.id,
  });

  return (
    <VStack p={10} align="unset" spacing={4}>
      <Text fontWeight="bold" fontSize="2xl">
        Inventory
      </Text>
      <HStack align="end" justifyContent="space-between">
        <HStack align="end">
          <FormControl>
            <FormLabel color="gray.500">Search</FormLabel>
            <Input
              type="search"
              value={searchFilter.query}
              onChange={(e) =>
                setSearchFilter({ query: e.target.value, debounced: false })
              }
              placeholder="Search by keyword"
              name="search"
              w="xs/2"
            />
          </FormControl>
          <FormControl>
            <FormLabel color="gray.500">Product</FormLabel>
            <Select
              value={productFilter}
              onChange={(e) => {
                setProductFilter(e.target.value);
              }}
            >
              <option value="">All</option>
              {productsData?.products.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel color="gray.500">Warehouse</FormLabel>
            <Select
              value={warehouseFilter}
              onChange={(e) => {
                setWarehouseFilter(e.target.value);
              }}
            >
              <option value="">All</option>
              {warehouseData?.warehouses.map((wh) => (
                <option key={wh.id} value={wh.id}>
                  {wh.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </HStack>
        <HStack>
          <Button
            as={Link}
            to="/warehouses"
            size="md"
            onClick={onFormOpen}
            leftIcon={<IconPlus />}
          >
            Add Warehouse
          </Button>
          <Button
            colorScheme="brand"
            size="md"
            onClick={onFormOpen}
            leftIcon={<IconPlus />}
          >
            Add Inventory
          </Button>
        </HStack>
      </HStack>
      <InventoryForm
        warehouses={warehouseData?.warehouses || []}
        products={productsData?.products || []}
        selected={selectedItem}
        isOpen={isFormOpen}
        onClose={onFormClose}
      />
      {data?.inventory.length ? (
        <TableContainer whiteSpace="normal">
          <Table mt="5">
            <Thead>
              <Tr>
                <Th>Product</Th>
                <Th>Amount</Th>
                <Th>Price</Th>
                <Th>Remaining</Th>
                <Th>Warehouse</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.inventory.map((inventory) => {
                return (
                  <Tr key={inventory.id}>
                    <Td>{inventory.product.name}</Td>
                    <Td>{inventory.amount}</Td>
                    <Td>{inventory.price}</Td>
                    <Td>{inventory.remaining}</Td>
                    <Td>{inventory.warehouse?.name || "none"}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <HStack ml="5" mt="5">
            <Text>
              Showing {data.inventory.length} of{" "}
              {data.inventory_aggregate.aggregate?.count} Products
            </Text>
            (
            {canFetchMore && (
              <Text
                onClick={fetchMore}
                fontWeight="bold"
                textColor="brand.500"
                _hover={{ cursor: "pointer" }}
              >
                Load More
              </Text>
            )}
            )
          </HStack>
        </TableContainer>
      ) : productFilter || warehouseFilter || searchFilter.query ? (
        <Text ml="5">
          <b>No matching results found.</b>
          <br /> Click on the "Add" button to create new Inventory.{" "}
        </Text>
      ) : (
        <Text ml="5">
          <b>You don't have any Inventory.</b>
          <br /> Click on the "Add" button to create new Inventory.{" "}
        </Text>
      )}
    </VStack>
  );
};

export default Inventory;
