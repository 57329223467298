export const SpinnerTheme = {
  sizes: {
    lg: {
      borderWidth: "3px",
    },
    xl: {
      borderWidth: "4px",
    },
  },
  defaultProps: {
    // speed: "0.9s",
    // emptyColor: "gray.200",
    // color: "brand.900",
  },
};
