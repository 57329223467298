import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  SaveMyVehicleMutation,
  GetMyVehiclesQuery,
  GetMyVehiclesQueryVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { GET_MY_VEHICLES } from "@farmevo/web/src/Pages/Vehicles/vehicles.graphql";

export const insert_vehicles_one: UpdateResolver = (result, args, cache) => {
  const newVehicle = (result as SaveMyVehicleMutation).insert_vehicles_one;

  const queryArgs = cache
    .inspectFields("query_root")
    .filter((field) => field.fieldName === newVehicle?.__typename)[0].arguments;

  const queryArgsFilter = queryArgs?.where as any;

  cache.updateQuery<GetMyVehiclesQuery, GetMyVehiclesQueryVariables>(
    {
      query: GET_MY_VEHICLES,
      variables: {
        filter: queryArgsFilter,
        limit: queryArgs?.limit as number,
        offset: queryArgs?.offset as number,
      },
    },
    (data) => {
      const vehicleAlreadyExists = !!data?.vehicles.find(
        (vehicle) => vehicle.id === newVehicle?.id
      );

      if (newVehicle && !vehicleAlreadyExists) {
        data?.vehicles.unshift(newVehicle);
        if (
          data?.vehicles_aggregate.aggregate?.count !== null &&
          data?.vehicles_aggregate.aggregate?.count !== undefined
        ) {
          data.vehicles_aggregate.aggregate.count += 1;
        }
      }
      return data;
    }
  );
};
