import React, { useMemo } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import LayoutOnboarding from "../../Components/LayoutOnboarding";
import ComboSelect from "../../Components/ComboSelect";
import ColorPickerField from "../../Components/ColorPickerField";
import { Currency, currencies } from "../../constants/currency";
import { Timezone, timezones } from "../../constants/timezone";
import { LatLng, MapWithAutoComplete } from "../../Components/GoogleMap";
import { CompleteForm } from "./onboard.graphql";
import {
  currenciesFilter,
  currencyToString,
  timezonesFilter,
  timezoneToString,
} from "../../utils/filters";
import { IconMapPin } from "../../Components/Icons";

type Props = {
  formData: Partial<CompleteForm> | null;
};

const Preferences = ({ formData }: Props) => {
  const geoLocation = useMemo(() => {
    if (!formData?.latLng)
      return {
        lat: 39.7546178,
        lng: -89.6362974,
      };
    const latLngSplit = formData.latLng.split(",");
    return {
      lat: Number(latLngSplit[0].trim()),
      lng: Number(latLngSplit[1].trim()),
    } as LatLng;
  }, [formData?.latLng]);
  return (
    <LayoutOnboarding
      title="Setup default business preferences"
      subtitle="You can change these settings later."
    >
      <Box flex={1} mb={10}>
        <Text fontSize="xl" fontWeight="semibold" mb={4}>
          Default settings
        </Text>
        <VStack spacing={5}>
          <ComboSelect<Currency>
            items={currencies}
            itemsFilter={currenciesFilter}
            itemToString={currencyToString}
            formControlProps={{ isRequired: true }}
            label="Currency"
            inputProps={{
              id: "currency",
              name: "currency",
              placeholder: "Select currency",
              defaultValue: formData?.currency,
            }}
            renderItem={(item) => (
              <span>
                {item?.name} ({item?.symbol})
              </span>
            )}
          />
          <ComboSelect<Timezone>
            items={timezones}
            itemsFilter={timezonesFilter}
            itemToString={timezoneToString}
            formControlProps={{ isRequired: true }}
            defaultSelectedItem={timezones.find(
              (t) => t.tzCode === formData?.timezone
            )}
            label="Time zone"
            inputProps={{
              id: "timezone",
              name: "timezone",
              placeholder: "Select timezone",
            }}
            renderItem={(item) => <span>{item?.label}</span>}
          />
          <FormControl isRequired>
            <FormLabel>Field measurement unit</FormLabel>
            <Select
              id="unit"
              name="unit"
              defaultValue={formData?.unit}
              placeholder="Select unit"
            >
              <option value="hectare">Hectare</option>
              <option value="acre">Acre</option>
            </Select>
          </FormControl>
          <ColorPickerField
            defaultValue={formData?.color}
            label="Default field color"
          />
        </VStack>
      </Box>

      <Box flex={1}>
        <Text fontSize="xl" fontWeight="semibold" mb={2}>
          Default location
        </Text>
        <Text fontSize="sm" mb={2}>
          Drag the red marker{" "}
          <IconMapPin size={16} style={{ display: "inline" }} /> to adjust your
          location
        </Text>
        <Box>
          <MapWithAutoComplete defaultLocation={geoLocation} />
        </Box>
      </Box>
    </LayoutOnboarding>
  );
};

export default Preferences;
