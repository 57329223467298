import { useState, ChangeEvent, CSSProperties } from "react";
import { GoogleMap, MarkerF as Marker } from "@react-google-maps/api";
import { Box, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxPopoverProps,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { IconSearch } from "./Icons";

export type LatLng = {
  lat: number;
  lng: number;
};

type Props = {
  defaultLocation?: LatLng;
  suggestionsContainerProps?: {
    style?: CSSProperties;
  } & ComboboxPopoverProps;
  onChangeLocation?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void | Promise<any>;
};

const containerStyle = {
  width: "100%",
  height: "400px",
};

export function MapWithAutoComplete({
  defaultLocation,
  suggestionsContainerProps,
  onChangeLocation,
}: Props) {
  const [selected, setSelected] = useState<LatLng | undefined>(
    defaultLocation || {
      lat: 43.45,
      lng: -80.49,
    }
  );

  return (
    <>
      <Box position="absolute" zIndex={99}>
        <PlacesAutocomplete
          selected={selected}
          onSelectLocation={(pos) => {
            setSelected(pos);
            onChangeLocation?.("geoLocation", `(${pos.lat},${pos.lng})`);
          }}
          popoverProps={suggestionsContainerProps}
        />
      </Box>

      <GoogleMap
        zoom={10}
        center={selected}
        options={{
          streetViewControl: false,
          mapTypeControlOptions: {
            position: google.maps.ControlPosition.BOTTOM_LEFT,
          },
        }}
        mapContainerStyle={containerStyle}
      >
        {selected && (
          <Marker
            position={selected}
            onDragEnd={(e) => {
              const lat = e.latLng?.lat();
              const lng = e.latLng?.lng();

              if (lat && lng) {
                setSelected({ lat, lng });
                onChangeLocation?.("geoLocation", `(${lat},${lng})`);
              }
            }}
            draggable
          />
        )}
      </GoogleMap>
    </>
  );
}

type PlacesAutocompleteProps = {
  selected?: LatLng;
  popoverProps?: { style?: CSSProperties } & ComboboxPopoverProps;
  onSelectLocation: (pos: LatLng) => void;
};

const PlacesAutocomplete = ({
  selected,
  popoverProps,
  onSelectLocation,
}: PlacesAutocompleteProps) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address: string) => {
    if (!address) return;
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = getLatLng(results[0]);
    onSelectLocation({ lat, lng });
  };

  return (
    <Combobox onSelect={handleSelect}>
      <InputGroup m={4}>
        <InputLeftElement>
          <IconSearch color="#4a5568" size={18} />
        </InputLeftElement>
        <ComboboxInput
          as={Input}
          bg="white"
          pl={9}
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setValue(e.target.value)
          }
          disabled={!ready}
          className="combobox-input"
          placeholder="Search an address"
        />
        <Input
          type="hidden"
          id="latLng"
          name="latLng"
          value={`${selected?.lat},${selected?.lng}`}
          readOnly
        />
      </InputGroup>
      <ComboboxPopover {...popoverProps}>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};
