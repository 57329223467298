import { useEffect, useMemo, useState } from "react";
import { gql, useClient, useMutation, useQuery } from "urql";
import {
  AddJobMutation,
  AddJobMutationVariables,
  Chargeable_Units_Enum,
  GetFieldsCompactQuery,
  GetFieldsCompactQueryVariables,
  GetImplementsQuery,
  GetImplementsQueryVariables,
  GetProductsQuery,
  GetProductsQueryVariables,
  GetOperatorsQuery,
  GetOperatorsQueryVariables,
  GetVehiclesQuery,
  GetVehiclesQueryVariables,
  Job_Status_Enum,
  Roles_Enum,
  GetOperationProductsQuery,
  GetOperationProductsQueryVariables,
  GetOperationsQuery,
  GetOperationsQueryVariables,
  GetOperationExtrasQuery,
  GetOperationExtrasQueryVariables,
  GetJobDetailsQuery,
  GetJobDetailsQueryVariables,
  Job_Operators_Constraint,
  Job_Operators_Update_Column,
  Job_Operator_Vehicles_Constraint,
  Job_Operator_Implements_Constraint,
  Job_Extras_Constraint,
  Job_Extras_Update_Column,
  Job_Fields_Constraint,
  Job_Locations_Constraint,
  Job_Locations_Update_Column,
  Job_Products_Constraint,
  Job_Products_Update_Column,
  UpdateJobMutationVariables,
  GetFarmsQuery,
  GetFarmsQueryVariables,
  UpdateJobAsOperatorMutation,
  UpdateJobAsOperatorMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { useSession } from "@farmevo/web/src/hooks/useSession";
import {
  ADD_JOB_MUTATION,
  GET_FIELDS_QUERY_COMPACT,
  GET_IMPLEMENTS_QUERY,
  GET_PRODUCTS_QUERY,
  GET_OPERATORS_QUERY,
  GET_VEHICLES_QUERY,
  GET_OPERATIONS_QUERY,
  GET_OPERATION_PRODUCTS_QUERY,
  GET_OPERATION_EXTRAS_QUERY,
  generateUpdateJobMutation,
  DeletedOperatorVehicle,
  DeletedOperatorImplement,
  UPDATE_JOB_AS_OPERATOR_MUTATION,
} from "./addjob.graphql";
import { useNavigate, useParams } from "react-router-dom";
import { GET_JOB_DETAILS_QUERY } from "../JobDetails/job-details.graphql";
import { GET_FARMS_QUERY } from "../../Fields/fields.graphql";

interface UserEntity {
  id: number;
  firstName: string;
  lastName?: string | null;
  currency?: string | null;
}

interface SelectedContractor extends UserEntity {}
interface SelectedOperator extends UserEntity {
  teamLead?: boolean;
  vehicles: GetVehiclesQuery["vehicles"];
  implements: GetImplementsQuery["implements"];
}

type SelectedProduct = Partial<GetProductsQuery["products"][0]> & {
  value?: string;
  locked?: boolean;
};

type Extra = {
  id: number;
  name: string;
  unit?: Chargeable_Units_Enum | null;
  value?: string;
};

type Location = {
  id?: number;
  temporaryId?: string;
  notes: string;
  geoLocation?: string;
};

export const useJobForm = () => {
  const { session } = useSession();
  const { jobId } = useParams<{ jobId: string }>();
  const navigate = useNavigate();
  const [submitAttempts, setSubmitAttempts] = useState(0);
  const [initialized, setInitialized] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({
    selectedContractor: "",
    selectedOperation: "",
    from: "",
    to: "",
    instructions: "",
    selectedOperators: "",
    selectedFields: "",
    extras: "",
    selectedProducts: "",
    locations: "",
  });
  const [selectedContractor, setSelectedContractor] =
    useState<SelectedContractor | null>(null);
  const [selectedFarm, setSelectedFarm] = useState<
    GetFarmsQuery["farms"][0] | null
  >(null);
  const [selectedOperation, setSelectedOperation] = useState<
    GetOperationsQuery["operations"][0] | null
  >(null);
  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string | null>(null);
  const [instructions, setInstructions] = useState<string | null>(null);
  const [selectedOperators, setSelectedOperators] = useState<
    SelectedOperator[]
  >([]);
  const [selectedFields, setSelectedFields] = useState<
    GetFieldsCompactQuery["field_season_shot"]
  >([]);
  const [extras, setExtras] = useState<Extra[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(
    []
  );
  const [locations, setLocations] = useState<Location[]>([]);

  const isOperator = !!session?.user?.isOperator;

  const [{ data: jobData, fetching: loadingJob }] = useQuery<
    GetJobDetailsQuery,
    GetJobDetailsQueryVariables
  >({
    query: GET_JOB_DETAILS_QUERY,
    variables: { jobId, isOperator },
    pause: !jobId,
  });

  const job = jobData?.jobs_by_pk;

  const [{ data: operatorsData, fetching: loadingOperators }] = useQuery<
    GetOperatorsQuery,
    GetOperatorsQueryVariables
  >({
    query: GET_OPERATORS_QUERY,
    variables: {
      userId: session?.id || -1,
    },
    pause: !session?.id || isOperator,
  });

  const [{ data: vehiclesData, fetching: loadingVehicles }] = useQuery<
    GetVehiclesQuery,
    GetVehiclesQueryVariables
  >({
    query: GET_VEHICLES_QUERY,
    variables: {
      userId: session?.id || -1,
    },
    pause:
      !session?.id ||
      selectedContractor?.id !== session.id ||
      selectedOperators.length === 0 ||
      isOperator,
  });

  const [{ data: implementsData, fetching: loadingImplements }] = useQuery<
    GetImplementsQuery,
    GetImplementsQueryVariables
  >({
    query: GET_IMPLEMENTS_QUERY,
    variables: {
      userId: session?.id || -1,
    },
    pause:
      !session?.id ||
      selectedContractor?.id !== session.id ||
      selectedOperators.length === 0 ||
      isOperator,
  });

  const [{ data: farmsData, fetching: loadingFarms }] = useQuery<
    GetFarmsQuery,
    GetFarmsQueryVariables
  >({
    query: GET_FARMS_QUERY,
    variables: { userId: session?.id || -1 },
    pause: !session?.id || isOperator,
  });

  const [{ data: fieldsData, fetching: loadingFields }] = useQuery<
    GetFieldsCompactQuery,
    GetFieldsCompactQueryVariables
  >({
    query: GET_FIELDS_QUERY_COMPACT,
    variables: {
      filter: {
        farm: {
          ...(selectedFarm && { id: { _eq: selectedFarm.id } }),
          userId: { _eq: session?.id || -1 },
        },
      },
    },
    pause: !session?.id || isOperator,
  });

  const [{ data: operationsData, fetching: loadingOperations }] = useQuery<
    GetOperationsQuery,
    GetOperationsQueryVariables
  >({
    query: GET_OPERATIONS_QUERY,
    variables: {
      userId: selectedContractor?.id || job?.assignedUser?.id || -1,
    },
    pause: !session?.id || !selectedContractor?.id || isOperator,
  });

  const [{ data: operationExtrasData }] = useQuery<
    GetOperationExtrasQuery,
    GetOperationExtrasQueryVariables
  >({
    query: GET_OPERATION_EXTRAS_QUERY,
    variables: {
      operationId: selectedOperation?.id || -1,
    },
    pause: !selectedOperation?.id || isOperator,
  });

  const [{ data: operationProductsData }] = useQuery<
    GetOperationProductsQuery,
    GetOperationProductsQueryVariables
  >({
    query: GET_OPERATION_PRODUCTS_QUERY,
    variables: {
      operationId: selectedOperation?.id || -1,
    },
    pause:
      selectedContractor?.id !== session?.id ||
      !selectedOperation?.id ||
      isOperator,
  });

  const [{ data: productsData, fetching: loadingProducts }] = useQuery<
    GetProductsQuery,
    GetProductsQueryVariables
  >({
    query: GET_PRODUCTS_QUERY,
    variables: { userId: session?.id || -1 },
    pause: !session?.id || selectedContractor?.id !== session.id || isOperator,
  });

  const [, addJob] = useMutation<AddJobMutation, AddJobMutationVariables>(
    ADD_JOB_MUTATION
  );

  const [, updateJobAsOperator] = useMutation<
    UpdateJobAsOperatorMutation,
    UpdateJobAsOperatorMutationVariables
  >(UPDATE_JOB_AS_OPERATOR_MUTATION);

  const urqlClient = useClient();

  const self: UserEntity | undefined = useMemo(
    () =>
      session?.user && {
        id: session.id,
        firstName: session.user.firstName,
        lastName: session.user.lastName,
        currency: session.user.prefs?.currency,
      },
    [session]
  );

  const contractors = useMemo(() => {
    if (!self) {
      return [];
    }
    if (!operatorsData?.team_members) {
      return [{ ...self }];
    }
    return [self].concat(
      operatorsData?.team_members
        .filter(
          (tm) =>
            !!tm.teamMember.roles?.find((r) => r.role === Roles_Enum.Contractor)
        )
        .map((tm) => ({
          id: tm.teamMember.id,
          firstName: tm.teamMember.firstName,
          lastName: tm.teamMember.lastName || "",
          currency: tm.teamMember.prefs?.currency,
        }))
    );
  }, [operatorsData, self]);

  const operators = useMemo(() => {
    if (!self) {
      return [];
    }
    if (!operatorsData?.team_members) {
      return [self];
    }
    return [self].concat(
      operatorsData?.team_members
        .filter(
          (tm) =>
            !!tm.teamMember.roles?.find(
              (r) =>
                r.role === Roles_Enum.Operator || r.role === Roles_Enum.Manager
            )
        )
        .map((tm) => ({
          id: tm.teamMember.id,
          firstName: tm.teamMember.firstName,
          lastName: tm.teamMember.lastName || "",
        }))
    );
  }, [operatorsData, self]);

  useEffect(() => {
    if (!job) return;

    const _selectedContractor = contractors.find(
      (c) => c.id === job.assignedUser?.id
    );

    setSelectedContractor(_selectedContractor || null);
  }, [job, contractors]);

  useEffect(() => {
    if (!job || (!isOperator && !operationsData) || initialized) return;

    const _selectedOperation = operationsData?.operations.find(
      (op) => op.name === job.operationName
    );
    setSelectedOperation(_selectedOperation || null);
    setFrom(
      job.from
        ?.toLocaleString("sv-SE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
        .replace(" ", "T")
    );
    setTo(
      job.to
        ?.toLocaleString("sv-SE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
        .replace(" ", "T")
    );
    setInstructions(job.instructions || "");
    setSelectedOperators(
      job.operators.map((op) => ({
        id: op.user.id,
        firstName: op.user.firstName,
        teamLead: !!op.teamLead,
        vehicles: op.vehicles.map((ve) => ve.vehicle),
        implements: op.implements.map((im) => im.implement),
      }))
    );
    setSelectedFields(job.fields.map((field) => field.seasonShot));
    setExtras(
      job.extras.map((ex, i) => ({
        id: i + 1,
        name: ex.name,
        unit: ex.unit,
        value: ex.value || "",
      }))
    );
    setSelectedProducts(
      job.products.map((jp) => ({
        id: jp.productId,
        name: jp.product.name,
        locked: jp.locked,
        chargeableUnit: jp.product.chargeableUnit,
        value: jp.value || "",
      }))
    );
    setLocations(job.locations.map((jl) => ({ ...jl, notes: jl.notes || "" })));
    setInitialized(true);
  }, [
    job,
    contractors,
    isOperator,
    selectedOperation,
    operationsData,
    initialized,
  ]);

  useEffect(() => {
    if (!operationProductsData || !!jobId) return;

    setSelectedProducts(
      operationProductsData.operation_products.map((op) => op.product)
    );
  }, [operationProductsData, jobId]);

  useEffect(() => {
    if (!operationExtrasData || !!jobId) return;

    setExtras(
      operationExtrasData.operation_extras.map((extra, i) => ({
        ...extra,
        value: extra.value || "",
        name: extra.name || `Extra ${i + 1}`,
      }))
    );
  }, [operationExtrasData, jobId]);

  useEffect(() => {
    if (submitAttempts < 1 || !selectedContractor) return;

    setErrors((prev) => ({ ...prev, selectedContractor: "" }));
  }, [selectedContractor, submitAttempts]);

  useEffect(() => {
    if (submitAttempts < 1 || !selectedOperation) return;

    setErrors((prev) => ({ ...prev, selectedOperation: "" }));
  }, [selectedOperation, submitAttempts]);

  const submit = async () => {
    setSubmitAttempts((prev) => prev + 1);

    const newErrors = { ...errors };
    if (!selectedContractor) {
      newErrors.selectedContractor = "Please select a contractor";
    }

    if (!selectedOperation) {
      newErrors.selectedOperation = "Please select an operation";
    }

    setErrors(newErrors);

    if (
      !session?.id ||
      Object.values(newErrors).some((isErrored) => !!isErrored)
    ) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    setSubmitting(true);

    if (jobId && job) {
      let removedOperatorVehicles: DeletedOperatorVehicle[] = [];
      let removedOperatorImplements: DeletedOperatorImplement[] = [];

      selectedOperators.forEach((selectedOperator) => {
        const operator = job.operators.find(
          (op) => op.user.id === selectedOperator.id
        );

        if (!operator) return;

        removedOperatorVehicles = removedOperatorVehicles.concat(
          operator.vehicles
            .filter(
              (ov) =>
                !selectedOperator.vehicles.find(
                  (sov) => sov.id === ov.vehicle.id
                )
            )
            .map((ov) => ({
              userId: operator.user.id,
              vehicleId: ov.vehicle.id,
            }))
        );

        removedOperatorImplements = removedOperatorImplements.concat(
          operator.implements
            .filter(
              (oi) =>
                !selectedOperator.implements.find(
                  (soi) => soi.id === oi.implement.id
                )
            )
            .map((oi) => ({
              userId: operator.user.id,
              implementId: oi.implement.id,
            }))
        );
      });

      await urqlClient.executeMutation<any, UpdateJobMutationVariables>({
        query: gql(
          generateUpdateJobMutation(
            removedOperatorVehicles,
            removedOperatorImplements
          )
        ),
        key: Number(jobId),
        variables: {
          jobId,
          removedOperators: job.operators
            .filter((op) => !selectedOperators.find((o) => op.user.id === o.id))
            .map((op) => op.user.id),
          removedFields: job.fields
            .filter(
              (jf) =>
                !selectedFields.find((field) => jf.seasonShot.id === field.id)
            )
            .map((field) => field.seasonShot.id),
          removedExtras: job.extras
            .filter((jex) => !extras.find((ex) => ex.name === jex.name))
            .map((ex) => ex.name),
          removedProducts: job.products
            .filter(
              (jp) => !selectedProducts.find((p) => jp.productId === p.id)
            )
            .map((jp) => jp.productId),
          removedLocations: job.locations
            .filter((loc) => !locations.find((l) => loc.id === l.id))
            .map((loc) => loc.id),
          updatedJob: {
            id: jobId,
            assignedUserId: selectedContractor?.id,
            userId: job.userId,
            from: from && new Date(from).toISOString(),
            to: to && new Date(to).toISOString(),
            operationId: selectedOperation?.id,
            operationName: selectedOperation?.name,
            instructions: instructions,
            status: job.status,
            operators: {
              on_conflict: {
                constraint: Job_Operators_Constraint.JobOperatorsPkey,
                update_columns: [Job_Operators_Update_Column.TeamLead],
              },
              data: selectedOperators.map((op) => ({
                userId: op.id,
                teamLead: op.teamLead,
                vehicles: {
                  on_conflict: {
                    constraint:
                      Job_Operator_Vehicles_Constraint.JobOperatorVehiclesPkey,
                    update_columns: [],
                  },
                  data: op.vehicles.map((v) => ({ vehicleId: v.id })),
                },
                implements: {
                  on_conflict: {
                    constraint:
                      Job_Operator_Implements_Constraint.JobOperatorImplementsPkey,
                    update_columns: [],
                  },
                  data: op.implements.map((im) => ({ implementId: im.id })),
                },
              })),
            },
            extras: {
              on_conflict: {
                constraint: Job_Extras_Constraint.JobExtrasPkey,
                update_columns: [
                  Job_Extras_Update_Column.Unit,
                  Job_Extras_Update_Column.Value,
                ],
              },
              data: extras.map((ex) => ({
                name: ex.name,
                unit: ex.unit,
                value: ex.value,
              })),
            },
            fields: {
              on_conflict: {
                constraint: Job_Fields_Constraint.JobFieldsPkey,
                update_columns: [],
              },
              data: selectedFields.map((field) => ({ seasonShotId: field.id })),
            },
            locations: {
              on_conflict: {
                constraint: Job_Locations_Constraint.JobLocationsPkey,
                update_columns: [
                  Job_Locations_Update_Column.Notes,
                  Job_Locations_Update_Column.GeoLocation,
                ],
              },
              data: locations.map((loc) => ({
                ...(loc.id && { id: loc.id }),
                ...(loc.geoLocation && { geoLocation: loc.geoLocation }),
                notes: loc.notes,
              })),
            },
            products: {
              on_conflict: {
                constraint: Job_Products_Constraint.JobProductsPkey,
                update_columns: [
                  Job_Products_Update_Column.Value,
                  Job_Products_Update_Column.Locked,
                ],
              },
              data: selectedProducts.map((op) => ({
                productId: op.id,
                value: op.value,
                locked: op.locked,
              })),
            },
          },
        },
      });
      navigate(`/jobs/${jobId}`);
    } else {
      await addJob({
        job: {
          userId: session.id,
          assignedUserId: selectedContractor?.id,
          from: from && new Date(from).toISOString(),
          to: to && new Date(to).toISOString(),
          operationId: selectedOperation?.id,
          operationName: selectedOperation?.name,
          instructions: instructions,
          status: Job_Status_Enum.Ready,
          operators: {
            data: selectedOperators.map((op) => ({
              userId: op.id,
              teamLead: op.teamLead,
              vehicles: { data: op.vehicles.map((v) => ({ vehicleId: v.id })) },
              implements: {
                data: op.implements.map((im) => ({ implementId: im.id })),
              },
            })),
          },
          extras: {
            data: extras.map((ex) => ({
              name: ex.name,
              unit: ex.unit,
              value: ex.value,
            })),
          },
          fields: {
            data: selectedFields.map((field) => ({ seasonShotId: field.id })),
          },
          locations: {
            data: locations.map((loc) => ({
              geoLocation: loc.geoLocation,
              notes: loc.notes,
            })),
          },
          products: {
            data: selectedProducts.map((op) => ({
              productId: op.id,
              value: op.value,
              locked: op.locked,
            })),
          },
        },
      });
      navigate("/jobs");
    }

    setSubmitting(false);
  };

  const submitAsOperator = async () => {
    if (!jobId || !job) return;

    setSubmitAttempts((prev) => prev + 1);

    setSubmitting(true);

    await updateJobAsOperator({
      extraUpdates: extras.map((extra) => ({
        _set: {
          value: extra.value,
        },
        where: {
          jobId: { _eq: jobId },
          name: { _eq: extra.name },
        },
      })),
      productUpdates: selectedProducts.map((product) => ({
        _set: { value: product.value },
        where: { jobId: { _eq: jobId }, productId: { _eq: product.id } },
      })),
    });

    setSubmitting(false);
    navigate(`/jobs/${jobId}`);
  };

  return {
    formValues: {
      selectedFarm,
      selectedContractor,
      selectedOperation,
      from,
      to,
      instructions,
      selectedOperators,
      selectedFields,
      extras,
      selectedProducts,
      locations,
    },
    errors,
    operators: operators.filter(
      (op) => !selectedOperators.find((sop) => sop.id === op.id)
    ),
    contractors,
    operations: operationsData?.operations,
    vehicles: vehiclesData?.vehicles,
    implements: implementsData?.implements,
    farms: farmsData?.farms,
    fields: fieldsData?.field_season_shot,
    products: productsData?.products,
    job,
    loadingOperations,
    loadingOperators,
    loadingVehicles,
    loadingImplements,
    loadingFarms,
    loadingFields,
    loadingProducts,
    submitting,
    loadingJob,
    setSelectedFarm,
    setSelectedContractor,
    setSelectedOperation,
    setFrom,
    setTo,
    setInstructions,
    setSelectedOperators,
    setSelectedFields,
    setExtras,
    setSelectedProducts,
    setLocations,
    submit: isOperator ? submitAsOperator : submit,
  };
};
