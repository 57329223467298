import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import PreferencesForm from "./PreferencesForm";
import ProfileForm from "./ProfileForm";
import RatesForm from "./RatesForm";

const Account = () => (
  <Box>
    <Text m={3} fontSize="2xl" fontWeight="bold">
      Account
    </Text>
    <Tabs variant="enclosed">
      <TabList>
        <Tab
          _selected={{
            fontWeight: "bold",
            textColor: "brand.800",
            border: "2px",
          }}
        >
          Profile
        </Tab>
        <Tab
          _selected={{
            fontWeight: "bold",
            textColor: "brand.800",
            border: "2px",
          }}
        >
          Preferences
        </Tab>
        <Tab
          _selected={{
            fontWeight: "bold",
            textColor: "brand.800",
            border: "2px",
          }}
        >
          Rates
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <ProfileForm />
        </TabPanel>
        <TabPanel>
          <PreferencesForm />
        </TabPanel>
        <TabPanel>
          <RatesForm />
        </TabPanel>
      </TabPanels>
    </Tabs>
  </Box>
);

export default Account;
