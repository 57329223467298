import { gql } from "urql";

export const GET_REPORT_BY_TAG_QUERY = gql`
  query GetReportByTag($tag: uuid!) {
    reports(where: { tag: { _eq: $tag } }) {
      id
      link
      name
      jsonSummary
      createdAt
      seasonShot {
        name
        cropName
        createdAt
        polygon {
          size
        }
        farm {
          name
        }
      }
    }
  }
`;
