import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  GetMyProductsPaginatedQuery,
  GetMyProductsPaginatedQueryVariables,
  SaveMyProductMutation,
  SaveMyProductMutationVariables,
  UpdateMyProductMutation,
} from "@farmevo/common/dist/graphql/graphql";
import { GET_MY_PRODUCTS_PAGINATED } from "@farmevo/web/src/Pages/Products/products.graphql";

export const insert_products_one: UpdateResolver = (result, args, cache) => {
  const variables =
    args.object as SaveMyProductMutationVariables["saveProductPayload"];
  const mutationResult = result as SaveMyProductMutation &
    UpdateMyProductMutation;

  const queryArgs = cache
    .inspectFields("query_root")
    .filter(
      (field) =>
        field.fieldName === mutationResult.insert_products_one?.__typename
    )[0].arguments;

  const queryArgsFilter = queryArgs?.where as any;

  cache.updateQuery<
    GetMyProductsPaginatedQuery,
    GetMyProductsPaginatedQueryVariables
  >(
    {
      query: GET_MY_PRODUCTS_PAGINATED,
      variables: {
        filter: {
          userId: { _eq: variables.userId || -1 },
          status: { _eq: queryArgsFilter?.status?._eq },
          ...(queryArgsFilter?.name?._similar && {
            name: { _similar: queryArgsFilter?.name?._similar || "" },
          }),
        },
        limit: queryArgs?.limit as number,
        offset: queryArgs?.offset as number,
      },
    },
    (data) => {
      const productAlreadyExists = data?.products.find(
        (product) => mutationResult.insert_products_one?.id === product.id
      );
      if (
        (mutationResult.update_products_by_pk ||
          mutationResult.insert_products_one) &&
        !productAlreadyExists
      ) {
        if (mutationResult.update_products_by_pk)
          data?.products.push(mutationResult.update_products_by_pk);
        if (mutationResult.insert_products_one)
          data?.products.unshift(mutationResult.insert_products_one);
      }
      return data;
    }
  );

  cache
    .inspectFields("query_root")
    .filter((field) => field.fieldName === "products")
    .forEach((productQuery) => {
      const filter = productQuery.arguments
        ?.where as GetMyProductsPaginatedQueryVariables["filter"];
      cache.updateQuery<
        GetMyProductsPaginatedQuery,
        GetMyProductsPaginatedQueryVariables
      >(
        {
          query: GET_MY_PRODUCTS_PAGINATED,
          variables: {
            filter,
            limit: productQuery.arguments?.limit as number,
            offset: productQuery.arguments?.offset as number,
          },
        },
        (data) => {
          const productAlreadyExists = data?.products.find(
            (product) => mutationResult.insert_products_one?.id === product.id
          );
          if (
            (mutationResult.update_products_by_pk ||
              mutationResult.insert_products_one) &&
            !productAlreadyExists
          ) {
            if (mutationResult.update_products_by_pk)
              data?.products.push(mutationResult.update_products_by_pk);
            if (mutationResult.insert_products_one)
              data?.products.push(mutationResult.insert_products_one);
          }
          return data;
        }
      );
    });
};

export const update_products_by_pk: UpdateResolver = (result, args, cache) => {
  //update cache for the paginated query when the status is updated.
};
