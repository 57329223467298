import React from "react";
import { Box, BoxProps, HStack, Text, VStack } from "@chakra-ui/react";

type Props = {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
} & BoxProps;

const LayoutOnboarding = ({ title, subtitle, children, ...props }: Props) => {
  return (
    <Box py="12" px="28" {...props}>
      <VStack>
        <Text fontWeight="bold" fontSize="3xl">
          {title}
        </Text>
        <Text>{subtitle}</Text>
      </VStack>

      <HStack
        alignItems="initial"
        mt="12"
        spacing={{ base: 0, md: 5 }}
        maxW="5xl"
        mx="auto"
        flexDir={{ base: "column", md: "row" }}
      >
        {children}
      </HStack>
    </Box>
  );
};

export default LayoutOnboarding;
