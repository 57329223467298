import { createContext, useContext } from "react";

export const GoogleApiTokenContext = createContext<{
  token: google.accounts.oauth2.TokenResponse | null;
  setToken: (token: google.accounts.oauth2.TokenResponse | null) => void;
}>({
  token: null,
  setToken: () => {},
});

export const useGoogleApiToken = () => {
  return useContext(GoogleApiTokenContext);
};
