import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, Button, Flex, HStack, Text } from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { parseFormData } from "parse-nested-form-data";
import { useMutation } from "urql";
import Business from "./Business";
import Preferences from "./Preferences";
import Invoicing from "./Invoicing";
import { useSession } from "../../hooks/useSession";
import VerifyEmail from "./VerifyEmail";
import { CompleteForm, SAVE_USER_PROFILE_MUTATION } from "./onboard.graphql";
import {
  SaveUserProfileMutation,
  SaveUserProfileMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { IconCheck } from "../../Components/Icons";

const renderStep = (step: number, formData: Partial<CompleteForm> | null) => {
  switch (step) {
    case 0:
      return <VerifyEmail />;
    case 1:
      return <Business formData={formData} />;
    case 2:
      return <Preferences formData={formData} />;
    case 3:
      return <Invoicing formData={formData} />;
  }
};

const Onboarding = () => {
  const [error, setError] = useState("");
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [completeForm, setCompleteForm] =
    useState<Partial<CompleteForm> | null>(() => ({
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }));
  const [{ fetching: savingUserProfile }, saveUserProfile] = useMutation<
    SaveUserProfileMutation,
    SaveUserProfileMutationVariables
  >(SAVE_USER_PROFILE_MUTATION);
  const { session, logout } = useSession();
  const { activeStep, setStep } = useSteps({
    initialStep: 0,
  });
  const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!session) return;

    const formData = parseFormData(
      new FormData(e.target)
    ) as Partial<CompleteForm>;
    const newFormData = { ...completeForm, ...formData };
    setCompleteForm(newFormData);

    setError("");

    const { error } = await saveUserProfile({
      userProfile: {
        userId: session.id,
        companyName: newFormData.companyName,
        phone: newFormData.phone,
        address1: newFormData.address1,
        address2: newFormData.address2,
        city: newFormData.city,
        state: newFormData.state,
        country: newFormData.country,
        postalCode: newFormData.postal,
        taxNumber: newFormData.salesTaxNumber,
      },
      userPrefs: {
        userId: session.id,
        currency: newFormData.currency,
        fieldColor: newFormData.color,
        fieldUnit: newFormData.unit,
        geoLocation: newFormData.latLng,
        invoicePayInfo: newFormData.paymentInfo,
        invoiceTerms: Number(newFormData.invoiceDays || 15),
        timezone: newFormData.timezone,
      },
      rates:
        newFormData.rates?.map((rt) => ({ ...rt, userId: session.id })) || [],
    });

    if (error) {
      setError("An error occurred");
      return;
    }
    if (activeStep < 3) {
      setStep(activeStep + 1);
    } else {
      setIsRedirecting(true);
      // Session state sometimes doesn't update in time
      // Do a hard reload until we set up/fix cache
      window.location.assign("/");
    }
  };

  const onClickStep = (step: number) => {
    if (step === 0 && !!session?.user?.isEmailVerified) {
      return;
    }
    setStep(step);
  };

  useEffect(() => {
    if (activeStep === 0 && !!session?.user?.isEmailVerified) {
      setStep(1);
    }
  }, [session, activeStep, setStep]);

  return (
    <>
      <Box
        borderBottom="1px solid"
        borderColor="gray.100"
        position="sticky"
        top={0}
        py={4}
      >
        <Box maxW="5xl" mx="auto" px={6}>
          <Steps
            colorScheme="orange"
            activeStep={activeStep}
            onClickStep={onClickStep}
          >
            <Step label="Verify" checkIcon={IconCheck} />
            <Step label="Business" checkIcon={IconCheck} />
            <Step label="Preferences" checkIcon={IconCheck} />
            <Step label="Taxes" checkIcon={IconCheck} />
          </Steps>
        </Box>
      </Box>
      <form onSubmit={onSubmit} autoComplete="off">
        {renderStep(activeStep, completeForm)}

        {activeStep > 0 && (
          <Box
            borderTop="1px solid"
            borderColor="gray.100"
            position="sticky"
            bottom={0}
            py={6}
            px={28}
            bg="white"
          >
            <Flex
              maxW="5xl"
              mx="auto"
              justify="space-between"
              align="center"
              w="full"
            >
              <Button onClick={logout} size="md">
                Logout
              </Button>
              <HStack>
                {error && (
                  <Text fontSize="sm" color="red.500">
                    {error}
                  </Text>
                )}
                <Button
                  type="submit"
                  isDisabled={savingUserProfile || isRedirecting}
                  isLoading={savingUserProfile || isRedirecting}
                  size="lg"
                >
                  Submit
                </Button>
              </HStack>
            </Flex>
          </Box>
        )}
      </form>
    </>
  );
};

export default Onboarding;
