import { useEffect, useRef, useState } from "react";
import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";
import $script from "scriptjs";
import { useMutation } from "urql";
import Button from "../../Components/Button";
import GoogleDriveButton from "./GoogleDriveButton";
import { IconUploadCloud } from "../../Components/Icons";
import { FieldShot, SAVE_SNAPSHOT_REPORT_MUTATION } from "./fields.graphql";
import {
  SaveSnapshotReportMutation,
  SaveSnapshotReportMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { useGoogleApiToken } from "../../hooks/useGoogleApiToken";

type Props = {
  shot?: FieldShot | null;
  isOpen: boolean;
  onClose: () => void;
};

enum Errors {
  MISSING_REQUIRED_SCOPES = "MISSING_REQUIRED_SCOPES",
  CANNOT_SAVE_REPORT = "CANNOT_SAVE_REPORT",
  CANNOT_SAVE_FOLDER = "CANNOT_SAVE_FOLDER",
  EXPIRED_TOKEN = "EXPIRED_TOKEN",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

enum UploadReportStage {
  UPLOAD_METADATA_ON_DB,
  SETUP_RESOURCES_ON_GDRIVE,
  UPLOAD_METADATA_ON_GDRIVE,
  UPLOAD_FILE_ON_GDRIVE,
}

const AUTH_SCOPE_DRIVE = "https://www.googleapis.com/auth/drive.file";
const AUTH_SCOPE_EMAIL = "https://www.googleapis.com/auth/userinfo.email";

const UploadReportModal = ({ shot, isOpen, onClose }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errorType, setErrorType] = useState<Errors | null>(null);
  const [uploadingReport, setUploadingReport] = useState(false);
  const [uploadReportProgress, setUploadReportProgress] = useState(0);
  const [uploadReportStage, setUploadReportStage] =
    useState<UploadReportStage | null>(null);
  const [uploadedReportLink, setUploadedReportLink] = useState<string | null>(
    null
  );
  const { token: tokenResponse, setToken: setTokenResponse } =
    useGoogleApiToken();
  const [gapiInited, setGapiInited] = useState(false);
  const [gsiInited, setGsiInited] = useState(false);
  const [googleTokenClient, setGoogleTokenClient] =
    useState<google.accounts.oauth2.TokenClient | null>(null);
  const [selectedReportId, setReportId] = useState<number | null>(null);
  const [reportName, setReportName] = useState<string | null>(null);
  const [reportDate, setReportDate] = useState<string | null>(() =>
    new Date().toLocaleDateString("en-CA")
  );

  useEffect(() => {
    if (!isOpen) {
      setUploadedReportLink(null);
    }
  }, [isOpen]);

  useEffect(() => {
    $script("https://apis.google.com/js/api.js", () => {
      gapi.load("client", async () => {
        await gapi.client.init({
          apiKey: process.env.REACT_APP_GOOGLE_DRIVE_API_KEY,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
          ],
        });
        setGapiInited(true);
      });
    });

    $script("https://accounts.google.com/gsi/client", () => {
      setGoogleTokenClient(
        google.accounts.oauth2.initTokenClient({
          client_id: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID || "",
          scope: `${AUTH_SCOPE_DRIVE} ${AUTH_SCOPE_EMAIL}`,
          callback: (tokenResponse) => {
            if (!tokenResponse.scope.includes(AUTH_SCOPE_DRIVE)) {
              setErrorType(Errors.MISSING_REQUIRED_SCOPES);
              return;
            }

            setTokenResponse(tokenResponse);
          },
        })
      );

      setGsiInited(true);
    });
  }, [setTokenResponse]);

  useEffect(() => {
    if (!selectedFile) return;

    setReportName(selectedFile.name);
    setReportDate(
      new Date(selectedFile.lastModified).toLocaleDateString("en-CA")
    );
  }, [selectedFile]);

  const [, saveReport] = useMutation<
    SaveSnapshotReportMutation,
    SaveSnapshotReportMutationVariables
  >(SAVE_SNAPSHOT_REPORT_MUTATION);

  const handleGoogleAuthClick = () => {
    if (!googleTokenClient) return;

    setErrorType(null);

    if (gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account and ask for
      // consent to share their data when establishing a new session.
      googleTokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      googleTokenClient.requestAccessToken({ prompt: "" });
    }
  };

  const handleDriveUploadClick = () => {
    if (!fileInputRef.current) return;

    fileInputRef.current.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);

    if (file) setSelectedFile(file);
  };

  const onUploadReport = async () => {
    if (
      !shot ||
      !tokenResponse ||
      !selectedFile ||
      !reportName ||
      !reportDate
    ) {
      return;
    }

    setErrorType(null);
    setUploadedReportLink(null);
    setUploadReportProgress(0);
    setUploadingReport(true);
    setUploadReportStage(UploadReportStage.SETUP_RESOURCES_ON_GDRIVE);

    const { result: generatedIdResult } =
      await gapi.client.drive.files.generateIds({
        count: 1,
      });

    if (!generatedIdResult.ids) {
      throw new Error(Errors.CANNOT_SAVE_FOLDER);
    }

    setUploadReportStage(UploadReportStage.UPLOAD_METADATA_ON_DB);
    const reportDriveId = generatedIdResult.ids[0];
    const { data, error } = await saveReport({
      report: {
        ...(selectedReportId && { id: selectedReportId }),
        name: reportName,
        link: "https://drive.google.com/file/d/" + reportDriveId,
        seasonShotId: shot.id,
        createdAt: new Date(reportDate).toISOString(),
      },
    });

    if (error) {
      throw new Error(Errors.CANNOT_SAVE_REPORT);
    }

    const reportId = data?.insert_reports_one?.id;
    setReportId(reportId);
    setUploadReportStage(UploadReportStage.SETUP_RESOURCES_ON_GDRIVE);
    try {
      let searchResult = await gapi.client.drive.files.list({
        q: "name = 'farmevo' and trashed = false and mimeType = 'application/vnd.google-apps.folder'",
        pageSize: 1,
        fields: "files(id)",
        spaces: "drive",
      });

      let farmevoFolderId = searchResult.result.files?.[0]?.id;

      if (!farmevoFolderId) {
        const farmevoFolder = await gapi.client.drive.files.create({
          resource: {
            name: "farmevo",
            mimeType: "application/vnd.google-apps.folder",
          },
          fields: "id",
        });

        farmevoFolderId = farmevoFolder.result.id;
      }

      if (!farmevoFolderId) {
        throw new Error(Errors.CANNOT_SAVE_FOLDER);
      }

      searchResult = await gapi.client.drive.files.list({
        q: `name = 'field-${shot.fieldId}' and '${farmevoFolderId}' in parents and trashed = false and mimeType = 'application/vnd.google-apps.folder'`,
        pageSize: 1,
        fields: "files(id)",
        spaces: "drive",
      });

      let fieldFolderId = searchResult.result.files?.[0]?.id;

      if (!fieldFolderId) {
        const fieldFolder = await gapi.client.drive.files.create({
          resource: {
            name: `field-${shot.fieldId}`,
            mimeType: "application/vnd.google-apps.folder",
            parents: [farmevoFolderId],
          },
          fields: "id",
        });
        fieldFolderId = fieldFolder.result.id;
      }

      if (!fieldFolderId) {
        throw new Error(Errors.CANNOT_SAVE_FOLDER);
      }

      searchResult = await gapi.client.drive.files.list({
        q: `name = '${shot.season}' and '${fieldFolderId}' in parents and trashed = false and mimeType = 'application/vnd.google-apps.folder'`,
        pageSize: 1,
        fields: "files(id)",
        spaces: "drive",
      });

      let seasonFolderId = searchResult.result.files?.[0]?.id;

      if (!seasonFolderId) {
        const seasonFolder = await gapi.client.drive.files.create({
          resource: {
            name: shot.season,
            mimeType: "application/vnd.google-apps.folder",
            parents: [fieldFolderId],
          },
          fields: "id",
        });

        seasonFolderId = seasonFolder.result.id;
      }

      if (!seasonFolderId) {
        throw new Error(Errors.CANNOT_SAVE_FOLDER);
      }

      setUploadReportStage(UploadReportStage.UPLOAD_METADATA_ON_GDRIVE);

      const metadata = {
        id: reportDriveId,
        name: `report-${reportId}-${selectedFile.name}`,
        description: reportName,
        parents: [seasonFolderId],
        mimeType: selectedFile.type,
      };

      const result = await fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable",
        {
          method: "POST",
          body: JSON.stringify(metadata),
          headers: new Headers({
            Authorization: `Bearer ${tokenResponse.access_token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "X-Upload-Content-Type": selectedFile.type,
            "X-Upload-Content-Length": `${selectedFile.size}`,
          }),
        }
      );

      const resumableUri = result.headers.get("Location") as string;

      if (!resumableUri) {
        throw new Error(Errors.CANNOT_SAVE_FOLDER);
      }

      setUploadReportStage(UploadReportStage.UPLOAD_FILE_ON_GDRIVE);

      const xhr = new XMLHttpRequest();
      xhr.open("PUT", resumableUri, true);
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${tokenResponse.access_token}`
      );
      const xhrPromise = xhrRequestResumable(xhr, setUploadReportProgress);
      xhr.send(selectedFile);
      await xhrPromise;
      setUploadedReportLink(data?.insert_reports_one?.link || null);
      setSelectedFile(null);
      setReportId(null);
    } catch (error: any) {
      if (Object.values(Errors).includes(error.message)) {
        setErrorType(error.message);
      } else if (error.status === 401) {
        setErrorType(Errors.EXPIRED_TOKEN);
        setTokenResponse(null);
        setSelectedFile(null);
      } else {
        setErrorType(Errors.UNKNOWN_ERROR);
      }
    } finally {
      setUploadReportProgress(0);
      setUploadReportStage(null);
      setUploadingReport(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      closeOnEsc={!uploadingReport}
      closeOnOverlayClick={!uploadingReport}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Upload Report</Text>
          <HStack mt={2}>
            <Badge>{shot?.season}</Badge>
            {shot?.cropName && <Badge>{shot?.cropName}</Badge>}
          </HStack>
        </ModalHeader>
        <ModalCloseButton isDisabled={uploadingReport} />
        <ModalBody>
          {uploadedReportLink ? (
            <VStack align="flex-start">
              <Alert status="success" borderRadius="md">
                <AlertIcon />
                <Box>
                  <Text as="span">Uploaded report!</Text>{" "}
                </Box>
              </Alert>
              <Button variant="link" colorScheme="brand">
                <RouteLink target="_blank" to={uploadedReportLink}>
                  View Report
                </RouteLink>
              </Button>
            </VStack>
          ) : (
            <>
              <Text>Select an upload method:</Text>
              <HStack my={4}>
                <GoogleDriveButton
                  isDisabled={!gapiInited && !gsiInited && !googleTokenClient}
                  onClick={
                    tokenResponse
                      ? handleDriveUploadClick
                      : handleGoogleAuthClick
                  }
                  isSignedIn={!!tokenResponse}
                />
                {!!tokenResponse && (
                  <input
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    type="file"
                    id="file"
                    style={{ display: "none" }}
                  />
                )}
                <Button minH="110px" flex={1} isDisabled>
                  <VStack spacing={3}>
                    <Box>
                      <IconUploadCloud />
                    </Box>
                    <Text>Upload to our Cloud</Text>
                    <Text fontSize="sm" fontStyle="italic" color="gray.500">
                      Charges apply
                    </Text>
                  </VStack>
                </Button>
              </HStack>
              {errorType === Errors.EXPIRED_TOKEN && (
                <Text color="red.600">
                  Session expired. Click the above button again to sign in to
                  Google Drive
                </Text>
              )}
              {selectedFile && (
                <VStack>
                  <Alert status="success" borderRadius="md">
                    <AlertIcon />
                    <Box>
                      <Text as="span">Selected</Text>{" "}
                      <Text as="span" fontWeight="bold">
                        {" "}
                        {selectedFile.name}
                      </Text>{" "}
                      <Text as="span">of size</Text>{" "}
                      <Text as="span">
                        {(selectedFile.size / 1e6).toFixed(2)} MB
                      </Text>
                    </Box>
                  </Alert>
                  <FormControl py={2}>
                    <FormLabel>Give this report a name</FormLabel>
                    <Input
                      type="text"
                      name="reportName"
                      value={reportName || ""}
                      onChange={(e) => setReportName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Assign a date to this report</FormLabel>
                    <Input
                      type="date"
                      name="reportDate"
                      value={reportDate || ""}
                      onChange={(e) => setReportDate(e.target.value)}
                    />
                    <FormHelperText>
                      By default, it's the last modified date of your file
                    </FormHelperText>
                  </FormControl>
                  <Box w="full">
                    {errorType === Errors.MISSING_REQUIRED_SCOPES && (
                      <Text color="red.600">
                        We need access to your Google Drive to continue.
                      </Text>
                    )}
                    {errorType === Errors.CANNOT_SAVE_REPORT && (
                      <Text color="red.600">
                        Error saving report. Please try again or contact
                        support.
                      </Text>
                    )}
                    {errorType === Errors.CANNOT_SAVE_FOLDER && (
                      <Text color="red.600">
                        Error creating resources on your Google Drive. Please
                        try again or contact support.
                      </Text>
                    )}
                    {errorType === Errors.UNKNOWN_ERROR && (
                      <Text color="red.600">
                        An unknown error occurred. Please try again or contact
                        support.
                      </Text>
                    )}
                  </Box>
                </VStack>
              )}
            </>
          )}
        </ModalBody>
        {selectedFile && (
          <ModalFooter as={HStack}>
            {uploadingReport && uploadReportStage && (
              <Text fontSize="sm" color="gray.500" fontStyle="italic">
                {renderUploadStageText(uploadReportStage, uploadReportProgress)}
              </Text>
            )}
            <Button onClick={onClose} isDisabled={uploadingReport}>
              Cancel
            </Button>
            <Button
              onClick={onUploadReport}
              isDisabled={uploadingReport}
              isLoading={uploadingReport}
            >
              Upload
            </Button>
          </ModalFooter>
        )}
        {uploadedReportLink && (
          <ModalFooter as={HStack}>
            <Button onClick={() => setUploadedReportLink(null)}>
              Upload Another Report
            </Button>
            <Button onClick={onClose}>Ok</Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

const renderUploadStageText = (stage: UploadReportStage, progress: number) => {
  switch (stage) {
    case UploadReportStage.UPLOAD_METADATA_ON_DB:
      return "Uploading metadata on database...";
    case UploadReportStage.SETUP_RESOURCES_ON_GDRIVE:
      return "Setting up resources on Google Drive...";
    case UploadReportStage.UPLOAD_METADATA_ON_GDRIVE:
      return "Uploading metadata on Google Drive...";
    case UploadReportStage.UPLOAD_FILE_ON_GDRIVE:
      return `Uploading file on Google Drive ${progress}%...`;
    default:
      return null;
  }
};

const xhrRequestResumable = (
  xhr: XMLHttpRequest,
  onProgress: (progress: number) => void
) =>
  new Promise((resolve, reject) => {
    xhr.upload.addEventListener("progress", (e) =>
      onProgress(Math.round((e.loaded / e.total) * 100))
    );
    xhr.addEventListener("load", () =>
      resolve({ status: xhr.status, body: xhr.responseText })
    );
    xhr.addEventListener("error", () =>
      reject(new Error("File upload failed"))
    );
    xhr.addEventListener("abort", () =>
      reject(new Error("File upload aborted"))
    );
  });

export default UploadReportModal;
