import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  GetFieldSnapshotsQuery,
  GetFieldSnapshotsQueryVariables,
  GetFieldsQuery,
  GetFieldsQueryVariables,
  InsertFieldSeasonShotMutation,
} from "@farmevo/common/dist/graphql/graphql";
import {
  GET_FIELDS_QUERY,
  GET_FIELD_SNAPSHOTS_QUERY,
} from "@farmevo/web/src/Pages/Fields/fields.graphql";

const fieldsQueryUpdater = (
  newShot: InsertFieldSeasonShotMutation,
  data: GetFieldsQuery | null
) => {
  const existingFieldIndex =
    data?.field_season_shot.findIndex(
      (shot) => shot.fieldId === newShot.insert_field_season_shot_one?.fieldId
    ) || -1;

  if (data && newShot.insert_field_season_shot_one) {
    if (existingFieldIndex >= 0) {
      data.field_season_shot[existingFieldIndex] =
        newShot.insert_field_season_shot_one;
    } else {
      data.field_season_shot.push(newShot.insert_field_season_shot_one);
    }
  }
  return data;
};

export const insert_field_season_shot_one: UpdateResolver = (
  result,
  args,
  cache
) => {
  const newShot = result as InsertFieldSeasonShotMutation;

  // with dynamic farms filter, each query with a unique farmId (or none) is
  // cached uniquely, so each needs to be updated
  cache
    .inspectFields("query_root")
    .filter((f) => {
      const isFieldType = f.fieldName === "field_season_shot";
      const farmIdFilter = (
        f.arguments?.where as GetFieldsQueryVariables["filter"]
      )?.farmId;
      const isSameFarm =
        farmIdFilter?._eq === newShot.insert_field_season_shot_one?.farm.id;

      return isFieldType && (!farmIdFilter || isSameFarm);
    })
    .forEach((field) => {
      cache.updateQuery<GetFieldsQuery, GetFieldsQueryVariables>(
        {
          query: GET_FIELDS_QUERY,
          variables: {
            filter: field.arguments?.where as GetFieldsQueryVariables["filter"],
          },
        },
        (data) => {
          return fieldsQueryUpdater(newShot, data);
        }
      );
    });

  // Update season shots for a field
  cache.updateQuery<GetFieldSnapshotsQuery, GetFieldSnapshotsQueryVariables>(
    {
      query: GET_FIELD_SNAPSHOTS_QUERY,
      variables: {
        userId: newShot.insert_field_season_shot_one?.farm.userId,
        fieldId: newShot.insert_field_season_shot_one?.fieldId,
      },
    },
    (data) => {
      if (data && newShot.insert_field_season_shot_one) {
        data.field_season_shot.unshift(newShot.insert_field_season_shot_one);
      }
      return data;
    }
  );
};
