import {
  Box,
  HStack,
  Link,
  SkeletonText,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "urql";
import { Link as RouteLink } from "react-router-dom";
import { FieldShot, GET_SNAPSHOT_JOBS_QUERY } from "./fields.graphql";
import {
  GetSnapshotJobsQuery,
  GetSnapshotJobsQueryVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { JobStatusIcon } from "../../Components/JobStatusIcon";

const SnapshotJobs = ({
  selectedShot,
  isOpen,
}: {
  selectedShot?: FieldShot;
  isOpen: boolean;
}) => {
  const [{ data, error, fetching }] = useQuery<
    GetSnapshotJobsQuery,
    GetSnapshotJobsQueryVariables
  >({
    query: GET_SNAPSHOT_JOBS_QUERY,
    variables: {
      shotId: selectedShot?.id,
    },
    pause: !isOpen,
  });

  if (fetching) {
    return (
      <Box maxW="40%" mt={2} mb={1}>
        <SkeletonText noOfLines={1} />
      </Box>
    );
  }

  if (error) {
    return (
      <Text fontSize="sm" fontStyle="italic" color="red.600">
        Error loading jobs
      </Text>
    );
  }

  if (data?.job_fields.length === 0) {
    return (
      <Text fontSize="sm" fontStyle="italic" color="gray.600">
        Not available
      </Text>
    );
  }

  return (
    <VStack pr={6} spacing={3}>
      {data?.job_fields.map((jobField) => (
        <HStack key={jobField.jobId} alignItems="center" w="full">
          <HStack spacing={2.5} flex={1}>
            <JobStatusIcon status={jobField.job.status} />
            <Link
              colorScheme="brand"
              as={RouteLink}
              to={`/jobs/${jobField.jobId}`}
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              color="gray.600"
            >
              {jobField.job.operationName}
            </Link>
          </HStack>
          {jobField.job.from && (
            <Tooltip label="Scheduled for">
              <Text fontSize="xs" color="gray.500">
                {new Date(jobField.job.from).toDateString()}
              </Text>
            </Tooltip>
          )}
        </HStack>
      ))}
    </VStack>
  );
};

export default SnapshotJobs;
