import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  Text,
  Spinner,
  Input,
  FormHelperText,
  Button,
  useToast,
  InputRightAddon,
  InputGroup,
  VStack,
  HStack,
  Select,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useFormik } from "formik";
import {
  GetFarmsQuery,
  GetFarmsQueryVariables,
  GetMyInventoryQuery,
  GetMyProductsQuery,
  GetMyWarehousesQuery,
  GetProductsQuery,
  SaveMyInventoryMutation,
  SaveMyInventoryMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { useMutation, useQuery } from "urql";
import { SAVE_MY_INVENTORY } from "./inventory.graphql";
import { GET_FARMS_QUERY } from "../Fields/fields.graphql";
import { useSession } from "../../hooks/useSession";
import ComboSelect from "../../Components/ComboSelect";

const InventoryForm = (props: {
  warehouses: GetMyWarehousesQuery["warehouses"];
  products: GetMyProductsQuery["products"];
  selected?: GetMyInventoryQuery["inventory"][0] | null;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { warehouses, products } = props;
  const { session } = useSession();

  const [{ fetching: farmsFetching }] = useQuery<
    GetFarmsQuery,
    GetFarmsQueryVariables
  >({
    query: GET_FARMS_QUERY,
    variables: { userId: session?.id || -1 },
    pause: !session?.id,
  });

  const [{ fetching: insertingInventory }, insertInventory] = useMutation<
    SaveMyInventoryMutation,
    SaveMyInventoryMutationVariables
  >(SAVE_MY_INVENTORY);

  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      amount: "",
      price: "",
      productId: "",
      userId: session?.id || "",
      warehouseId: "",
    },
    onSubmit: async (values) => {
      //if warehouseId is set, remove the warehouse object, remove itself if not set.
      const insertedInventory = await insertInventory({
        saveInventoryPayload: {
          amount: values.amount,
          price: values.price,
          productId: values.productId,
          remaining: values.amount,
          userId: session?.id || -1,
          ...(values.warehouseId && { warehouseId: values.warehouseId }),
        },
      });

      if (insertedInventory.error) {
        toast({
          status: "error",
          title: "Something went wrong",
          description: "Please try again or contact support",
        });
        return;
      }
      toast({
        status: "success",
        title: `Inventory added successfully`,
      });

      props.onClose();
      formik.resetForm();
    },
    enableReinitialize: true,
  });

  const { setFieldValue } = formik;

  useEffect(() => {
    const product = products.find((p) => p.id === formik.values.productId);
    if (!product) {
      return;
    }
    setFieldValue("price", `${product.pricePerUnit}`);
  }, [formik.values.productId, products, setFieldValue]);

  if (farmsFetching) {
    return <Spinner />;
  }

  return (
    <Drawer size="md" isOpen={props.isOpen} onClose={props.onClose}>
      <DrawerOverlay />
      <DrawerContent overflow="scroll">
        <form onSubmit={formik.handleSubmit}>
          <DrawerHeader>Add Inventory</DrawerHeader>
          <DrawerBody>
            <VStack>
              <FormControl isRequired>
                {products ? (
                  <ComboSelect<GetProductsQuery["products"][0]>
                    label="Product"
                    items={products}
                    itemsFilter={(v) => (item) => item.name.includes(v)}
                    searchBehavior="reduce"
                    itemToString={(product) => product?.name || ""}
                    onSelectItem={(product) =>
                      formik.setFieldValue("productId", product?.id)
                    }
                    defaultSelectedItem={products.find(
                      (p) => p.id === formik.values.productId
                    )}
                    formControlProps={{ isRequired: true }}
                    renderItem={(item) => <span>{item?.name}</span>}
                    menuProps={{
                      maxH: "200px",
                    }}
                  />
                ) : (
                  <Text>
                    You don't have any products, go to{" "}
                    <Link to="/products">Products</Link> to create one.
                  </Text>
                )}
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Amount</FormLabel>
                <InputGroup>
                  <Input
                    {...formik.getFieldProps("amount")}
                    placeholder="0"
                    type="number"
                    min="1"
                  />
                  <InputRightAddon
                    children={
                      products?.filter(
                        (product) => product.id === formik.values.productId
                      )[0]?.chargeableUnit || "unit"
                    }
                  />
                </InputGroup>
                <FormHelperText>
                  The amount of product currently stored
                </FormHelperText>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Price</FormLabel>
                <InputGroup>
                  <Input
                    {...formik.getFieldProps("price")}
                    placeholder="Add a valid price for this batch"
                    type="number"
                    min="0"
                  />
                  <InputRightAddon>
                    {session?.user?.prefs?.currency}
                  </InputRightAddon>
                </InputGroup>
                <FormHelperText>
                  The price at which this batch of product was bought
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Warehouse</FormLabel>
                <Select {...formik.getFieldProps("warehouseId")}>
                  <option value="">None</option>
                  {warehouses.map((warehouse) => (
                    <option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </VStack>
          </DrawerBody>
          <DrawerFooter as={HStack}>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button
              isLoading={insertingInventory}
              isDisabled={insertingInventory || !formik.isValid}
              type="submit"
              variant="solid"
              colorScheme="brand"
            >
              Add
            </Button>
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
};

export default InventoryForm;
