import { FormMode } from "../../constants/enums";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "urql";
import {
  Chargeable_Units,
  Chargeable_Units_Enum,
  GetMyProductsPaginatedQuery,
  SaveMyProductMutation,
  SaveMyProductMutationVariables,
  UpdateMyProductMutation,
  UpdateMyProductMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { SAVE_MY_PRODUCT, UPDATE_MY_PRODUCT } from "./products.graphql";
import ComboSelect from "../../Components/ComboSelect";

const createProductValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  pricePerUnit: Yup.number().moreThan(0),
});

const ProductForm = (props: {
  chargeableUnits: Chargeable_Units[];
  mode: FormMode;
  selected?: GetMyProductsPaginatedQuery["products"][0] | null;
  isOpen: boolean;
  onClose: () => void;
  userId: number;
}) => {
  const toast = useToast();

  const [, insertProduct] = useMutation<
    SaveMyProductMutation,
    SaveMyProductMutationVariables
  >(SAVE_MY_PRODUCT);

  const [, updateProduct] = useMutation<
    UpdateMyProductMutation,
    UpdateMyProductMutationVariables
  >(UPDATE_MY_PRODUCT);

  const formik = useFormik({
    initialValues: props.selected
      ? {
          name: props.selected.name,
          pricePerUnit: props.selected.pricePerUnit,
          chargeableUnit: props.selected.chargeableUnit,
        }
      : {
          name: "",
          pricePerUnit: 0,
          chargeableUnit: Chargeable_Units_Enum.Unit,
        },
    onSubmit: async (values) => {
      switch (props.mode) {
        case FormMode.Add:
          const insertResult = await insertProduct({
            saveProductPayload: { ...values, userId: props.userId },
          });
          toast({
            title: insertResult.error
              ? `An error occured while saving product ${values.name}.`
              : `${values.name} saved.`,
            status: insertResult.error ? "error" : "success",
            duration: 3000,
            isClosable: true,
          });
          break;
        case FormMode.Edit:
          const updateResult = await updateProduct({
            updateProductPayload: { ...values },
            id: props.selected?.id || "-1",
          });
          toast({
            title: updateResult.error
              ? `An error occured while saving changes to product ${values.name}.`
              : `${values.name} updated.`,
            status: updateResult.error ? "error" : "success",
            duration: 3000,
            isClosable: true,
          });
      }
      props.onClose();
      formik.resetForm();
    },
    validationSchema: createProductValidationSchema,
    enableReinitialize: true,
  });

  if (!props.selected && props.mode === FormMode.Edit) {
    return null;
  }

  return (
    <Drawer size="sm" isOpen={props.isOpen} onClose={props.onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <form onSubmit={formik.handleSubmit}>
          <DrawerHeader>
            {props.mode === FormMode.Add
              ? "Add a New Product"
              : `Edit Product : ${props.selected?.name}`}
          </DrawerHeader>
          <DrawerBody>
            <Box mx="2">
              <FormControl
                isRequired
                isInvalid={
                  (!!formik.errors.name && formik.touched.name) as boolean
                }
                my="3"
              >
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                  placeholder="e.g Fuel"
                  {...formik.getFieldProps("name")}
                />
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              </FormControl>
              <FormControl
                my="2"
                isInvalid={
                  (!!formik.errors.pricePerUnit &&
                    formik.touched.pricePerUnit) as boolean
                }
              >
                <FormLabel htmlFor="pricePerUnit">Price / Unit</FormLabel>
                <Input
                  type="number"
                  {...formik.getFieldProps("pricePerUnit")}
                />
                <FormErrorMessage>
                  {formik.errors.pricePerUnit?.toString()}
                </FormErrorMessage>
              </FormControl>
              <Box my="2" overflow={"hidden"}>
                <ComboSelect<string>
                  label="Chargeable Unit"
                  items={props.chargeableUnits.map((cu) => {
                    return cu.value;
                  })}
                  itemsFilter={(v) => (item) => item.includes(v)}
                  searchBehavior="reduce"
                  onSelectItem={(item) =>
                    formik.setFieldValue("chargeableUnit", item)
                  }
                  inputProps={{ value: formik.values.chargeableUnit }}
                  renderItem={(item) => <span>{item}</span>}
                  //defaultSelectedItem={formik.initialValues.chargeableUnit}
                  menuProps={{
                    maxH: "200px",
                    width: "85%",
                    overflowX: "hidden",
                    position: "fixed",
                  }}
                />
              </Box>
            </Box>
          </DrawerBody>
          <DrawerFooter mb="5">
            <Button mr={4} onClick={props.onClose}>
              Close
            </Button>
            <Button
              isDisabled={!formik.isValid}
              mr={4}
              type="submit"
              variant="solid"
              colorScheme="brand"
            >
              Submit
            </Button>
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
};

export default ProductForm;
