import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  AddJobMutation,
  AddJobMutationVariables,
  GetJobDetailsQuery,
  GetJobDetailsQueryVariables,
  GetJobsQuery,
  GetJobsQueryVariables,
  GetMyUserDetailsQuery,
  GetMyUserDetailsQueryVariables,
  GetSnapshotJobsQuery,
  GetSnapshotJobsQueryVariables,
  Roles_Enum,
  UpdateJobMutation,
} from "@farmevo/common/dist/graphql/graphql";
import { GET_SNAPSHOT_JOBS_QUERY } from "@farmevo/web/src/Pages/Fields/fields.graphql";
import { GET_JOBS_QUERY } from "@farmevo/web/src/Pages/Jobs/JobsTable/jobs-table.graphql";
import { GET_JOB_DETAILS_QUERY } from "@farmevo/web/src/Pages/Jobs/JobDetails/job-details.graphql";
import {
  GET_MY_USER_DETAILS_QUERY,
  SESSION_KEY,
  Session,
} from "@farmevo/web/src/hooks/useSession";

export const insert_jobs_one: UpdateResolver = (result, args, cache) => {
  const variables = args.object as AddJobMutationVariables["job"];
  const newJob = result as AddJobMutation & UpdateJobMutation;

  const session = JSON.parse(
    localStorage.getItem(SESSION_KEY) || "null"
  ) as Session;
  const userDetails = cache.readQuery<
    GetMyUserDetailsQuery,
    GetMyUserDetailsQueryVariables
  >({
    query: GET_MY_USER_DETAILS_QUERY,
    variables: { userId: session.id },
  });

  const isOperator = !!userDetails?.users_by_pk?.roles.find(
    (userRole) => userRole.role === Roles_Enum.Operator
  );
  cache
    .inspectFields("query_root")
    .filter((f) => {
      const isFieldTypeJobs =
        f.fieldName === newJob.insert_jobs_one?.__typename;
      const operationNameFilter = (
        f.arguments?.where as GetJobsQueryVariables["filter"]
      )?.operationName;
      const isSameOperation =
        operationNameFilter?._eq === newJob.insert_jobs_one?.operationName;

      return isFieldTypeJobs && (!operationNameFilter || isSameOperation);
    })
    .forEach((cacheField) => {
      const filter = cacheField.arguments
        ?.where as GetJobsQueryVariables["filter"];
      const limit = cacheField.arguments?.limit as number;
      const offset = cacheField.arguments?.offset as number;

      cache.updateQuery<GetJobsQuery, GetJobsQueryVariables>(
        {
          query: GET_JOBS_QUERY,
          variables: {
            filter,
            limit,
            offset,
          },
        },
        (data) => {
          const jobAlreadyExists = data?.jobs.find(
            (job) => job.id === newJob.insert_jobs_one?.id
          );
          if (newJob.insert_jobs_one && !jobAlreadyExists) {
            data?.jobs.unshift(newJob.insert_jobs_one);
            if (data?.count.aggregate) {
              data.count.aggregate.count += 1;
            }
          }
          return data;
        }
      );
    });

  // Update job details cache
  cache.updateQuery<GetJobDetailsQuery, GetJobDetailsQueryVariables>(
    {
      query: GET_JOB_DETAILS_QUERY,
      variables: { jobId: newJob.insert_jobs_one?.id, isOperator },
    },
    (data) => {
      if (newJob.insert_jobs_one) {
        return { jobs_by_pk: newJob.insert_jobs_one } as GetJobDetailsQuery;
      }
      return data;
    }
  );

  // Update jobs list for each job-field
  if (variables.fields?.data) {
    variables.fields.data.forEach((jobField) => {
      cache.updateQuery<GetSnapshotJobsQuery, GetSnapshotJobsQueryVariables>(
        {
          query: GET_SNAPSHOT_JOBS_QUERY,
          variables: { shotId: jobField.seasonShotId },
        },
        (data) => {
          const jobAlreadyExists = data?.job_fields.find(
            (jf) => jf.jobId === newJob.insert_jobs_one?.id
          );
          if (newJob.insert_jobs_one && !jobAlreadyExists) {
            data?.job_fields.push({
              jobId: newJob.insert_jobs_one.id,
              job: {
                id: newJob.insert_jobs_one.id,
                operationName: newJob.insert_jobs_one.operationName || "",
                from: newJob.insert_jobs_one.from,
                status: newJob.insert_jobs_one.status,
              },
            });
          }
          return data;
        }
      );
    });
  }
};
