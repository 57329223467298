import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  GetMyTaxRatesQuery,
  GetMyTaxRatesQueryVariables,
  SaveMyTaxRatesMutation,
  SaveMyTaxRatesMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { useMutation, useQuery } from "urql";
import { GET_MY_TAX_RATES, SAVE_MY_TAX_RATES } from "./accounts.graphql";
import { useSession } from "../../hooks/useSession";
import { IconPlus, IconX } from "../../Components/Icons";
import { useEffect, useState } from "react";

const RatesForm = () => {
  const { session } = useSession();

  const [rates, setRates] =
    useState<{ name: string; rate: number; label: string }[]>();

  const [{ data, fetching }] = useQuery<
    GetMyTaxRatesQuery,
    GetMyTaxRatesQueryVariables
  >({
    query: GET_MY_TAX_RATES,
    variables: { userId: session?.id || -1 },
    pause: !session?.id,
  });

  const [, saveTaxRates] = useMutation<
    SaveMyTaxRatesMutation,
    SaveMyTaxRatesMutationVariables
  >(SAVE_MY_TAX_RATES);

  const toast = useToast();

  useEffect(() => {
    const _rates = data?.user_tax_rates.map((tx_rt) => ({
      name: tx_rt.name,
      rate: tx_rt.rate,
      label: tx_rt.label,
    }));
    setRates(_rates);
  }, [data]);

  if (fetching) {
    return <Spinner />;
  }

  return (
    <Box>
      <Text fontSize="xl" m="2" fontWeight="semibold">
        Tax Rates
      </Text>
      {rates?.map((taxRate, txIdx) => (
        <Flex my="2" key={taxRate.label}>
          <FormControl isRequired mx="2">
            <FormLabel>Name</FormLabel>
            <Input
              onChange={({ target }) =>
                setRates((prev) => {
                  const _p = [...(prev || [])];
                  _p[txIdx].name = target.value;
                  return _p;
                })
              }
              value={taxRate.name}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel> Value (%)</FormLabel>
            <Input
              onChange={({ target }) =>
                setRates((prev) => {
                  const _p = [...(prev || [])];
                  _p[txIdx].rate = parseInt(target.value);
                  return _p;
                })
              }
              value={taxRate.rate || 0}
            />
          </FormControl>
          {txIdx === rates.length - 1 &&
            rates.length > (data?.user_tax_rates.length || 0) && (
              <Button
                mt="7"
                alignSelf="center"
                bgColor="red.300"
                textColor="white"
                mx="2"
                onClick={() =>
                  setRates((prev) => {
                    const _p = [...(prev || [])];
                    _p.pop();
                    return _p;
                  })
                }
              >
                <IconX />
              </Button>
            )}
        </Flex>
      ))}
      <Flex gap="2" justifyContent="right" m="5">
        <Button
          rightIcon={<IconPlus />}
          onClick={() => {
            setRates((prev) => [
              ...(prev || []),
              { name: "", rate: 0, label: "" },
            ]);
          }}
        >
          Add
        </Button>
        <Button
          type="submit"
          onClick={async () => {
            if (!rates) return;
            const result = await saveTaxRates({
              rates: rates.map((tx_rt) => ({
                name: tx_rt.name,
                rate: tx_rt.rate,
                userId: session?.id,
              })),
            });
            toast({
              title: result.error
                ? "An error occurred while saving tax rates"
                : "Tax rates have been updated",
              status: result.error ? "error" : "success",
              duration: 3000,
              isClosable: true,
            });
          }}
          colorScheme="brand"
        >
          Submit
        </Button>
      </Flex>
    </Box>
  );
};
export default RatesForm;
