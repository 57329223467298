import { Box, HStack, SkeletonText } from "@chakra-ui/react";

export const LoadingSkeleton = () => (
  <HStack align="center" pt={6}>
    <Box w="full">
      <SkeletonText w="50px" mb={2} noOfLines={1} skeletonHeight="2" />
      <SkeletonText pr={4} noOfLines={1} skeletonHeight="2" />
    </Box>
    <Box w="full" px={4}>
      <SkeletonText w="50px" mb={2} noOfLines={1} skeletonHeight="2" />
      <SkeletonText noOfLines={1} skeletonHeight="2" />
    </Box>
    <Box w="full" px={4}>
      <SkeletonText w="50px" mb={2} noOfLines={1} skeletonHeight="2" />
      <SkeletonText noOfLines={1} skeletonHeight="2" />
    </Box>
    <Box w="full" px={4}>
      <SkeletonText w="50px" mb={2} noOfLines={1} skeletonHeight="2" />
      <SkeletonText noOfLines={1} skeletonHeight="2" />
    </Box>
    <Box w="full" pl={4}>
      <SkeletonText w="50px" mb={2} noOfLines={1} skeletonHeight="2" />
      <SkeletonText noOfLines={1} skeletonHeight="2" />
    </Box>
  </HStack>
);
