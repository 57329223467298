export const THEME_COLORS = {
  brand: {
    50: "#fff1e5",
    100: "#fdebdc",
    200: "#fde0cb",
    300: "#fcd6b9",
    400: "#fbcca8",
    500: "#FF7E1D",
    600: "#f9b885",
    700: "#f9ad73",
    800: "#f8a362",
    900: "#f79950",
  },
  black: {},
};
