export const symbols: Record<string, string> = {
  USD: "$",
  AUD: "$",
  CAD: "$",
  PKR: "Rs.",
  GBP: "£",
  EUR: "€",
};

export const currencies: Currency[] = [
  { code: "USD", name: "United States Dollar", symbol: "$" },
  { code: "AUD", name: "Australian Dollar", symbol: "$" },
  { code: "CAD", name: "Canadian Dollar", symbol: "$" },
  { code: "PKR", name: "Pakistani Rupee", symbol: "Rs." },
  { code: "GBP", name: "British Pound", symbol: "£" },
  { code: "EUR", name: "Euro", symbol: "€" },
];

export interface Currency {
  code: string;
  name: string;
  symbol: string;
}
