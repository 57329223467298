import { useReactToPrint } from "react-to-print";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery } from "urql";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Divider,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SkeletonText,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  UnorderedList,
  VStack,
  useDisclosure,
  useToast,
  useToken,
} from "@chakra-ui/react";
import { useNavigate, useParams, Link as RouteLink } from "react-router-dom";
import {
  IconArrowLeft,
  IconCornerDownLeft,
  IconDownload,
  IconEdit,
  IconLock,
} from "../../../Components/Icons";
import {
  GET_JOB_DETAILS_QUERY,
  UPDATE_JOB_STATUS_MUTATION,
} from "./job-details.graphql";
import {
  Chargeable_Units_Enum,
  GetJobDetailsQuery,
  GetJobDetailsQueryVariables,
  Job_Status_Enum,
  UpdateJobStatusMutation,
  UpdateJobStatusMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { useSession } from "@farmevo/web/src/hooks/useSession";
import { JobStatusIcon } from "@farmevo/web/src/Components/JobStatusIcon";
import { roundToTwo } from "@farmevo/web/src/utils/math";
import { pluralize } from "@farmevo/web/src/utils/pluralize";
import logoFull from "@farmevo/web/src/assets/logo-full.svg";

const JobDetails = () => {
  const { session } = useSession();
  const toast = useToast();
  const gray500 = useToken("colors", "gray.500");
  const { jobId } = useParams<{ jobId: string }>();
  const navigate = useNavigate();
  const {
    isOpen: isInvoiceModalOpen,
    onClose: closeInvoiceModal,
    onOpen: openInvoiceModal,
  } = useDisclosure();
  const {
    isOpen: isCompleteConfirmModalOpen,
    onClose: closeCompleteConfirmModal,
    onOpen: openCompleteConfirmModal,
  } = useDisclosure();
  const {
    isOpen: isCancelConfirmModalOpen,
    onClose: closeCancelConfirmModal,
    onOpen: openCancelConfirmModal,
  } = useDisclosure();
  const isOperator = !!session?.user?.isOperator;
  const [{ data: jobData, fetching }] = useQuery<
    GetJobDetailsQuery,
    GetJobDetailsQueryVariables
  >({
    query: GET_JOB_DETAILS_QUERY,
    variables: { jobId, isOperator },
  });

  const [{ fetching: updatingJob }, updateJobStatus] = useMutation<
    UpdateJobStatusMutation,
    UpdateJobStatusMutationVariables
  >(UPDATE_JOB_STATUS_MUTATION);

  const job = jobData?.jobs_by_pk;

  if (fetching) {
    return (
      <VStack align="unset" p={10}>
        <SkeletonText width="200px" noOfLines={1} />
        <SkeletonText noOfLines={2} />
      </VStack>
    );
  }

  if (!job) {
    return <Text>Not found</Text>;
  }

  const jobArea = roundToTwo(
    job.fields.reduce(
      (total, nextField) => total + nextField.seasonShot.polygon.size,
      0
    )
  );

  const updateStatus = async (variables: UpdateJobStatusMutationVariables) => {
    const result = await updateJobStatus(variables);

    if (result.error) {
      toast({
        status: "error",
        title:
          variables.status === Job_Status_Enum.PendingCompletion
            ? "Cannot complete the job"
            : "An error occurred",
        description:
          variables.status === Job_Status_Enum.PendingCompletion &&
          "Check if you have added all the information required before changing the status",
      });
    }
  };

  const onStatusChange = async (status: Job_Status_Enum) => {
    if (
      status === Job_Status_Enum.Completed ||
      status === Job_Status_Enum.PendingCompletion
    ) {
      openCompleteConfirmModal();
    } else if (status === Job_Status_Enum.Cancelled) {
      openCancelConfirmModal();
    } else {
      await updateStatus({
        jobId: job.id,
        status: status,
      });
    }
  };

  const onConfirmComplete = async () => {
    closeCompleteConfirmModal();
    await updateStatus({
      jobId: job.id,
      status: isOperator
        ? Job_Status_Enum.PendingCompletion
        : Job_Status_Enum.Completed,
    });
  };

  const onConfirmCancel = async () => {
    closeCancelConfirmModal();
    await updateStatus({
      jobId: job.id,
      status: Job_Status_Enum.Cancelled,
    });
  };

  const canOperatorUpdate =
    job.status !== Job_Status_Enum.PendingCompletion &&
    job.status !== Job_Status_Enum.Completed &&
    job.status !== Job_Status_Enum.Cancelled;

  const canManagerUpdate =
    job.status !== Job_Status_Enum.Completed &&
    job.status !== Job_Status_Enum.Cancelled;

  return (
    <>
      <VStack p={10} align="unset" spacing={4}>
        <HStack>
          <IconButton
            variant="ghost"
            aria-label="go back"
            icon={<IconArrowLeft />}
            onClick={() => navigate("/jobs")}
          />
          <Text fontWeight="bold" fontSize="2xl">
            #{jobId} - Job Details
          </Text>
          {((isOperator && canOperatorUpdate) ||
            (!isOperator && canManagerUpdate)) && (
            <Link
              px={4}
              as={RouteLink}
              fontWeight="semibold"
              color="gray.500"
              to="edit"
            >
              <IconEdit />
            </Link>
          )}
        </HStack>
        <Text fontWeight="bold" fontSize="lg">
          {job.operationName}
        </Text>
        {job.assignedUser && (
          <VStack align="unset" spacing={0.5}>
            <Text fontSize="sm" color="gray.500" fontWeight="semibold">
              Contractor
            </Text>
            <Text>
              {job.assignedUser.firstName}{" "}
              {job.assignedUser.id === session?.id && "(You)"}
            </Text>
          </VStack>
        )}
        <HStack spacing={8}>
          <VStack align="unset" spacing={0.5}>
            <HStack>
              <Text fontSize="sm" color="gray.500" fontWeight="semibold">
                Status
              </Text>
              {updatingJob && <Spinner color="brand.500" size="xs" />}
            </HStack>
            <Menu>
              <MenuButton
                as={Button}
                variant="ghost"
                fontWeight="normal"
                px={2}
                h="8"
                isDisabled={
                  updatingJob ||
                  (isOperator && !canOperatorUpdate) ||
                  (!isOperator && !canManagerUpdate)
                }
              >
                <HStack>
                  <JobStatusIcon status={job.status} hideTooltip />
                  <Text textTransform="capitalize">
                    {job.status.replace("_", " ")}
                    {job.status === Job_Status_Enum.PendingCompletion &&
                      (isOperator ? " by manager" : " by you")}
                  </Text>
                </HStack>
              </MenuButton>
              <MenuList>
                {!isOperator && (
                  <MenuItem
                    onClick={() => onStatusChange(Job_Status_Enum.Pending)}
                    icon={
                      <JobStatusIcon
                        status={Job_Status_Enum.Pending}
                        hideTooltip
                      />
                    }
                  >
                    Pending
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => onStatusChange(Job_Status_Enum.Ready)}
                  icon={
                    <JobStatusIcon status={Job_Status_Enum.Ready} hideTooltip />
                  }
                >
                  Ready
                </MenuItem>
                <MenuItem
                  onClick={() => onStatusChange(Job_Status_Enum.InProgress)}
                  icon={
                    <JobStatusIcon
                      status={Job_Status_Enum.InProgress}
                      hideTooltip
                    />
                  }
                >
                  In Progress
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    onStatusChange(Job_Status_Enum.PartlyCompleted)
                  }
                  icon={
                    <JobStatusIcon
                      status={Job_Status_Enum.PartlyCompleted}
                      hideTooltip
                    />
                  }
                >
                  Partly Completed
                </MenuItem>
                {!isOperator && (
                  <MenuItem
                    onClick={() => onStatusChange(Job_Status_Enum.Cancelled)}
                    icon={
                      <JobStatusIcon
                        status={Job_Status_Enum.Cancelled}
                        hideTooltip
                      />
                    }
                  >
                    Cancelled
                  </MenuItem>
                )}
                {isOperator && (
                  <MenuItem
                    onClick={() =>
                      onStatusChange(Job_Status_Enum.PendingCompletion)
                    }
                    icon={
                      <JobStatusIcon
                        status={Job_Status_Enum.PendingCompletion}
                        hideTooltip
                      />
                    }
                  >
                    Completed
                  </MenuItem>
                )}
                {!isOperator && (
                  <MenuItem
                    onClick={() => onStatusChange(Job_Status_Enum.Completed)}
                    icon={
                      <JobStatusIcon
                        status={Job_Status_Enum.Completed}
                        hideTooltip
                      />
                    }
                  >
                    Completed
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </VStack>
          {job.from && (
            <VStack align="unset" spacing={0.5}>
              <Text fontSize="sm" color="gray.500" fontWeight="semibold">
                Scheduled date & time
              </Text>
              <Text>
                {job.from.toLocaleString()}{" "}
                {job.to && `— ${job.to.toLocaleString()}`}
              </Text>
            </VStack>
          )}
          {job.completedAt && (
            <VStack align="unset" spacing={0.5}>
              <Text fontSize="sm" color="gray.500" fontWeight="semibold">
                Completed at
              </Text>
              <Text>{job.completedAt.toLocaleString()}</Text>
            </VStack>
          )}
        </HStack>
        {job.instructions && (
          <VStack align="unset" spacing={0.5}>
            <Text fontSize="sm" color="gray.500" fontWeight="semibold">
              Instructions
            </Text>
            <Text>{job.instructions}</Text>
          </VStack>
        )}
        {job.operators.length > 0 && (
          <VStack align="unset">
            <Text fontSize="sm" color="gray.500" fontWeight="semibold">
              Operators
            </Text>
            <VStack align="unset">
              {job.operators.map((operator) => (
                <HStack key={operator.user.id}>
                  <Avatar name={operator.user.firstName} size="sm" />
                  <Text>{operator.user.firstName}</Text>
                  {operator.user.id === session?.id && <Text>(You)</Text>}
                  {operator.vehicles.length > 0 && (
                    <Tooltip
                      label={operator.vehicles.map((ov) => (
                        <Text key={ov.vehicle.id}>
                          {ov.vehicle.name} {ov.vehicle.model}
                        </Text>
                      ))}
                      hasArrow
                    >
                      <Text fontSize="sm" color="gray.500">
                        {pluralize(operator.vehicles.length, "vehicle")}
                      </Text>
                    </Tooltip>
                  )}
                  {operator.implements.length > 0 && (
                    <Text fontSize="sm" color="gray.500">
                      {pluralize(operator.implements.length, "implement")}
                    </Text>
                  )}
                </HStack>
              ))}
            </VStack>
          </VStack>
        )}
        {job.fields.length > 0 && (
          <VStack align="unset">
            <HStack align="end">
              <Text fontSize="sm" color="gray.500" fontWeight="semibold">
                Fields
              </Text>
              <Text fontSize="xs" color="gray.500">
                (Total area: {jobArea} acres)
              </Text>
            </HStack>
            <VStack align="unset">
              {job.fields.map((field) => (
                <HStack key={field.seasonShot.id}>
                  <Text>{field.seasonShot.name}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {roundToTwo(field.seasonShot.polygon.size)} acres
                  </Text>
                </HStack>
              ))}
            </VStack>
          </VStack>
        )}
        {job.extras.length > 0 && (
          <VStack align="unset">
            <Text fontSize="sm" color="gray.500" fontWeight="semibold">
              Extras
            </Text>
            <VStack align="unset">
              {job.extras.map((extra) => (
                <HStack key={extra.name}>
                  <Text>{extra.name}</Text>
                  {extra.value && (
                    <Text fontWeight="semibold">{extra.value}</Text>
                  )}
                  <Text fontSize="sm" color="gray.500">
                    {extra.unit}
                  </Text>
                </HStack>
              ))}
            </VStack>
          </VStack>
        )}
        {job.products.length > 0 && (
          <VStack align="unset">
            <Text fontSize="sm" color="gray.500" fontWeight="semibold">
              Products
            </Text>
            <VStack align="unset">
              {job.products.map((product) => (
                <HStack key={product.productId}>
                  <Text>{product.product.name}</Text>
                  {product.value && (
                    <Text fontWeight="semibold">{product.value}</Text>
                  )}
                  <Text fontSize="sm" color="gray.500">
                    {product.product.chargeableUnit}
                  </Text>
                  {product.locked && <IconLock size={14} color={gray500} />}
                </HStack>
              ))}
            </VStack>
          </VStack>
        )}
        {job.locations.length > 0 && (
          <VStack align="unset">
            <Text fontSize="sm" color="gray.500" fontWeight="semibold">
              Locations
            </Text>
            <VStack align="unset">
              {job.locations.map((location) => (
                <HStack key={location.id}>
                  <Text>{location.notes}</Text>
                </HStack>
              ))}
            </VStack>
          </VStack>
        )}
        {!isOperator && (
          <Box>
            <Button onClick={openInvoiceModal}>View Invoice</Button>
          </Box>
        )}
      </VStack>
      {!isOperator && (
        <JobInvoiceModal
          job={job}
          isOpen={isInvoiceModalOpen}
          onClose={closeInvoiceModal}
        />
      )}
      <CompleteJobConfirmModal
        isOpen={isCompleteConfirmModalOpen}
        onClose={closeCompleteConfirmModal}
        onConfirm={onConfirmComplete}
      />
      <CancelJobConfirmModal
        isOpen={isCancelConfirmModalOpen}
        onClose={closeCancelConfirmModal}
        onConfirm={onConfirmCancel}
      />
    </>
  );
};

const JobInvoiceModal = ({
  job,
  isOpen,
  onClose,
}: {
  job: GetJobDetailsQuery["jobs_by_pk"];
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [gray500] = useToken("colors", ["gray.500"]);
  const { session } = useSession();
  const contentRef = useRef(null);
  const [operationCost, setOperationCost] = useState<string | null>(null);
  const [isOperationCostSet, setIsOperationCostSet] = useState(false);
  const print = useReactToPrint({ content: () => contentRef.current });

  const jobArea = useMemo(
    () =>
      roundToTwo(
        job?.fields.reduce(
          (total, nextField) => total + nextField.seasonShot.polygon.size,
          0
        ) || 0
      ),
    [job]
  );

  const productCost = useMemo(
    () =>
      job?.products.reduce(
        (cost, nextProduct) =>
          cost +
          Number(nextProduct.value || 0) * nextProduct.product.pricePerUnit,
        0
      ) || 0,
    [job]
  );

  useEffect(() => {
    if (!job) return;

    if (
      job.operation?.billingUnit === Chargeable_Units_Enum.Acre &&
      jobArea > 0
    ) {
      setOperationCost(`${jobArea}`);
      setIsOperationCostSet(true);
    }
  }, [job, jobArea]);

  if (!job || !job.assignedUser || !job.user) return null;

  const contractorAddress =
    job.assignedUser &&
    `${job.assignedUser.profile?.address1 || ""} ${
      job.assignedUser.profile?.address2 || ""
    } ${job.assignedUser.profile?.city || ""} ${
      job.assignedUser.profile?.country || ""
    } ${job.assignedUser.profile?.postalCode || ""}`.trim();

  const customerAddress =
    job.user &&
    `${job.user.profile?.address1 || ""} ${job.user.profile?.address2 || ""} ${
      job.user.profile?.city || ""
    } ${job.user.profile?.country || ""} ${
      job.user.profile?.postalCode || ""
    }`.trim();

  const onKeyDownOperationCost = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && operationCost !== null) {
      setIsOperationCostSet(true);
    }
  };

  const operationCostTotal = roundToTwo(
    (job.operation?.billingRate || 0) * Number(operationCost)
  );

  const subtotal = productCost + operationCostTotal;
  const total = subtotal;

  const onClickPrint = () => {
    print();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invoice</ModalHeader>
        <ModalBody ref={contentRef}>
          <HStack justify="space-between" align="center">
            <Image h="40px" src={logoFull} />
            <VStack spacing={1} align="end">
              <Text fontSize="lg" fontWeight="semibold">
                Job Invoice
              </Text>
              <Text>{new Date().toLocaleString()}</Text>
            </VStack>
          </HStack>
          <Divider mt={2} borderColor="gray.600" />
          <HStack mt={4}>
            <VStack align="unset" flex={1}>
              <Text color="gray.500">Contractor</Text>
              <Text fontWeight="semibold">
                {job.assignedUser.profile?.companyName || "Company name"}
              </Text>
              <Text>{contractorAddress || "Company address"}</Text>
              <Text>{job.assignedUser.profile?.phone || "Company phone"}</Text>
            </VStack>
            <VStack align="unset" flex={1}>
              <Text color="gray.500">Customer</Text>
              <Text fontWeight="semibold">
                {job.user.profile?.companyName || "Customer name"}
              </Text>
              <Text>{customerAddress || "Customer address"}</Text>
              <Text>{job.assignedUser.profile?.phone || "Company phone"}</Text>
            </VStack>
          </HStack>
          <Text mt={8} fontWeight="semibold" color="gray.500">
            Job Details
          </Text>
          <Divider />
          <HStack mt={4} align="start">
            <VStack spacing={1} align="unset" flex={1}>
              <Text color="gray.500" fontSize="sm">
                Operation name
              </Text>
              <Text fontWeight="semibold">{job.operationName}</Text>
            </VStack>
            <VStack spacing={1} align="unset" flex={1}>
              <Text color="gray.500" fontSize="sm">
                Billable area
              </Text>
              <Text fontWeight="semibold">
                {jobArea ? `${jobArea} acres` : "N/a"}
              </Text>
            </VStack>
            <VStack spacing={1} align="unset" flex={1}>
              <Text color="gray.500" fontSize="sm">
                Completion date
              </Text>
              <Text fontWeight="semibold">
                {job.completedAt?.toLocaleString() || "N/a"}
              </Text>
            </VStack>
          </HStack>
          <HStack mt={4} align="start">
            {job.products.length > 0 && (
              <VStack spacing={1} align="unset" flex={1}>
                <Text color="gray.500" fontSize="sm">
                  Products
                </Text>
                <Box>
                  {job.products.map((jp) => (
                    <Box key={jp.productId}>
                      <Text as="span" fontWeight="semibold">
                        {jp.product.name}
                      </Text>{" "}
                      - {jp.value || 0}{" "}
                      {jp.product.chargeableUnit?.replaceAll("_", " ")}
                    </Box>
                  ))}
                </Box>
              </VStack>
            )}
            {job.extras.length > 0 && (
              <VStack spacing={1} align="unset" flex={1}>
                <Text color="gray.500" fontSize="sm">
                  Extras
                </Text>
                <Box>
                  {job.extras.map((je) => (
                    <Box key={je.name}>
                      <Text as="span" fontWeight="semibold">
                        {je.name}
                      </Text>{" "}
                      - {je.value || 0} {je.unit.replaceAll("_", " ")}
                    </Box>
                  ))}
                </Box>
              </VStack>
            )}
            <Box flex={1} />
          </HStack>
          <Table mt={10} variant="simple">
            <Tbody>
              <Tr>
                <Td px={0} textAlign="left">
                  Operation cost{" "}
                  <Text as="span" color="gray.500" fontSize="sm">
                    {job.operation?.billingRate}{" "}
                    {session?.user?.prefs?.currency} /{" "}
                    {job.operation?.billingUnit}
                  </Text>
                </Td>
                <Td px={0} textAlign="right">
                  {isOperationCostSet ? (
                    <Tooltip label="Double click to edit" hasArrow>
                      <Text
                        as="span"
                        onDoubleClick={() => setIsOperationCostSet(false)}
                      >
                        {session?.user?.prefs?.currency}{" "}
                        {roundToTwo(
                          (job.operation?.billingRate || 0) *
                            Number(operationCost)
                        ).toLocaleString()}
                      </Text>
                    </Tooltip>
                  ) : (
                    <>
                      <Text as="span">
                        Number of {job.operation?.billingUnit}s:{" "}
                      </Text>
                      <InputGroup display="inline-block" width="70px">
                        <Input
                          value={operationCost || ""}
                          min={0}
                          height="unset"
                          type="number"
                          variant="flushed"
                          py={1}
                          pr={4}
                          placeholder="0"
                          onKeyDown={onKeyDownOperationCost}
                          onChange={(e) => setOperationCost(e.target.value)}
                        />
                        <InputRightElement w="unset" h={7}>
                          <Tooltip label="Press ENTER to set" hasArrow>
                            <IconCornerDownLeft color={gray500} size={16} />
                          </Tooltip>
                        </InputRightElement>
                      </InputGroup>
                    </>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td px={0} textAlign="left">
                  Products cost
                </Td>
                <Td px={0} textAlign="right">
                  {session?.user?.prefs?.currency}{" "}
                  {productCost.toLocaleString()}
                </Td>
              </Tr>
              <Tr>
                <Td px={0} textAlign="left">
                  Subtotal
                </Td>
                <Td px={0} textAlign="right">
                  {session?.user?.prefs?.currency} {subtotal.toLocaleString()}
                </Td>
              </Tr>
              <Tr>
                <Td px={0} textAlign="left">
                  Total
                </Td>
                <Td px={0} textAlign="right">
                  {session?.user?.prefs?.currency} {total.toLocaleString()}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            leftIcon={<IconDownload />}
            onClick={onClickPrint}
            isDisabled={!isOperationCostSet}
          >
            Download
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const CompleteJobConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => (
  <Modal isCentered onClose={onClose} isOpen={isOpen}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Complete Job?</ModalHeader>
      <ModalBody as={VStack} align="unset">
        <Text>Are you sure you want to mark this job as completed?</Text>
        <Alert status="error" borderRadius="md">
          <AlertIcon />
          <AlertDescription fontSize="sm">
            <Text>This action is irreversible! Once completed:</Text>
            <UnorderedList>
              <ListItem>You cannot change the status again</ListItem>
              <ListItem>You cannot edit the job</ListItem>
            </UnorderedList>
          </AlertDescription>
        </Alert>
        <Alert status="info" borderRadius="md">
          <AlertIcon />
          <AlertDescription fontSize="sm">
            <Text>
              Before completing the job, make sure you've filled information for
              extras and products
            </Text>
          </AlertDescription>
        </Alert>
      </ModalBody>
      <ModalFooter as={HStack}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm}>Yes, Complete job</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

const CancelJobConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => (
  <Modal isCentered onClose={onClose} isOpen={isOpen}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Cancel Job?</ModalHeader>
      <ModalBody as={VStack} align="unset">
        <Text>Are you sure you want to cancel this job?</Text>
        <Alert status="error" borderRadius="md">
          <AlertIcon />
          <AlertDescription fontSize="sm">
            <Text>This action is irreversible! Once cancelled:</Text>
            <UnorderedList>
              <ListItem>You cannot change the status again</ListItem>
              <ListItem>You cannot edit the job</ListItem>
            </UnorderedList>
          </AlertDescription>
        </Alert>
      </ModalBody>
      <ModalFooter as={HStack}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm}>Yes, Cancel job</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default JobDetails;
