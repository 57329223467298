import { gql } from "urql";

export const GET_MY_TEAM_MEMBERS = gql`
  query GetMyTeamMembers($userId: Int!, $roles: [roles_enum!]) {
    team_members(
      where: {
        ownerId: { _eq: $userId }
        teamMember: { roles: { role: { _in: $roles } } }
      }
    ) {
      teamMember {
        roles {
          role
        }
        username
        id
      }
    }
  }
`;

export const GET_MY_TEAM_INVITES = gql`
  query GetMyTeamInvites($userId: Int!) {
    user_invites(where: { user_id: { _eq: $userId } }) {
      invitee_email
      invitee_role
      id: invite_id
    }
  }
`;
