import { useSession } from "../hooks/useSession";
import LayoutLoading from "./LayoutLoading";

type Props = {
  children: JSX.Element;
};

const LayoutSession = ({ children }: Props) => {
  const { fetchingSession } = useSession();

  if (fetchingSession) {
    return <LayoutLoading />;
  }

  return children;
};

export default LayoutSession;
