import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  useToast,
  DrawerContent,
} from "@chakra-ui/react";
import { useMutation } from "urql";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormMode } from "../../constants/enums";
import {
  GetMyVehiclesQuery,
  Ownership_Types_Enum,
  SaveMyVehicleMutation,
  SaveMyVehicleMutationVariables,
  UpdateMyVehicleMutation,
  UpdateMyVehicleMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";

import { SAVE_MY_VEHICLE, UPDATE_MY_VEHICLE } from "./vehicles.graphql";

const createVehicleValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  width: Yup.number().moreThan(0),
  length: Yup.number().moreThan(0),
  weight: Yup.number().moreThan(0),
});

const VehicleForm = (props: {
  mode: FormMode;
  selected?: GetMyVehiclesQuery["vehicles"][0] | null;
  isOpen: boolean;
  onClose: () => void;
  userId: number;
}) => {
  const toast = useToast();

  const [, updateVehicle] = useMutation<
    UpdateMyVehicleMutation,
    UpdateMyVehicleMutationVariables
  >(UPDATE_MY_VEHICLE);

  const [, insertVehicle] = useMutation<
    SaveMyVehicleMutation,
    SaveMyVehicleMutationVariables
  >(SAVE_MY_VEHICLE);

  const formik = useFormik({
    initialValues: props.selected
      ? {
          brand: props.selected.brand,
          length: props.selected.length,
          model: props.selected.model,
          name: props.selected.name,
          ownership: props.selected.ownership,
          registrationNumber: props.selected.registrationNumber,
          weight: props.selected.weight,
          width: props.selected.width,
        }
      : {
          brand: "",
          length: 0,
          model: "",
          name: "",
          ownership: Ownership_Types_Enum.Rented,
          registrationNumber: "",
          weight: 0,
          width: 0,
        },
    enableReinitialize: true,
    onSubmit: async (values) => {
      switch (props.mode) {
        case FormMode.Add:
          const insertResult = await insertVehicle({
            saveVehiclePayload: { ...values, userId: props.userId },
          });
          toast({
            title: insertResult.error
              ? `An error occured while saving vehicle ${values.name}.`
              : `${values.name} saved.`,
            status: insertResult.error ? "error" : "success",
            duration: 3000,
            isClosable: true,
          });
          break;
        case FormMode.Edit:
          const updateResult = await updateVehicle({
            updateVehiclePayload: { ...values },
            id: props.selected?.id || "-1",
          });
          toast({
            title: updateResult.error
              ? `An error occured while saving changes to vehicle ${values.name}.`
              : `${values.name} updated.`,
            status: updateResult.error ? "error" : "success",
            duration: 3000,
            isClosable: true,
          });
      }
      props.onClose();
      formik.resetForm();
    },
    validationSchema: createVehicleValidationSchema,
  });

  if (!props.selected && props.mode === FormMode.Edit) {
    return null;
  }

  return (
    <Drawer size="sm" isOpen={props.isOpen} onClose={props.onClose}>
      <DrawerOverlay />
      <form onSubmit={formik.handleSubmit}>
        <DrawerContent>
          <DrawerHeader>
            {props.mode === FormMode.Add
              ? "Add a New Vehicle"
              : `Edit Vehicle : ${props.selected?.name}`}
          </DrawerHeader>
          <DrawerBody>
            <Box m="2">
              <FormControl
                isRequired
                isInvalid={!!formik.errors.name && formik.touched.name}
                my="2"
              >
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                  placeholder="e.g GreenMover"
                  {...formik.getFieldProps("name")}
                />
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              </FormControl>
              <FormControl my="2">
                <FormLabel htmlFor="model">Model</FormLabel>
                <Input
                  placeholder="e.g ECO2023"
                  {...formik.getFieldProps("model")}
                />
              </FormControl>
              <FormControl my="2">
                <FormLabel htmlFor="brand">Brand</FormLabel>
                <Input
                  placeholder="e.g EcoDrive"
                  {...formik.getFieldProps("brand")}
                />
              </FormControl>
              <FormControl my="2">
                <FormLabel htmlFor="registrationNumber">
                  Registration Number
                </FormLabel>
                <Input
                  placeholder="e.g ABC-1234"
                  {...formik.getFieldProps("registrationNumber")}
                />
              </FormControl>
              <FormControl my="2">
                <FormLabel htmlFor="ownership">Ownership</FormLabel>
                <Select {...formik.getFieldProps("ownership")}>
                  <option value="rented">Rented</option>
                  <option value="owned">Owned</option>
                  <option value="other">Other</option>
                </Select>
              </FormControl>
              <Flex my="3" gap="2" justifyContent="space-evenly">
                <FormControl
                  isRequired
                  isInvalid={
                    (!!formik.errors.width && formik.touched.width) as boolean
                  }
                >
                  <FormLabel htmlFor="width">Width</FormLabel>
                  <InputGroup>
                    <Input
                      type="number"
                      min="0"
                      {...formik.getFieldProps("width")}
                    />
                    <InputRightElement>m</InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {formik.errors.width?.toString()}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={
                    (!!formik.errors.length && formik.touched.length) as boolean
                  }
                >
                  <FormLabel htmlFor="length">Length</FormLabel>
                  <InputGroup>
                    <Input
                      type="number"
                      min="0"
                      {...formik.getFieldProps("length")}
                    />
                    <InputRightElement>m</InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {formik.errors.length?.toString()}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={
                    (!!formik.errors.weight && formik.touched.weight) as boolean
                  }
                >
                  <FormLabel htmlFor="weight">Weight</FormLabel>
                  <InputGroup>
                    <Input
                      type="number"
                      min="0"
                      {...formik.getFieldProps("weight")}
                    />
                    <InputRightElement>kg</InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {formik.errors.weight?.toString()}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
            </Box>
          </DrawerBody>
          <DrawerFooter mb="5">
            <Button mr={4} onClick={props.onClose}>
              Close
            </Button>
            <Button mr={4} type="submit" variant="solid" colorScheme="brand">
              {props.mode === FormMode.Add ? "Submit" : "Update"}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};

export default VehicleForm;
