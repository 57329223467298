import { gql } from "urql";

export const GET_OPERATORS_QUERY = gql`
  query GetOperators($userId: Int!) {
    team_members(where: { ownerId: { _eq: $userId } }) {
      teamMember {
        id
        firstName
        lastName
        roles {
          role
        }
        prefs {
          currency
        }
      }
    }
  }
`;

// Since vehicles and implements are paginated, need to pass
// offset field in all such queries for urql to pick up
export const GET_VEHICLES_QUERY = gql`
  query GetVehicles($userId: Int!) {
    vehicles(
      offset: 0
      where: { userId: { _eq: $userId }, status: { _eq: active } }
    ) {
      id
      name
      model
      brand
    }
  }
`;

export const GET_IMPLEMENTS_QUERY = gql`
  query GetImplements($userId: Int!) {
    implements(
      offset: 0
      where: { userId: { _eq: $userId }, status: { _eq: active } }
    ) {
      id
      name
      model
      brand
    }
  }
`;

export const GET_FIELDS_QUERY_COMPACT = gql`
  query GetFieldsCompact($filter: field_season_shot_bool_exp!) {
    field_season_shot(
      order_by: [{ fieldId: asc }, { id: desc }]
      distinct_on: fieldId
      where: $filter
    ) {
      id
      name
      farmId
      polygon {
        id
        size
        geometry
      }
    }
  }
`;

export const GET_OPERATIONS_QUERY = gql`
  query GetOperations($userId: Int!) {
    operations(where: { userId: { _eq: $userId }, status: { _eq: active } }) {
      id
      name
      billingRate
      billingUnit
    }
  }
`;

export const GET_OPERATION_EXTRAS_QUERY = gql`
  query GetOperationExtras($operationId: bigint!) {
    operation_extras(where: { operationId: { _eq: $operationId } }) {
      id
      unit
      value
      name
    }
  }
`;

export const GET_OPERATION_PRODUCTS_QUERY = gql`
  query GetOperationProducts($operationId: bigint!) {
    operation_products(
      where: {
        operationId: { _eq: $operationId }
        product: { status: { _eq: active } }
      }
    ) {
      product {
        id
        name
        chargeableUnit
      }
    }
  }
`;

export const GET_PRODUCTS_QUERY = gql`
  query GetProducts($userId: Int!) {
    products(where: { userId: { _eq: $userId }, status: { _eq: active } }) {
      id
      name
      chargeableUnit
    }
  }
`;

export const ADD_JOB_MUTATION = gql`
  mutation AddJob($job: jobs_insert_input!) {
    insert_jobs_one(object: $job) {
      id
      userId
      operationName
      instructions
      assignedUser {
        id
        firstName
      }
      extras {
        name
        unit
        value
      }
      user {
        id
        profile {
          companyName
          address1
          address2
          city
          state
          country
          postalCode
          phone
        }
      }
      from
      to
      status
      createdAt
      completedAt
      locations {
        id
        notes
        geoLocation
      }
      products {
        productId
        jobId
        locked
        value
        product {
          id
          name
          chargeableUnit
        }
      }
      operators {
        teamLead
        user {
          id
          firstName
        }
        vehicles {
          vehicle {
            id
            name
            model
            brand
          }
        }
        implements {
          implement {
            id
            name
            model
            brand
          }
        }
      }
      fields {
        seasonShot {
          id
          name
          polygon {
            id
            size
            geometry
          }
        }
      }
    }
  }
`;

export interface DeletedOperatorVehicle {
  userId: number;
  vehicleId: string;
}

export interface DeletedOperatorImplement {
  userId: number;
  implementId: string;
}

export const generateUpdateJobMutation = (
  deletedOperatorVehicles: DeletedOperatorVehicle[],
  deletedOperatorImplements: DeletedOperatorImplement[]
) =>
  `mutation UpdateJob(
    $jobId: bigint!
    $updatedJob: jobs_insert_input!
    $removedOperators: [Int!]
    $removedFields: [bigint!]
    $removedExtras: [String!]
    $removedLocations: [bigint!]
    $removedProducts: [uuid!]
  ) {
    ${deletedOperatorVehicles.map(
      (ov) => `
      delete_job_operator_vehicles_by_pk(jobId: $jobId, userId: ${ov.userId}, vehicleId: "${ov.vehicleId}") {vehicleId}
    `
    )}
    ${deletedOperatorImplements.map(
      (oi) => `
      delete_job_operator_implements_by_pk(jobId: $jobId, userId: ${oi.userId}, implementId: "${oi.implementId}") {implementId}
    `
    )}
    delete_job_operators(
      where: { jobId: { _eq: $jobId }, userId: { _in: $removedOperators } }
    ) {
      affected_rows
    }
    delete_job_fields(
      where: { jobId: { _eq: $jobId }, seasonShotId: { _in: $removedFields } }
    ) {
      affected_rows
    }
    delete_job_extras(
      where: { jobId: { _eq: $jobId }, name: { _in: $removedExtras } }
    ) {
      affected_rows
    }
    delete_job_products(
      where: { jobId: { _eq: $jobId }, productId: { _in: $removedProducts } }
    ) {
      affected_rows
    }
    delete_job_locations(where: { id: { _in: $removedLocations } }) {
      affected_rows
    }
    insert_jobs_one(
      object: $updatedJob
      on_conflict: {
        constraint: jobs_pkey
        update_columns: [
          assignedUserId
          operationId
          operationName
          from
          to
          instructions
        ]
      }
    ) {
      id
      userId
      operationName
      instructions
      assignedUser {
        id
        firstName
      }
      extras {
        name
        unit
        value
      }
      from
      to
      status
      createdAt
      completedAt
      locations {
        id
        notes
        geoLocation
      }
      products {
        productId
        jobId
        locked
        value
        product {
          id
          name
          chargeableUnit
        }
      }
      operators {
        teamLead
        user {
          id
          firstName
        }
        vehicles {
          vehicle {
            id
            name
            model
            brand
          }
        }
        implements {
          implement {
            id
            name
            model
            brand
          }
        }
      }
      fields {
        seasonShot {
          id
          name
          polygon {
            id
            size
            geometry
          }
        }
      }
    }
  }
`;

gql`
  mutation UpdateJob(
    $jobId: bigint!
    $updatedJob: jobs_insert_input!
    $removedOperators: [Int!]
    $removedFields: [bigint!]
    $removedExtras: [String!]
    $removedLocations: [bigint!]
    $removedProducts: [uuid!]
  ) {
    delete_job_operators(
      where: { jobId: { _eq: $jobId }, userId: { _in: $removedOperators } }
    ) {
      affected_rows
    }
    delete_job_fields(
      where: { jobId: { _eq: $jobId }, seasonShotId: { _in: $removedFields } }
    ) {
      affected_rows
    }
    delete_job_extras(
      where: { jobId: { _eq: $jobId }, name: { _in: $removedExtras } }
    ) {
      affected_rows
    }
    delete_job_products(
      where: { jobId: { _eq: $jobId }, productId: { _in: $removedProducts } }
    ) {
      affected_rows
    }
    delete_job_locations(where: { id: { _in: $removedLocations } }) {
      affected_rows
    }
    insert_jobs_one(
      object: $updatedJob
      on_conflict: {
        constraint: jobs_pkey
        update_columns: [
          assignedUserId
          operationId
          operationName
          from
          to
          instructions
        ]
      }
    ) {
      id
      userId
      operationName
      instructions
      assignedUser {
        id
        firstName
      }
      extras {
        name
        unit
        value
      }
      from
      to
      status
      createdAt
      completedAt
      locations {
        id
        notes
        geoLocation
      }
      products {
        productId
        jobId
        locked
        value
        product {
          id
          name
          chargeableUnit
        }
      }
      operators {
        teamLead
        user {
          id
          firstName
        }
        vehicles {
          vehicle {
            id
            name
            model
            brand
          }
        }
        implements {
          implement {
            id
            name
            model
            brand
          }
        }
      }
      fields {
        seasonShot {
          id
          name
          polygon {
            id
            size
            geometry
          }
        }
      }
    }
  }
`;

export const UPDATE_JOB_AS_OPERATOR_MUTATION = gql`
  mutation UpdateJobAsOperator(
    $extraUpdates: [job_extras_updates!]!
    $productUpdates: [job_products_updates!]!
  ) {
    update_job_extras_many(updates: $extraUpdates) {
      returning {
        name
        value
        unit
      }
    }

    update_job_products_many(updates: $productUpdates) {
      returning {
        productId
        jobId
        locked
        value
        product {
          id
          name
          chargeableUnit
        }
      }
    }
  }
`;
