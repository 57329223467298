import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  GetMyImplementsQuery,
  GetMyImplementsQueryVariables,
  SaveMyImplementMutation,
  SaveMyImplementMutationVariables,
  UpdateMyImplementMutation,
} from "@farmevo/common/dist/graphql/graphql";
import { GET_MY_IMPLEMENTS } from "@farmevo/web/src/Pages/Implements/implements.graphql";

export const insert_implements_one: UpdateResolver = (result, args, cache) => {
  const variables =
    args.object as SaveMyImplementMutationVariables["saveImplementPayload"];
  const mutationResult = result as SaveMyImplementMutation &
    UpdateMyImplementMutation;

  const queryArgs = cache
    .inspectFields("query_root")
    .filter(
      (field) =>
        field.fieldName === mutationResult.insert_implements_one?.__typename
    )[0].arguments;

  const queryArgsFilter = queryArgs?.where as any;

  cache.updateQuery<GetMyImplementsQuery, GetMyImplementsQueryVariables>(
    {
      query: GET_MY_IMPLEMENTS,
      variables: {
        filter: {
          userId: { _eq: variables.userId || -1 },
          status: { _eq: queryArgsFilter?.status?._eq },
          ...(queryArgsFilter?.name?._similar && {
            name: { _similar: queryArgsFilter?.name?._similar || "" },
          }),
        },
        limit: queryArgs?.limit as number,
        offset: queryArgs?.offset as number,
      },
    },
    (data) => {
      const implementAlreadyExists = data?.implements.find(
        (implement) => implement.id === mutationResult.insert_implements_one?.id
      );
      if (
        (mutationResult.update_implements_by_pk ||
          mutationResult.insert_implements_one) &&
        !implementAlreadyExists
      ) {
        if (mutationResult.update_implements_by_pk)
          data?.implements.push(mutationResult.update_implements_by_pk);
        if (mutationResult.insert_implements_one)
          data?.implements.unshift(mutationResult.insert_implements_one);
      }
      return data;
    }
  );
};
