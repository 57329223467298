import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  Spinner,
  Input,
  Button,
  useToast,
  VStack,
  HStack,
  Select,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import {
  GetFarmsQuery,
  GetFarmsQueryVariables,
  GetMyWarehousesQuery,
  SaveWarehouseMutation,
  SaveWarehouseMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { useMutation, useQuery } from "urql";
import { GET_FARMS_QUERY } from "../Fields/fields.graphql";
import { useSession } from "../../hooks/useSession";
import { SAVE_WAREHOUSE_MUTATION } from "./warehouses.graphql";

const WarehouseForm = (props: {
  selectedWarehouse?: GetMyWarehousesQuery["warehouses"][0] | null;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { session } = useSession();
  const { selectedWarehouse } = props;
  const toast = useToast();

  const [{ data: farmsData, fetching: farmsFetching }] = useQuery<
    GetFarmsQuery,
    GetFarmsQueryVariables
  >({
    query: GET_FARMS_QUERY,
    variables: { userId: session?.id || -1 },
    pause: !session?.id,
  });

  const [{ fetching: savingWarehouse }, saveWarehouse] = useMutation<
    SaveWarehouseMutation,
    SaveWarehouseMutationVariables
  >(SAVE_WAREHOUSE_MUTATION);

  const formik = useFormik({
    initialValues: {
      name: selectedWarehouse?.name || "",
      address: selectedWarehouse?.address || "",
      geoLocation: selectedWarehouse?.geoLocation || "",
      userId: session?.id || "",
      farmId: selectedWarehouse?.farm?.id || "",
    },
    onSubmit: async (values) => {
      const savedWarehouse = await saveWarehouse({
        warehouse: {
          name: values.name,
          address: values.address,
          userId: session?.id,
          farmId: values.farmId ? values.farmId : null,
          ...(values.geoLocation && { geoLocation: values.geoLocation }),
          ...(selectedWarehouse && { id: selectedWarehouse.id }),
        },
      });

      if (savedWarehouse.error) {
        toast({
          status: "error",
          title: "Something went wrong",
          description: "Please try again or contact support",
        });
        return;
      }
      toast({
        status: "success",
        title: `${values.name} ${
          selectedWarehouse ? "saved" : "added"
        } successfully`,
      });
      props.onClose();
      formik.resetForm();
    },
    enableReinitialize: true,
  });

  if (farmsFetching) {
    return <Spinner />;
  }

  return (
    <Drawer size="md" isOpen={props.isOpen} onClose={props.onClose}>
      <DrawerOverlay />
      <DrawerContent overflow="scroll">
        <form onSubmit={formik.handleSubmit}>
          <DrawerHeader>
            {selectedWarehouse
              ? "Edit Warehouse: " + selectedWarehouse.name
              : "Add Warehouse"}
          </DrawerHeader>
          <DrawerBody>
            <VStack>
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder="e.g. Shafiq Warehouse A2"
                  type="text"
                  {...formik.getFieldProps("name")}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Address</FormLabel>
                <Input
                  placeholder="e.g. 14 Sahela district, Gujranwala"
                  type="text"
                  {...formik.getFieldProps("address")}
                />
              </FormControl>
              {/* <FormControl>
                <FormLabel>Location</FormLabel>
                <Button w="full" leftIcon={<IconMapPin />}>
                  Open map and select
                </Button>
              </FormControl> */}
              <FormControl>
                <FormLabel>Farm</FormLabel>
                <Select {...formik.getFieldProps("farmId")}>
                  <option value="">None</option>
                  {farmsData?.farms.map((farm) => (
                    <option key={farm.id} value={farm.id}>
                      {farm.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </VStack>
          </DrawerBody>
          <DrawerFooter as={HStack}>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button
              isLoading={savingWarehouse}
              isDisabled={savingWarehouse}
              type="submit"
              variant="solid"
              colorScheme="brand"
            >
              {selectedWarehouse ? "Save" : "Add"}
            </Button>
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
};

export default WarehouseForm;
