import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  GetMyInventoryQuery,
  GetMyInventoryQueryVariables,
  SaveMyInventoryMutation,
  SaveMyInventoryMutationVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { GET_MY_INVENTORY } from "@farmevo/web/src/Pages/Inventory/inventory.graphql";

export const insert_inventory_one: UpdateResolver = (result, args, cache) => {
  const variables =
    args.object as SaveMyInventoryMutationVariables["saveInventoryPayload"];
  const newInventory = result as SaveMyInventoryMutation;

  const queryArgs = cache
    .inspectFields("query_root")
    .filter(
      (field) =>
        field.fieldName === newInventory.insert_inventory_one?.__typename
    )[0].arguments;

  const queryArgsFilter = queryArgs?.where as any;

  cache.updateQuery<GetMyInventoryQuery, GetMyInventoryQueryVariables>(
    {
      query: GET_MY_INVENTORY,
      variables: {
        filter: {
          userId: { _eq: variables.userId || -1 },
          ...(queryArgsFilter?.productId && {
            productId: { _eq: queryArgsFilter?.productId._eq },
          }),
          ...(queryArgsFilter?.warehouseId && {
            warehouseId: { _eq: queryArgsFilter?.warehouseId._eq },
          }),
          ...(queryArgsFilter?.name?._similar && {
            product: {
              name: { _similar: queryArgsFilter.product.name._similar },
            },
          }),
        },
        limit: queryArgs?.limit as number,
        offset: queryArgs?.offset as number,
      },
    },
    (data) => {
      const inventoryAlreadyExists = data?.inventory.find(
        (inventory) => inventory.id === newInventory.insert_inventory_one?.id
      );
      if (newInventory.insert_inventory_one && !inventoryAlreadyExists) {
        data?.inventory.unshift(newInventory.insert_inventory_one);
      }
      return data;
    }
  );
};
