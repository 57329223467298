import { gql } from "urql";

export const GET_MY_PRODUCTS = gql`
  query GetMyProducts($userId: Int!) {
    products(where: { userId: { _eq: $userId } }) {
      chargeableUnit
      id
      name
      pricePerUnit
      status
      userId
    }
  }
`;

export const GET_MY_PRODUCTS_PAGINATED = gql`
  query GetMyProductsPaginated(
    $filter: products_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    products_aggregate(where: $filter) {
      aggregate {
        count
      }
    }
    products(
      where: $filter
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
    ) {
      chargeableUnit
      id
      name
      pricePerUnit
      status
      userId
    }
  }
`;

export const UPDATE_MY_PRODUCT = gql`
  mutation UpdateMyProduct(
    $updateProductPayload: products_set_input!
    $id: uuid!
  ) {
    update_products_by_pk(
      pk_columns: { id: $id }
      _set: $updateProductPayload
    ) {
      chargeableUnit
      id
      name
      pricePerUnit
      status
      userId
    }
  }
`;

export const SAVE_MY_PRODUCT = gql`
  mutation SaveMyProduct($saveProductPayload: products_insert_input!) {
    insert_products_one(object: $saveProductPayload) {
      chargeableUnit
      id
      name
      pricePerUnit
      status
      userId
    }
  }
`;

export const GET_CHARGEABLE_UNITS = gql`
  query GetChargeableUnits {
    chargeable_units {
      value
      comment
    }
  }
`;

export const UPDATE_MY_PRODUCT_STATUS = gql`
  mutation UpdateMyProductStatus($status: entity_status_enum!, $id: uuid!) {
    update_products_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
      status
      id
    }
  }
`;
