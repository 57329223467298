import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Flex,
  HStack,
  Heading,
  IconButton,
  Input,
  Spinner,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import {
  IconPlus,
  IconChevronUp,
  IconChevronDown,
} from "../../Components/Icons";
import { useSession } from "../../hooks/useSession";
import { useQuery } from "urql";
import { useMemo, useState } from "react";
import {
  GetMyOperationsQuery,
  GetMyOperationsQueryVariables,
  GetMyProductsQuery,
  GetMyProductsQueryVariables,
} from "@farmevo/common/dist/graphql/graphql";
import { GET_MY_PRODUCTS } from "../Products/products.graphql";

import { GET_MY_OPERATIONS } from "./operations.graphql";
import { pluralize } from "../../utils/pluralize";
import { symbols } from "../../constants/currency";
import { OperationModal } from "./OperationModal";

export const DEFAULT_OPERATION_TYPES = [
  "applications",
  "bailing",
  "drilling",
  "foraging",
  "harvesting",
  "haulage",
  "labour",
  "miscellaneous",
  "muck",
  "planting",
  "slurry",
  "spraying",
  "tillage",
];

const OperationRow = (props: {
  type: string;
  products: GetMyProductsQuery["products"];
  subOperations: GetMyOperationsQuery["operations"];
  onClickSubOperation: (
    operation: GetMyOperationsQuery["operations"][0]
  ) => void;
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Card my="5">
      <CardHeader>
        <Flex justifyContent="space-between">
          <Box>
            <Heading size="md">
              {props.type[0].toLocaleUpperCase() + props.type.slice(1)}
            </Heading>
            <Text fontSize="sm">
              {pluralize(props.subOperations.length, "operation")}
            </Text>
          </Box>
          <IconButton
            aria-label="Toggle view"
            icon={isOpen ? <IconChevronUp /> : <IconChevronDown />}
            borderRadius="full"
            onClick={onToggle}
          />
        </Flex>
      </CardHeader>
      <Collapse in={isOpen} animateOpacity>
        <CardBody as={VStack}>
          {props.subOperations.map((op) => (
            <OperationDisplay
              key={op.id}
              operation={op}
              onClick={() => props.onClickSubOperation(op)}
            />
          ))}
        </CardBody>
      </Collapse>
    </Card>
  );
};

const Operations = () => {
  const { session } = useSession();
  const userId = session?.id;
  const [selectedOperation, setSelectedOperation] = useState<
    GetMyOperationsQuery["operations"][0] | null
  >(null);

  const [{ data: operationsData, fetching: loadingOperations }] = useQuery<
    GetMyOperationsQuery,
    GetMyOperationsQueryVariables
  >({
    query: GET_MY_OPERATIONS,
    variables: { userId: userId || -1 },
    pause: !userId,
  });

  const [{ data: productsData, fetching: loadingProducts }] = useQuery<
    GetMyProductsQuery,
    GetMyProductsQueryVariables
  >({
    query: GET_MY_PRODUCTS,
    variables: { userId: userId || -1 },
    pause: !userId,
  });

  const {
    isOpen: isOperationModalOpen,
    onClose: closeOperationModal,
    onOpen: openOperationModal,
  } = useDisclosure({
    onClose: () => setSelectedOperation(null),
  });

  //doing this on the frontend is probably not the best approach,
  //for future reference: https://github.com/hasura/graphql-engine/discussions/3478#discussioncomment-2521254
  const groupedOperations = useMemo(
    () =>
      operationsData?.operations.reduce(
        (ops: Record<string, GetMyOperationsQuery["operations"]>, op) => {
          const type = op["type"];
          ops[type] = Array.prototype.concat(ops[type] || [], op);
          return ops;
        },
        {}
      ),
    [operationsData]
  );

  const operationTypes = useMemo(
    () =>
      Array.from(
        new Set(
          Object.keys(groupedOperations || {}).concat(DEFAULT_OPERATION_TYPES)
        )
      ).sort(),
    [groupedOperations]
  );

  if (loadingOperations || loadingProducts) {
    return <Spinner />;
  }

  return (
    <Box p="2" m="2" mt="7">
      <Text ml="4" fontWeight="bold" fontSize="2xl">
        Operations
      </Text>
      <Flex ml="2" p="2" my="2" justifyContent="space-between">
        <Input placeholder="Search Operations" w="sm" name="search" />
        <Button
          onClick={openOperationModal}
          mr="5"
          colorScheme="brand"
          size="md"
          leftIcon={<IconPlus />}
        >
          Add Operation
        </Button>
      </Flex>
      <Box>
        {groupedOperations ? (
          Object.keys(groupedOperations).map((type) => {
            return (
              <OperationRow
                products={productsData?.products || []}
                key={type}
                type={type}
                subOperations={groupedOperations[type]}
                onClickSubOperation={(
                  operation: GetMyOperationsQuery["operations"][0]
                ) => {
                  setSelectedOperation(operation);
                  openOperationModal();
                }}
              />
            );
          })
        ) : (
          <Text ml="5">
            <b>You don't have any Operations.</b>
            <br />
            Click on the "Add" button to start creating new Operations.{" "}
          </Text>
        )}
      </Box>
      <OperationModal
        operation={selectedOperation}
        operationTypes={operationTypes}
        products={productsData?.products}
        isOpen={isOperationModalOpen}
        onClose={closeOperationModal}
      />
    </Box>
  );
};

const OperationDisplay = ({
  operation,
  onClick,
}: {
  operation: GetMyOperationsQuery["operations"][0];
  onClick: () => void;
}) => {
  const { session } = useSession();

  if (!operation) {
    return null;
  }

  return (
    <Button w="full" justifyContent="start" onClick={onClick}>
      <Text>{operation.name}</Text>
      <HStack ml={4} flex={1} justify="end">
        <Text fontSize="sm" fontWeight="normal">
          {pluralize(operation.products.length, "product")}
        </Text>
        <Text fontSize="sm" fontWeight="normal">
          {session?.user?.prefs?.currency &&
            symbols[session?.user?.prefs?.currency]}
          {operation.billingRate} / {operation.billingUnit}
        </Text>
        <Text fontSize="sm" fontWeight="normal">
          {pluralize(operation.extras.length, "extra")}
        </Text>
      </HStack>
    </Button>
  );
};

export default Operations;
