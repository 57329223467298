import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormControlProps,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { HexColorPicker } from "react-colorful";

type Props = {
  label?: string;
  onChange?: (color: string) => void;
} & FormControlProps;

const ColorPickerField = ({
  label,
  onChange,
  defaultValue,
  ...props
}: Props) => {
  const [color, setColor] = useState((defaultValue as string) || "#F79950");
  return (
    <FormControl {...props}>
      <FormLabel>{label || "Choose color"}</FormLabel>
      <InputGroup>
        <Input id="color" name="color" value={color} readOnly />
        <InputRightElement>
          <Popover placement="left">
            <PopoverTrigger>
              <Button
                p={0}
                h="24px"
                minW="24px"
                bg={color}
                borderRadius="full"
                _hover={{ color }}
              />
            </PopoverTrigger>
            <PopoverContent w="auto" border="none">
              <HexColorPicker
                color={color}
                onChange={(color) => {
                  onChange?.(color);
                  setColor(color);
                }}
              />
            </PopoverContent>
          </Popover>
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};

export default ColorPickerField;
