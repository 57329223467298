const ONE_SQ_KM_IN_ACRES = 247.10538146717;
const ONE_SQ_KM_IN_SQ_M = 1e6;

export const computePolygonAreaInAcres = (polygon: google.maps.Polygon) => {
  return (
    (google.maps.geometry.spherical.computeArea(polygon.getPath()) /
      ONE_SQ_KM_IN_SQ_M) *
    ONE_SQ_KM_IN_ACRES
  );
};

export const toDbPolygon = (polygon: google.maps.Polygon) => {
  return (
    "(" +
    polygon
      .getPath()
      .getArray()
      .map((a) => `(${a.lat()}, ${a.lng()})`)
      .toString() +
    ")"
  );
};

export const fromDbPolygonToPathArray = (geometry: string) => {
  return geometry
    .slice(1, -1)
    .split(/,(?=\()/)
    .map((l) => {
      const [lat, lng] = l.slice(1, -1).split(",").map(Number);
      return new google.maps.LatLng(lat, lng);
    });
};

export const getPolygonCenter = (polygon: google.maps.Polygon) => {
  const bounds = new google.maps.LatLngBounds();
  polygon
    .getPath()
    .getArray()
    .forEach((latLng) => bounds.extend(latLng));
  return bounds.getCenter();
};

export const getPathCenter = (path: google.maps.LatLng[]) => {
  const bounds = new google.maps.LatLngBounds();
  path.forEach((latLng) => bounds.extend(latLng));
  return bounds.getCenter();
};

export const getPolygonBounds = (polygon: google.maps.Polygon) => {
  var bounds = new google.maps.LatLngBounds();
  polygon.getPath().forEach((element, index) => {
    bounds.extend(element);
  });
  return bounds;
};

export const getPathBounds = (path: google.maps.LatLng[]) => {
  var bounds = new google.maps.LatLngBounds();
  path.forEach((element) => {
    bounds.extend(element);
  });
  return bounds;
};
