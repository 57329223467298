import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Progress,
  Select,
  Spinner,
  Table,
  Tag,
  TagCloseButton,
  TagLabel,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  VisuallyHidden,
  useDisclosure,
} from "@chakra-ui/react";
import { GoogleMap } from "@react-google-maps/api";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  IconArrowLeft,
  IconLock,
  IconMapPin,
  IconUnlock,
  IconX,
} from "../../../Components/Icons";
import ComboSelect from "../../../Components/ComboSelect";
import { useSession } from "../../../hooks/useSession";
import { MapWithAutoComplete } from "../../../Components/GoogleMap";
import {
  Chargeable_Units_Enum,
  Job_Status_Enum,
} from "@farmevo/common/dist/graphql/graphql";
import { useJobForm } from "./useJobForm";
import {
  fromDbPolygonToPathArray,
  getPolygonBounds,
  getPolygonCenter,
} from "@farmevo/web/src/utils/polygon";
import { convertLatLngToPgPoint } from "@farmevo/web/src/utils/point";
import { roundToTwo } from "@farmevo/web/src/utils/math";
import { nanoid } from "nanoid";

const AddJob = () => {
  const { session } = useSession();
  const { jobId } = useParams<{ jobId: string }>();
  const navigate = useNavigate();
  const [locationInstructions, setLocationInstructions] = useState("");
  const [locationCoords, setLocationCoords] = useState("");
  const [extraName, setExtraName] = useState("");
  const [extraUnit, setExtraUnit] = useState<Chargeable_Units_Enum>(
    Chargeable_Units_Enum.Acre
  );
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

  const { formValues, ...form } = useJobForm();

  const {
    isOpen: isSelectPinModalOpen,
    onOpen: openSelectPinModal,
    onClose: closeSelectPinModal,
  } = useDisclosure();

  useEffect(() => {
    let map: google.maps.Map;
    if (mapInstance) {
      map = mapInstance;
    } else {
      const mapDiv = document.getElementById("map") as HTMLElement;
      if (!mapDiv) return;
      map = new google.maps.Map(mapDiv, {
        center: session?.user?.prefs?.geoLocation,
        zoom: 14,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        streetViewControl: false,
      });
      setMapInstance(map);
    }

    if (!form.fields) return;

    const _allPolygons = form.fields.map((field) => {
      const polygon = new google.maps.Polygon({
        map,
        paths: fromDbPolygonToPathArray(field.polygon.geometry),
        strokeColor: session?.user?.prefs?.fieldColor,
        fillColor: session?.user?.prefs?.fieldColor,
        fillOpacity: !!formValues.selectedFields.find(
          (selectedFi) => selectedFi.id === field.id
        )
          ? 0.8
          : 0.5,
      });
      polygon.set("name", field.name);
      polygon.set("farmId", field.farmId);
      polygon.addListener("click", () => {
        form.setSelectedFields((prev) => {
          if (!!prev.find((selectedFi) => selectedFi.id === field.id)) {
            return prev.filter((selectedFi) => selectedFi.id !== field.id);
          }
          return [...prev, field];
        });
      });

      return polygon;
    });

    const _allMarkers = _allPolygons.map(
      (polygon) =>
        new google.maps.Marker({
          map,
          icon: "null",
          label: polygon.get("name"),
          position: getPolygonCenter(polygon),
        })
    );

    // Fit map to polygons
    const farmPolygons = _allPolygons.filter(
      (p) =>
        !formValues.selectedFarm ||
        p.get("farmId") === formValues.selectedFarm?.id
    );

    if (farmPolygons.length > 0) {
      const bounds = farmPolygons.reduce(
        (finalBounds, nextPolygon) =>
          finalBounds.union(getPolygonBounds(nextPolygon)),
        new google.maps.LatLngBounds()
      );
      map.fitBounds(bounds, 0);
    }

    return () => {
      _allPolygons.forEach((p) => {
        p.setMap(null);
        google.maps.event.clearInstanceListeners(p);
      });

      _allMarkers.forEach((m) => {
        m.setMap(null);
      });
    };
  }, [
    session,
    mapInstance,
    formValues.selectedFarm,
    formValues.selectedFields,
    form,
  ]);

  const selectedFieldsArea = useMemo(
    () =>
      formValues.selectedFields.reduce(
        (total, fi2) => total + fi2.polygon.size,
        0
      ),
    [formValues.selectedFields]
  );

  const onAddLocation = (e: any) => {
    e.preventDefault();

    form.setLocations((prev) => [
      ...prev,
      {
        temporaryId: nanoid(6),
        geoLocation: locationCoords,
        notes: locationInstructions,
      },
    ]);

    setLocationCoords("");
    setLocationInstructions("");
  };

  const onAddExtra = (e: any) => {
    e.preventDefault();

    form.setExtras((prev) => [
      ...prev,
      {
        id: prev.length + 1,
        name: extraName,
        unit: extraUnit,
        value: "",
      },
    ]);

    setExtraName("");
  };

  const isOperator = !!session?.user?.isOperator;

  if (jobId && form.loadingJob) {
    return (
      <Flex p={10} height="400px" alignItems="center" justify="center">
        <Spinner color="brand.500" />
      </Flex>
    );
  }

  return (
    <VStack p={10} align="unset" spacing={8}>
      <HStack>
        <IconButton
          variant="ghost"
          aria-label="go back"
          icon={<IconArrowLeft />}
          onClick={() => navigate(-1)}
        />
        <Text fontWeight="bold" fontSize="2xl">
          {jobId ? `Job#${jobId} - Edit` : "Add a Job"}
        </Text>
      </HStack>
      {!isOperator && (
        <HStack spacing={4}>
          <ComboSelect
            items={form.contractors}
            itemsFilter={(v) => (item) =>
              item.firstName
                .toLocaleLowerCase()
                .includes(v.toLocaleLowerCase()) ||
              !!item.lastName
                ?.toLocaleLowerCase()
                .includes(v.toLocaleLowerCase())}
            itemToString={(item) =>
              item
                ? `${item?.firstName}${
                    item?.lastName ? " " + item?.lastName : ""
                  }${session?.id === item.id ? " (You)" : ""}`
                : ""
            }
            label="Contractor"
            searchBehavior="reduce"
            formControlProps={{
              isRequired: true,
              isInvalid: !!form.errors.selectedContractor,
              isDisabled:
                !!form.job &&
                form.job?.status !== Job_Status_Enum.Ready &&
                form.job?.status !== Job_Status_Enum.Pending,
            }}
            labelProps={{ htmlFor: "contractor" }}
            inputProps={{
              id: "contractor",
              name: "contractor",
              placeholder: "Select contractor",
            }}
            defaultSelectedItem={formValues.selectedContractor}
            onSelectItem={(item) => {
              form.setSelectedContractor(item || null);
              form.setSelectedOperation(null);
            }}
            renderItem={(item) => (
              <span>
                {item?.firstName} {item?.lastName}
                {item?.id === session?.id && " (You)"}
              </span>
            )}
            menuProps={{ maxH: "200px" }}
            renderEmptyState={() =>
              form.loadingOperators && (
                <Progress
                  mt="-1px"
                  position="absolute"
                  width="calc(100% - 10px)"
                  ml="5px"
                  size="xs"
                  height="1.25px"
                  bgColor="unset"
                  colorScheme="brand"
                  isIndeterminate
                />
              )
            }
          />
          <ComboSelect
            items={form.operations}
            itemsFilter={(v) => (operation) =>
              operation.name.toLowerCase().includes(v.toLowerCase())}
            itemToString={(operation) => operation?.name || ""}
            label="Operation"
            searchBehavior="reduce"
            formControlProps={{
              isRequired: true,
              isInvalid: !!form.errors.selectedOperation,
              isDisabled: !formValues.selectedContractor,
            }}
            labelProps={{ htmlFor: "operation" }}
            inputProps={{
              id: "operation",
              name: "operation",
              placeholder: "Select operation",
            }}
            defaultSelectedItem={formValues.selectedOperation}
            onSelectItem={(operation) =>
              form.setSelectedOperation(operation || null)
            }
            renderItem={(item) => (
              <HStack justify="space-between">
                <span>{item?.name}</span>
                <Text as="span" color="gray.500">
                  {item?.billingRate} {formValues.selectedContractor?.currency}{" "}
                  / {item?.billingUnit}
                </Text>
              </HStack>
            )}
            menuProps={{ maxH: "200px" }}
            renderEmptyState={() =>
              form.loadingOperations && (
                <Progress
                  mt="-1px"
                  position="absolute"
                  width="calc(100% - 10px)"
                  ml="5px"
                  size="xs"
                  height="1.25px"
                  bgColor="unset"
                  colorScheme="brand"
                  isIndeterminate
                />
              )
            }
          />
        </HStack>
      )}
      {!isOperator && (
        <HStack spacing={4}>
          <FormControl>
            <FormLabel>From</FormLabel>
            <Input
              type="datetime-local"
              value={formValues.from || ""}
              onChange={(e) => form.setFrom(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>To</FormLabel>
            <Input
              type="datetime-local"
              value={formValues.to || ""}
              onChange={(e) => form.setTo(e.target.value)}
            />
          </FormControl>
        </HStack>
      )}
      {!isOperator && (
        <FormControl>
          <FormLabel>Instructions</FormLabel>
          <Textarea
            value={formValues.instructions || ""}
            onChange={(e) => form.setInstructions(e.target.value)}
          ></Textarea>
          <FormHelperText>
            Provide instructions for the operators on the job
          </FormHelperText>
        </FormControl>
      )}
      {!isOperator && formValues.selectedContractor?.id === session?.id && (
        <VStack>
          <FormControl>
            <FormLabel>Operators</FormLabel>
            <FormHelperText mb={2}>
              Select people for the job. Leave blank to keep it unassigned.
            </FormHelperText>
            <ComboSelect
              items={form.operators}
              itemsFilter={(v) => (item) =>
                item.firstName.toLowerCase().includes(v.toLowerCase()) ||
                !!item.lastName?.toLowerCase().includes(v.toLowerCase())}
              label=""
              searchBehavior="reduce"
              formControlProps={{ isRequired: true }}
              labelProps={{ htmlFor: "operator", style: { display: "none" } }}
              inputProps={{
                id: "operator",
                name: "operator",
                placeholder: "Select operator",
              }}
              // defaultSelectedItem={selectedSeason}
              onSelectItem={(item) => {
                form.setSelectedOperators((prev) => {
                  if (item && !prev.find((op) => op.id === item?.id)) {
                    const newItem: (typeof prev)[0] = {
                      ...item,
                      teamLead: false,
                      vehicles: [],
                      implements: [],
                    };
                    return [...prev, newItem];
                  }
                  return prev;
                });
              }}
              renderItem={(item) =>
                item && (
                  <Text>
                    {item?.firstName} {item?.lastName}{" "}
                    {item.id === session?.id && " (You)"}
                  </Text>
                )
              }
              menuProps={{ maxH: "200px" }}
              renderEmptyState={() =>
                form.loadingOperators && (
                  <Progress
                    mt="-1px"
                    position="absolute"
                    width="calc(100% - 10px)"
                    ml="5px"
                    size="xs"
                    height="1.25px"
                    bgColor="unset"
                    colorScheme="brand"
                    isIndeterminate
                  />
                )
              }
            />
          </FormControl>
          {formValues.selectedOperators.length > 0 && (
            <Table layout="fixed">
              <Thead>
                <Tr>
                  <Th w="200px">Operator</Th>
                  <Th w="140px">Team Lead</Th>
                  <Th width="auto">Vehicle</Th>
                  <Th width="auto">Implement</Th>
                  <Th w="80px">
                    <Text as={VisuallyHidden}>Actions</Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {formValues.selectedOperators.map((operator) => (
                  <Tr key={operator.id}>
                    <Td verticalAlign="baseline">
                      <HStack>
                        <Avatar name={operator.firstName} size="sm" />
                        <Text>{operator.firstName}</Text>
                        {operator.id === session?.id && <Text>(You)</Text>}
                      </HStack>
                    </Td>
                    <Td verticalAlign="baseline">
                      <Checkbox
                        isChecked={operator.teamLead}
                        onChange={(e) =>
                          form.setSelectedOperators((prev) => {
                            if (operator.teamLead === e.target.checked)
                              return prev;

                            const opIndex = prev.findIndex(
                              (op) => op.id === operator.id
                            );
                            prev[opIndex] = {
                              ...operator,
                              teamLead: e.target.checked,
                            };
                            return prev.slice();
                          })
                        }
                      />
                    </Td>
                    <Td verticalAlign="baseline">
                      <ComboSelect
                        items={form.vehicles?.filter(
                          (v) => !operator.vehicles.find((ov) => ov.id === v.id)
                        )}
                        itemsFilter={(v) => (vehicle) =>
                          vehicle.name
                            .toLocaleLowerCase()
                            .includes(v.toLowerCase()) ||
                          !!vehicle.model
                            ?.toLowerCase()
                            .includes(v.toLowerCase()) ||
                          !!vehicle.brand
                            ?.toLowerCase()
                            .includes(v.toLowerCase())}
                        label=""
                        searchBehavior="reduce"
                        formControlProps={{ isRequired: true }}
                        labelProps={{
                          htmlFor: "vehicle",
                          style: { display: "none" },
                        }}
                        inputProps={{
                          id: "vehicle",
                          name: "vehicle",
                          placeholder: "Select vehicle",
                        }}
                        onSelectItem={(vehicle) =>
                          form.setSelectedOperators((prev) => {
                            if (
                              !vehicle ||
                              !!operator.vehicles.find(
                                (v) => v.id === vehicle.id
                              )
                            ) {
                              return prev;
                            }

                            const opIndex = prev.findIndex(
                              (op) => op.id === operator.id
                            );
                            prev[opIndex] = {
                              ...operator,
                              vehicles: [...(operator.vehicles || []), vehicle],
                            };
                            return prev.slice();
                          })
                        }
                        renderItem={(item) => (
                          <span>
                            {item?.brand} {item?.name} {item?.model}
                          </span>
                        )}
                        menuProps={{ maxH: "200px" }}
                        renderEmptyState={() =>
                          form.loadingVehicles && (
                            <Progress
                              mt="-1px"
                              position="absolute"
                              width="calc(100% - 10px)"
                              ml="5px"
                              size="xs"
                              height="1.25px"
                              bgColor="unset"
                              colorScheme="brand"
                              isIndeterminate
                            />
                          )
                        }
                      />
                      <Grid mt={1} gridGap={1}>
                        {operator.vehicles.map((vehicle) => (
                          <GridItem key={vehicle.id}>
                            <Tag>
                              <TagLabel>
                                {vehicle.brand || vehicle.name} {vehicle.model}
                              </TagLabel>
                              <TagCloseButton
                                onClick={() =>
                                  form.setSelectedOperators((prev) => {
                                    if (
                                      !operator.vehicles.find(
                                        (v) => v.id === vehicle.id
                                      )
                                    ) {
                                      return prev;
                                    }
                                    const opIndex = prev.findIndex(
                                      (op) => op.id === operator.id
                                    );
                                    prev[opIndex] = {
                                      ...operator,
                                      vehicles: operator.vehicles.filter(
                                        (v) => v.id !== vehicle.id
                                      ),
                                    };
                                    return prev.slice();
                                  })
                                }
                              />
                            </Tag>
                          </GridItem>
                        ))}
                      </Grid>
                    </Td>
                    <Td verticalAlign="baseline">
                      <ComboSelect
                        items={form.implements?.filter(
                          (im) =>
                            !operator.implements.find((oim) => oim.id === im.id)
                        )}
                        itemsFilter={(v) => (implement) =>
                          implement.name
                            .toLocaleLowerCase()
                            .includes(v.toLowerCase()) ||
                          !!implement.model
                            ?.toLowerCase()
                            .includes(v.toLowerCase()) ||
                          !!implement.brand
                            ?.toLowerCase()
                            .includes(v.toLowerCase())}
                        label=""
                        searchBehavior="reduce"
                        formControlProps={{ isRequired: true }}
                        labelProps={{
                          htmlFor: "implement",
                          style: { display: "none" },
                        }}
                        inputProps={{
                          id: "implement",
                          name: "implement",
                          placeholder: "Select implement",
                        }}
                        // defaultSelectedItem={selectedSeason}
                        onSelectItem={(implement) =>
                          form.setSelectedOperators((prev) => {
                            if (
                              !implement ||
                              !!operator.implements.find(
                                (im) => im.id === implement.id
                              )
                            ) {
                              return prev;
                            }

                            const opIndex = prev.findIndex(
                              (op) => op.id === operator.id
                            );
                            prev[opIndex] = {
                              ...operator,
                              implements: [
                                ...(operator.implements || []),
                                implement,
                              ],
                            };
                            return prev.slice();
                          })
                        }
                        renderItem={(item) => (
                          <span>
                            {item?.brand} {item?.name} {item?.model}
                          </span>
                        )}
                        menuProps={{ maxH: "200px" }}
                        renderEmptyState={() =>
                          form.loadingImplements && (
                            <Progress
                              mt="-1px"
                              position="absolute"
                              width="calc(100% - 10px)"
                              ml="5px"
                              size="xs"
                              height="1.25px"
                              bgColor="unset"
                              colorScheme="brand"
                              isIndeterminate
                            />
                          )
                        }
                      />
                      <Grid mt={1} gridGap={1}>
                        {operator.implements.map((implement) => (
                          <GridItem key={implement.id}>
                            <Tag>
                              <TagLabel>
                                {implement.brand || implement.name}{" "}
                                {implement.model}
                              </TagLabel>
                              <TagCloseButton
                                onClick={() =>
                                  form.setSelectedOperators((prev) => {
                                    if (
                                      !operator.implements.find(
                                        (im) => im.id === implement.id
                                      )
                                    ) {
                                      return prev;
                                    }
                                    const opIndex = prev.findIndex(
                                      (op) => op.id === operator.id
                                    );
                                    prev[opIndex] = {
                                      ...operator,
                                      implements: operator.implements.filter(
                                        (im) => im.id !== implement.id
                                      ),
                                    };
                                    return prev.slice();
                                  })
                                }
                              />
                            </Tag>
                          </GridItem>
                        ))}
                      </Grid>
                    </Td>
                    <Td verticalAlign="baseline">
                      <IconButton
                        aria-label="remove job operator"
                        variant="ghost"
                        icon={<IconX />}
                        onClick={() => {
                          form.setSelectedOperators((prev) =>
                            prev.filter((op) => operator.id !== op.id)
                          );
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </VStack>
      )}
      {!isOperator && (
        <VStack align="unset" id="fields-container">
          <FormControl>
            <FormLabel>Fields</FormLabel>
            <FormHelperText mb={2}>
              Select fields from the map or drop-down list
            </FormHelperText>
            <HStack>
              <ComboSelect
                items={form.farms}
                itemsFilter={(v) => (farm) =>
                  !!farm.name?.toLowerCase()?.includes(v.toLowerCase())}
                label=""
                itemToString={(item) => item?.name || ""}
                searchBehavior="reduce"
                formControlProps={{ isRequired: true }}
                labelProps={{
                  style: { display: "none" },
                }}
                inputProps={{
                  id: "farm",
                  name: "farm",
                  placeholder: "Filter by farm",
                }}
                onSelectItem={(item) => form.setSelectedFarm(item || null)}
                renderItem={(item) => <span>{item?.name}</span>}
                menuProps={{ maxH: "200px" }}
                defaultSelectedItem={formValues.selectedFarm}
                renderEmptyState={() =>
                  form.loadingFarms && (
                    <Progress
                      mt="-1px"
                      position="absolute"
                      width="calc(100% - 10px)"
                      ml="5px"
                      size="xs"
                      height="1.25px"
                      bgColor="unset"
                      colorScheme="brand"
                      isIndeterminate
                    />
                  )
                }
              />
              <ComboSelect
                items={form.fields?.filter(
                  (field) =>
                    !formValues.selectedFields.find((fi) => fi.id === field.id)
                )}
                itemsFilter={(v) => (field) =>
                  !!field.name?.toLowerCase()?.includes(v.toLowerCase())}
                label=""
                searchBehavior="reduce"
                formControlProps={{ isRequired: true }}
                labelProps={{
                  style: { display: "none" },
                }}
                inputProps={{
                  id: "field",
                  name: "field",
                  placeholder: "Select field",
                }}
                onSelectItem={(item) => {
                  form.setSelectedFields((prev) => {
                    if (!item || !!prev.find((field) => field.id === item.id)) {
                      return prev;
                    }
                    return [...prev, item];
                  });
                  if (formValues.selectedFields.length === 0) {
                    document
                      .getElementById("fields-container")
                      ?.scrollIntoView({ block: "start" });
                  }
                }}
                renderItem={(item) => <span>{item?.name}</span>}
                menuProps={{ maxH: "200px" }}
                renderEmptyState={() =>
                  form.loadingFields && (
                    <Progress
                      mt="-1px"
                      position="absolute"
                      width="calc(100% - 10px)"
                      ml="5px"
                      size="xs"
                      height="1.25px"
                      bgColor="unset"
                      colorScheme="brand"
                      isIndeterminate
                    />
                  )
                }
              />
            </HStack>
          </FormControl>
          <GoogleMap
            mapContainerStyle={{
              height: "450px",
            }}
            id="map"
          />
          <Text textAlign="right">
            Selected area: {roundToTwo(selectedFieldsArea)} acres
          </Text>
          {formValues.selectedFields.length > 0 && (
            <Table>
              <Thead>
                <Tr>
                  <Th>Field</Th>
                  <Th>
                    <Text as={VisuallyHidden}>Actions</Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {formValues.selectedFields.map((field) => (
                  <Tr key={field.id}>
                    <Td>
                      <HStack>
                        <Text>{field.name}</Text>
                        <Text color="gray.500">
                          ({roundToTwo(field.polygon.size)} acres)
                        </Text>
                      </HStack>
                    </Td>
                    <Td width="1%" whiteSpace="nowrap">
                      <IconButton
                        aria-label="remove field"
                        variant="ghost"
                        icon={<IconX />}
                        onClick={() =>
                          form.setSelectedFields((prev) =>
                            prev.filter((fi) => fi.id !== field.id)
                          )
                        }
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </VStack>
      )}
      <VStack align="unset">
        <form onSubmit={onAddExtra}>
          <VStack align="unset">
            <FormControl>
              <FormLabel>Extra information</FormLabel>
              {!isOperator && (
                <FormHelperText>
                  Add any extra information required for the job
                </FormHelperText>
              )}
            </FormControl>
            {!isOperator && (
              <HStack align="end">
                <FormControl isRequired>
                  <FormLabel color="gray.500" fontSize="sm">
                    Extra name
                  </FormLabel>
                  <Input
                    placeholder="e.g. Imaging area"
                    value={extraName}
                    onChange={(e) => setExtraName(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel color="gray.500" fontSize="sm">
                    Select unit
                  </FormLabel>
                  <Select
                    value={extraUnit}
                    onChange={(e) =>
                      setExtraUnit(e.target.value as Chargeable_Units_Enum)
                    }
                  >
                    {Object.values(Chargeable_Units_Enum).map((unit) => (
                      <option key={unit} value={unit}>
                        {unit.replaceAll("_", " ")}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <Button type="submit">Add</Button>
              </HStack>
            )}
          </VStack>
        </form>
        {formValues.extras.length > 0 && (
          <Table>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Value</Th>
                <Th>Unit</Th>
                {!isOperator && (
                  <Th width="1%" whiteSpace="nowrap">
                    <Text as={VisuallyHidden}>Actions</Text>
                  </Th>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {formValues.extras.map((extra) => (
                <Tr key={extra.id}>
                  <Td>{extra.name}</Td>
                  <Td>
                    <Input
                      placeholder="value"
                      value={extra.value}
                      onChange={(e) =>
                        form.setExtras((prev) => {
                          const extraIndex = prev.findIndex(
                            (ex) => ex.id === extra.id
                          );
                          prev[extraIndex] = {
                            ...extra,
                            value: e.target.value,
                          };
                          return prev.slice();
                        })
                      }
                    />
                  </Td>
                  <Td>
                    <Select
                      isDisabled={isOperator}
                      value={extra.unit || Chargeable_Units_Enum.Acre}
                      onChange={(e) =>
                        form.setExtras((prev) => {
                          const extraIndex = prev.findIndex(
                            (ex) => ex.id === extra.id
                          );
                          prev[extraIndex] = {
                            ...extra,
                            unit: e.target.value as Chargeable_Units_Enum,
                          };
                          return prev.slice();
                        })
                      }
                    >
                      {Object.values(Chargeable_Units_Enum).map((unit) => (
                        <option key={unit} value={unit}>
                          {unit.replaceAll("_", " ")}
                        </option>
                      ))}
                    </Select>
                  </Td>
                  {!isOperator && (
                    <Td>
                      <IconButton
                        aria-label="remove extra"
                        variant="ghost"
                        icon={<IconX />}
                        onClick={() =>
                          form.setExtras((prev) =>
                            prev.filter((ex) => ex.id !== extra.id)
                          )
                        }
                      />
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </VStack>
      {(isOperator || formValues.selectedContractor?.id === session?.id) && (
        <VStack align="unset">
          <FormControl>
            <FormLabel>Products</FormLabel>
            {!isOperator && (
              <>
                <FormHelperText mb={2}>
                  Select products to be added on the job.
                </FormHelperText>
                <ComboSelect
                  items={form.products?.filter(
                    (opProduct) =>
                      !formValues.selectedProducts.find(
                        (selectedProduct) => opProduct.id === selectedProduct.id
                      )
                  )}
                  itemsFilter={(v) => (product) =>
                    product.name.toLowerCase().includes(v.toLowerCase())}
                  itemToString={(product) => product?.name || ""}
                  label=""
                  searchBehavior="reduce"
                  formControlProps={{ isRequired: true }}
                  labelProps={{
                    htmlFor: "product",
                    style: { display: "none" },
                  }}
                  inputProps={{
                    id: "product",
                    name: "product",
                    placeholder: "Select product",
                  }}
                  // defaultSelectedItem={selectedSeason}
                  onSelectItem={(item) =>
                    form.setSelectedProducts((prev) => {
                      if (
                        !item ||
                        !!prev.find((opProduct) => opProduct.id === item.id)
                      ) {
                        return prev;
                      }
                      return [...prev, item];
                    })
                  }
                  renderItem={(item) => <span>{item?.name}</span>}
                  menuProps={{ maxH: "200px" }}
                  renderEmptyState={() =>
                    form.loadingProducts && (
                      <Progress
                        mt="-1px"
                        position="absolute"
                        width="calc(100% - 10px)"
                        ml="5px"
                        size="xs"
                        height="1.25px"
                        bgColor="unset"
                        colorScheme="brand"
                        isIndeterminate
                      />
                    )
                  }
                />
              </>
            )}
          </FormControl>
          {formValues.selectedProducts.length > 0 && (
            <Table layout="fixed">
              <Thead>
                <Tr>
                  <Th width="150px">Product</Th>
                  <Th>Value</Th>
                  <Th>Unit</Th>
                  {!isOperator && (
                    <Th width="190px">
                      <Text as={VisuallyHidden}>Actions</Text>
                    </Th>
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {formValues.selectedProducts.map((opProduct) => (
                  <Tr key={opProduct.id}>
                    <Td>
                      <Text>{opProduct.name}</Text>
                    </Td>
                    <Td>
                      <FormControl>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="value"
                            value={opProduct.value}
                            onChange={(e) =>
                              form.setSelectedProducts((prev) => {
                                if (e.target.value === opProduct.value) {
                                  return prev;
                                }

                                const opIndex = prev.findIndex(
                                  (op) => op.id === opProduct.id
                                );

                                prev[opIndex] = {
                                  ...prev[opIndex],
                                  value: e.target.value,
                                };
                                return prev.slice();
                              })
                            }
                            isDisabled={opProduct.locked}
                          />
                          {opProduct.locked && (
                            <InputRightElement>
                              <Tooltip
                                label="Operators cannot edit it during job"
                                aria-label="edit info"
                                hasArrow
                              >
                                <IconLock size="18px" opacity={0.5} />
                              </Tooltip>
                            </InputRightElement>
                          )}
                        </InputGroup>
                      </FormControl>
                    </Td>
                    <Td>
                      <Text>{opProduct.chargeableUnit}</Text>
                    </Td>
                    {!isOperator && (
                      <Td width="1%" whiteSpace="nowrap">
                        <HStack justify="end">
                          <Button
                            size="sm"
                            leftIcon={
                              opProduct.locked ? (
                                <IconUnlock size="18px" />
                              ) : (
                                <IconLock size="18px" />
                              )
                            }
                            onClick={() =>
                              form.setSelectedProducts((prev) => {
                                const opIndex = prev.findIndex(
                                  (op) => op.id === opProduct.id
                                );

                                if (
                                  prev[opIndex].locked === !opProduct.locked
                                ) {
                                  return prev;
                                }

                                prev[opIndex] = {
                                  ...prev[opIndex],
                                  locked: !prev[opIndex].locked,
                                };
                                return prev.slice();
                              })
                            }
                          >
                            {opProduct.locked ? "Unlock" : "Lock"}
                          </Button>
                          <IconButton
                            aria-label="remove product"
                            variant="ghost"
                            icon={<IconX />}
                            onClick={() =>
                              form.setSelectedProducts((prev) =>
                                prev.filter((op) => op.id !== opProduct.id)
                              )
                            }
                          />
                        </HStack>
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </VStack>
      )}
      {!isOperator && (
        <VStack align="unset">
          <FormControl>
            <FormLabel>Locations</FormLabel>
            <FormHelperText>Direct operators to locations</FormHelperText>
          </FormControl>
          <form onSubmit={onAddLocation}>
            <HStack align="end">
              <FormControl isRequired>
                <FormLabel fontSize="sm" color="gray.500">
                  Address or instructions
                </FormLabel>
                <Textarea
                  rows={1}
                  placeholder="e.g. Plot # 42B"
                  value={locationInstructions}
                  onChange={(e) => setLocationInstructions(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize="sm" color="gray.500">
                  Pin location
                </FormLabel>
                <InputGroup>
                  <Input
                    placeholder="(lat, lng)"
                    value={locationCoords}
                    autoComplete="off"
                    onChange={(e) => setLocationCoords(e.target.value)}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label="open map"
                      size="sm"
                      icon={<IconMapPin size="18px" />}
                      onClick={openSelectPinModal}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button type="submit">Add</Button>
            </HStack>
          </form>
          {!isOperator && formValues.locations.length > 0 && (
            <VStack maxW="50%" py={6} spacing={4}>
              {formValues.locations.map((loc, i) => (
                <Box key={loc.id || loc.temporaryId} w="full">
                  <HStack align={loc.notes ? "start" : "center"}>
                    <Text px={4} py={2} color="gray.600" bgColor="brand.200">
                      {i + 1}
                    </Text>
                    <VStack spacing={1} flex="1" align="unset">
                      {loc.geoLocation ? (
                        <Text>Dropped Pin at {loc.geoLocation}</Text>
                      ) : (
                        <Text>Address</Text>
                      )}
                      {loc.notes && (
                        <Text fontSize="sm" color="gray.500">
                          {loc.notes}
                        </Text>
                      )}
                    </VStack>
                    <IconButton
                      aria-label="remove location"
                      variant="ghost"
                      icon={<IconX />}
                      onClick={() =>
                        form.setLocations((prev) =>
                          prev.filter((l) => l.id !== loc.id)
                        )
                      }
                    />
                  </HStack>
                  <Divider />
                </Box>
              ))}
            </VStack>
          )}
        </VStack>
      )}
      <Box
        py={6}
        pr={10}
        textAlign="right"
        position="sticky"
        bottom="0"
        bgColor="white"
        boxShadow="outerTop.md"
        style={{ marginRight: "-2.5rem", marginLeft: "-2.5rem" }}
      >
        <Button
          size="lg"
          onClick={form.submit}
          isLoading={form.submitting}
          isDisabled={form.submitting}
        >
          {jobId ? "Save" : "Add Job"}
        </Button>
      </Box>
      <Portal>
        <SelectPinModal
          isOpen={isSelectPinModalOpen}
          onClose={closeSelectPinModal}
          onSelectLocation={(coords) => setLocationCoords(coords)}
        />
      </Portal>
    </VStack>
  );
};

const SelectPinModal = (props: {
  isOpen: boolean;
  onClose: () => void;
  onSelectLocation: (coords: string) => void;
}) => {
  const { session } = useSession();
  const [coords, setCoords] = useState(
    () => convertLatLngToPgPoint(session?.user?.prefs?.geoLocation) || ""
  );

  const geoLocation = coords && {
    lat: Number(coords.split(",")[0].slice(1)),
    lng: Number(coords.split(",")[1].slice(0, -1)),
  };

  return (
    <Modal {...props} size="xl">
      <ModalOverlay />
      <ModalContent zIndex={2}>
        <ModalHeader>
          <Text>Select a location</Text>
          <Text fontWeight="normal" fontSize="sm">
            Drag the red marker{" "}
            <IconMapPin size={16} style={{ display: "inline" }} /> to set the
            location
          </Text>
        </ModalHeader>
        <ModalBody>
          <MapWithAutoComplete
            onChangeLocation={(_, fieldValue) => setCoords(fieldValue)}
            defaultLocation={geoLocation || session?.user?.prefs?.geoLocation}
            suggestionsContainerProps={{
              portal: false,
              style: {
                maxWidth: "100%",
                position: "absolute",
                top: "56px",
                left: "16px",
              },
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              props.onSelectLocation(coords);
              props.onClose();
            }}
          >
            Select
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddJob;
