import { Box, HStack, Text, Tooltip, VStack } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import {
  IconBriefcase,
  IconCalendar,
  IconFeather,
  IconGitlab,
  IconGrid,
  IconHome,
  IconOctagon,
  IconPackage,
  IconSettings,
  IconTool,
  IconTruck,
  IconUsers,
} from "./Icons";
import { useSession } from "../hooks/useSession";
import { useMemo } from "react";

const navLinks = [
  { path: "/jobs", label: "Jobs", icon: IconBriefcase },
  { path: "/fields", label: "Fields", icon: IconFeather },
  { path: "/operations", label: "Operations", icon: IconSettings },
  { path: "/timesheets", label: "Timesheets", icon: IconCalendar },
  { path: "/members", label: "Team Members", icon: IconUsers },
  { path: "/contractors", label: "Contractors", icon: IconTool },
  { path: "/customers", label: "Customers", icon: IconGitlab },
  { path: "/vehicles", label: "Vehicles", icon: IconTruck },
  { path: "/implements", label: "Implements", icon: IconOctagon },
  { path: "/products", label: "Products", icon: IconPackage },
  { path: "/inventory", label: "Inventory", icon: IconGrid },
  { path: "/warehouses", label: "Warehouses", icon: IconHome },
];

const SideNavItem = ({
  to,
  title,
  iconLeft,
  isActive,
  isMobile,
}: {
  to: string;
  title: string;
  iconLeft?: React.ReactNode;
  isActive?: boolean;
  isMobile?: boolean;
}) => (
  <Tooltip hasArrow isDisabled={!isMobile} placement="right" label={title}>
    <HStack
      as={Link}
      to={to}
      py={2}
      px={4}
      spacing={3}
      color={isActive ? "white" : "gray.500"}
      borderRadius="lg"
      fontWeight="600"
      bgColor={isActive ? "brand.500" : ""}
      _hover={{
        textDecoration: "none",
        backgroundColor: !isActive && "brand.100",
      }}
    >
      {iconLeft}
      {!isMobile && <Text>{title}</Text>}
    </HStack>
  </Tooltip>
);

type SideNavProps = {
  isOpen?: boolean;
};

const SideNav = ({ isOpen }: SideNavProps) => {
  const { session } = useSession();
  const { pathname } = useLocation();
  const isOperator = session?.user?.isOperator;
  const isContractor = session?.user?.isContractor;
  const filteredNavLinks = useMemo(() => {
    if (isOperator) {
      return navLinks.filter((link) => ["/jobs"].includes(link.path));
    }
    if (isContractor) {
      return navLinks.filter((link) => link.path !== "/contractors");
    }
    return navLinks.filter((link) => link.path !== "/customers");
  }, [isOperator, isContractor]);

  return (
    <Box
      h="calc(100vh - 64px)"
      minWidth={isOpen ? "280px" : "70px"}
      px={2}
      py={10}
      borderRight="1px"
      borderColor="gray.100"
      position="sticky"
      top="64px"
    >
      <VStack spacing={1} align="unset" maxH="90%" overflowY="auto">
        {filteredNavLinks.map((navLink) => {
          const IconComponent = navLink.icon;
          return (
            <SideNavItem
              key={navLink.label}
              title={navLink.label}
              to={navLink.path}
              iconLeft={<IconComponent />}
              isActive={pathname.startsWith(navLink.path)}
              isMobile={!isOpen}
            />
          );
        })}
      </VStack>
    </Box>
  );
};

export default SideNav;
