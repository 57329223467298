import {
  Box,
  HStack,
  Link,
  PinInput,
  PinInputField,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import LayoutOnboarding from "../../Components/LayoutOnboarding";
import { useSession } from "../../hooks/useSession";
import { useApi } from "../../hooks/useApi";

type Props = {};

const VerifyEmail = (props: Props) => {
  const [verifyingOtp, setVerifyingOtp] = useState(false);
  const [verifyError, setVerifyError] = useState("");
  const { setIsEmailVerified } = useSession();
  const api = useApi();
  const resendOtpEmail = async () => {
    setVerifyError("");
    try {
      const response = await api.post("auth/resend-otp");
      if (response.status === 304) {
        setIsEmailVerified();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verifyOtp = async (otp: string) => {
    setVerifyingOtp(true);
    setVerifyError("");
    try {
      const response = await api.post("auth/verify-otp", { otp });
      if (response.status !== 200) {
        setVerifyError("Invalid or Expired OTP");
      } else {
        setIsEmailVerified();
      }
    } catch (error) {
      setVerifyError("An unknown error occurred");
    } finally {
      setVerifyingOtp(false);
    }
  };

  const resetError = () => {
    setVerifyError("");
    setVerifyingOtp(false);
  };

  return (
    <LayoutOnboarding
      title={`Verify your email`}
      subtitle="Check your inbox. We just sent you an email with a 4-digit code (OTP)"
      my={20}
    >
      <VStack flex="1" spacing={10}>
        <VStack>
          <HStack>
            <PinInput
              isDisabled={verifyingOtp}
              size="lg"
              onComplete={verifyOtp}
              onChange={resetError}
              otp
            >
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
          {verifyingOtp && (
            <Text fontSize="sm" fontStyle="italic" color="gray.500">
              Verifying OTP...
            </Text>
          )}
          {verifyError && !verifyingOtp && (
            <Text fontSize="sm" color="red.500">
              {verifyError}
            </Text>
          )}
        </VStack>
        <Box>
          Didn't receive any code?{" "}
          <Link colorScheme="brand" onClick={resendOtpEmail}>
            Resend Email
          </Link>
        </Box>
      </VStack>
    </LayoutOnboarding>
  );
};

export default VerifyEmail;
