import {
  Box,
  HStack,
  Link,
  SkeletonText,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "urql";
import { Link as RouteLink } from "react-router-dom";
import { FieldShot, GET_SNAPSHOT_REPORTS_QUERY } from "./fields.graphql";
import {
  GetSnapshotReportsQuery,
  GetSnapshotReportsQueryVariables,
} from "@farmevo/common/dist/graphql/graphql";

const SnapshotReports = ({
  selectedShot,
  isOpen,
}: {
  selectedShot?: FieldShot;
  fieldId: number;
  isOpen: boolean;
}) => {
  const [{ data, error, fetching }] = useQuery<
    GetSnapshotReportsQuery,
    GetSnapshotReportsQueryVariables
  >({
    query: GET_SNAPSHOT_REPORTS_QUERY,
    variables: {
      shotId: selectedShot?.id,
    },
    pause: !isOpen,
  });

  if (fetching) {
    return (
      <Box maxW="40%" mt={2} mb={1}>
        <SkeletonText noOfLines={1} />
      </Box>
    );
  }

  if (error) {
    return (
      <Text fontSize="sm" fontStyle="italic" color="red.600">
        Error loading reports
      </Text>
    );
  }

  if (data?.reports.length === 0) {
    return (
      <Text fontSize="sm" fontStyle="italic" color="gray.600">
        Not available
      </Text>
    );
  }

  return (
    <VStack pr={6}>
      {data?.reports.map((report) => (
        <HStack key={report.id} alignItems="center" w="full">
          <Link
            flex={1}
            target="_blank"
            colorScheme="brand"
            as={RouteLink}
            to={report.link || `/reports/${report.tag}`}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {report.name}
          </Link>
          <Text fontSize="xs" color="gray.500">
            {new Date(report.createdAt).toDateString()}
          </Text>
        </HStack>
      ))}
    </VStack>
  );
};

export default SnapshotReports;
