export const pluralize = (
  value: number,
  word: string,
  plural: string | null = null,
  suffix = "s"
) => {
  if (value <= 1) {
    return `${value} ${word}`;
  }
  return plural ? `${value} ${plural}` : `${value} ${word}${suffix}`;
};
