import { gql } from "urql";

export const API_SIGN_UP_USER_MUTATION = gql`
  mutation ApiSignUpUser($input: api_sign_up_user_input) {
    api_sign_up_user(input: $input) {
      token
      id
    }
  }
`;

export const API_ACCEPT_INVITE_MUTATION = gql`
  mutation ApiAcceptInvite($input: api_accept_invite_input) {
    api_accept_invite(input: $input) {
      ok
    }
  }
`;
