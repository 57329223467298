import { ResolverConfig } from "@urql/exchange-graphcache";
import { simplePagination } from "@urql/exchange-graphcache/extras";
import { Jobs } from "@farmevo/common/dist/graphql/graphql";

export const resolvers: ResolverConfig = {
  query_root: {
    jobs: simplePagination({
      offsetArgument: "offset",
    }),
    vehicles: simplePagination({
      offsetArgument: "offset",
    }),
    implements: simplePagination({
      offsetArgument: "offset",
    }),
  },
  jobs: {
    from: (parent: Jobs) => parent.from && new Date(parent.from),
    to: (parent: Jobs) => parent.to && new Date(parent.to),
    completedAt: (parent: Jobs) =>
      parent.completedAt && new Date(parent.completedAt),
  },
};
