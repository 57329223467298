import * as icons from "react-feather";

export const IconActivity = icons.Activity;
export const IconAirplay = icons.Airplay;
export const IconAlertCircle = icons.AlertCircle;
export const IconAlertOctagon = icons.AlertOctagon;
export const IconAlertTriangle = icons.AlertTriangle;
export const IconAlignCenter = icons.AlignCenter;
export const IconAlignJustify = icons.AlignJustify;
export const IconAlignLeft = icons.AlignLeft;
export const IconAlignRight = icons.AlignRight;
export const IconAnchor = icons.Anchor;
export const IconAperture = icons.Aperture;
export const IconArchive = icons.Archive;
export const IconArrowDownCircle = icons.ArrowDownCircle;
export const IconArrowDownLeft = icons.ArrowDownLeft;
export const IconArrowDownRight = icons.ArrowDownRight;
export const IconArrowDown = icons.ArrowDown;
export const IconArrowLeftCircle = icons.ArrowLeftCircle;
export const IconArrowLeft = icons.ArrowLeft;
export const IconArrowRightCircle = icons.ArrowRightCircle;
export const IconArrowRight = icons.ArrowRight;
export const IconArrowUpCircle = icons.ArrowUpCircle;
export const IconArrowUpLeft = icons.ArrowUpLeft;
export const IconArrowUpRight = icons.ArrowUpRight;
export const IconArrowUp = icons.ArrowUp;
export const IconAtSign = icons.AtSign;
export const IconAward = icons.Award;
export const IconBarChart2 = icons.BarChart2;
export const IconBarChart = icons.BarChart;
export const IconBatteryCharging = icons.BatteryCharging;
export const IconBattery = icons.Battery;
export const IconBellOff = icons.BellOff;
export const IconBell = icons.Bell;
export const IconBluetooth = icons.Bluetooth;
export const IconBold = icons.Bold;
export const IconBookOpen = icons.BookOpen;
export const IconBook = icons.Book;
export const IconBookmark = icons.Bookmark;
export const IconBox = icons.Box;
export const IconBriefcase = icons.Briefcase;
export const IconCalendar = icons.Calendar;
export const IconCameraOff = icons.CameraOff;
export const IconCamera = icons.Camera;
export const IconCast = icons.Cast;
export const IconCheckCircle = icons.CheckCircle;
export const IconCheckSquare = icons.CheckSquare;
export const IconCheck = icons.Check;
export const IconChevronDown = icons.ChevronDown;
export const IconChevronLeft = icons.ChevronLeft;
export const IconChevronRight = icons.ChevronRight;
export const IconChevronUp = icons.ChevronUp;
export const IconChevronsDown = icons.ChevronsDown;
export const IconChevronsLeft = icons.ChevronsLeft;
export const IconChevronsRight = icons.ChevronsRight;
export const IconChevronsUp = icons.ChevronsUp;
export const IconChrome = icons.Chrome;
export const IconCircle = icons.Circle;
export const IconClipboard = icons.Clipboard;
export const IconClock = icons.Clock;
export const IconCloudDrizzle = icons.CloudDrizzle;
export const IconCloudLightning = icons.CloudLightning;
export const IconCloudOff = icons.CloudOff;
export const IconCloudRain = icons.CloudRain;
export const IconCloudSnow = icons.CloudSnow;
export const IconCloud = icons.Cloud;
export const IconCode = icons.Code;
export const IconCodepen = icons.Codepen;
export const IconCodesandbox = icons.Codesandbox;
export const IconCoffee = icons.Coffee;
export const IconColumns = icons.Columns;
export const IconCommand = icons.Command;
export const IconCompass = icons.Compass;
export const IconCopy = icons.Copy;
export const IconCornerDownLeft = icons.CornerDownLeft;
export const IconCornerDownRight = icons.CornerDownRight;
export const IconCornerLeftDown = icons.CornerLeftDown;
export const IconCornerLeftUp = icons.CornerLeftUp;
export const IconCornerRightDown = icons.CornerRightDown;
export const IconCornerRightUp = icons.CornerRightUp;
export const IconCornerUpLeft = icons.CornerUpLeft;
export const IconCornerUpRight = icons.CornerUpRight;
export const IconCpu = icons.Cpu;
export const IconCreditCard = icons.CreditCard;
export const IconCrop = icons.Crop;
export const IconCrosshair = icons.Crosshair;
export const IconDatabase = icons.Database;
export const IconDelete = icons.Delete;
export const IconDisc = icons.Disc;
export const IconDivideCircle = icons.DivideCircle;
export const IconDivideSquare = icons.DivideSquare;
export const IconDivide = icons.Divide;
export const IconDollarSign = icons.DollarSign;
export const IconDownloadCloud = icons.DownloadCloud;
export const IconDownload = icons.Download;
export const IconDribbble = icons.Dribbble;
export const IconDroplet = icons.Droplet;
export const IconEdit2 = icons.Edit2;
export const IconEdit3 = icons.Edit3;
export const IconEdit = icons.Edit;
export const IconExternalLink = icons.ExternalLink;
export const IconEyeOff = icons.EyeOff;
export const IconEye = icons.Eye;
export const IconFacebook = icons.Facebook;
export const IconFastForward = icons.FastForward;
export const IconFeather = icons.Feather;
export const IconFigma = icons.Figma;
export const IconFileMinus = icons.FileMinus;
export const IconFilePlus = icons.FilePlus;
export const IconFileText = icons.FileText;
export const IconFile = icons.File;
export const IconFilm = icons.Film;
export const IconFilter = icons.Filter;
export const IconFlag = icons.Flag;
export const IconFolderMinus = icons.FolderMinus;
export const IconFolderPlus = icons.FolderPlus;
export const IconFolder = icons.Folder;
export const IconFramer = icons.Framer;
export const IconFrown = icons.Frown;
export const IconGift = icons.Gift;
export const IconGitBranch = icons.GitBranch;
export const IconGitCommit = icons.GitCommit;
export const IconGitMerge = icons.GitMerge;
export const IconGitPullRequest = icons.GitPullRequest;
export const IconGitHub = icons.GitHub;
export const IconGitlab = icons.Gitlab;
export const IconGlobe = icons.Globe;
export const IconGrid = icons.Grid;
export const IconHardDrive = icons.HardDrive;
export const IconHash = icons.Hash;
export const IconHeadphones = icons.Headphones;
export const IconHeart = icons.Heart;
export const IconHelpCircle = icons.HelpCircle;
export const IconHexagon = icons.Hexagon;
export const IconHome = icons.Home;
export const IconImage = icons.Image;
export const IconInbox = icons.Inbox;
export const IconInfo = icons.Info;
export const IconInstagram = icons.Instagram;
export const IconItalic = icons.Italic;
export const IconKey = icons.Key;
export const IconLayers = icons.Layers;
export const IconLayout = icons.Layout;
export const IconLifeBuoy = icons.LifeBuoy;
export const IconLink2 = icons.Link2;
export const IconLink = icons.Link;
export const IconLinkedin = icons.Linkedin;
export const IconList = icons.List;
export const IconLoader = icons.Loader;
export const IconLock = icons.Lock;
export const IconLogIn = icons.LogIn;
export const IconLogOut = icons.LogOut;
export const IconMail = icons.Mail;
export const IconMapPin = icons.MapPin;
export const IconMap = icons.Map;
export const IconMaximize2 = icons.Maximize2;
export const IconMaximize = icons.Maximize;
export const IconMeh = icons.Meh;
export const IconMenu = icons.Menu;
export const IconMessageCircle = icons.MessageCircle;
export const IconMessageSquare = icons.MessageSquare;
export const IconMicOff = icons.MicOff;
export const IconMic = icons.Mic;
export const IconMinimize2 = icons.Minimize2;
export const IconMinimize = icons.Minimize;
export const IconMinusCircle = icons.MinusCircle;
export const IconMinusSquare = icons.MinusSquare;
export const IconMinus = icons.Minus;
export const IconMonitor = icons.Monitor;
export const IconMoon = icons.Moon;
export const IconMoreHorizontal = icons.MoreHorizontal;
export const IconMoreVertical = icons.MoreVertical;
export const IconMousePointer = icons.MousePointer;
export const IconMove = icons.Move;
export const IconMusic = icons.Music;
export const IconNavigation2 = icons.Navigation2;
export const IconNavigation = icons.Navigation;
export const IconOctagon = icons.Octagon;
export const IconPackage = icons.Package;
export const IconPaperclip = icons.Paperclip;
export const IconPauseCircle = icons.PauseCircle;
export const IconPause = icons.Pause;
export const IconPenTool = icons.PenTool;
export const IconPercent = icons.Percent;
export const IconPhoneCall = icons.PhoneCall;
export const IconPhoneForwarded = icons.PhoneForwarded;
export const IconPhoneIncoming = icons.PhoneIncoming;
export const IconPhoneMissed = icons.PhoneMissed;
export const IconPhoneOff = icons.PhoneOff;
export const IconPhoneOutgoing = icons.PhoneOutgoing;
export const IconPhone = icons.Phone;
export const IconPieChart = icons.PieChart;
export const IconPlayCircle = icons.PlayCircle;
export const IconPlay = icons.Play;
export const IconPlusCircle = icons.PlusCircle;
export const IconPlusSquare = icons.PlusSquare;
export const IconPlus = icons.Plus;
export const IconPocket = icons.Pocket;
export const IconPower = icons.Power;
export const IconPrinter = icons.Printer;
export const IconRadio = icons.Radio;
export const IconRefreshCcw = icons.RefreshCcw;
export const IconRefreshCw = icons.RefreshCw;
export const IconRepeat = icons.Repeat;
export const IconRewind = icons.Rewind;
export const IconRotateCcw = icons.RotateCcw;
export const IconRotateCw = icons.RotateCw;
export const IconRss = icons.Rss;
export const IconSave = icons.Save;
export const IconScissors = icons.Scissors;
export const IconSearch = icons.Search;
export const IconSend = icons.Send;
export const IconServer = icons.Server;
export const IconSettings = icons.Settings;
export const IconShare2 = icons.Share2;
export const IconShare = icons.Share;
export const IconShieldOff = icons.ShieldOff;
export const IconShield = icons.Shield;
export const IconShoppingBag = icons.ShoppingBag;
export const IconShoppingCart = icons.ShoppingCart;
export const IconShuffle = icons.Shuffle;
export const IconSidebar = icons.Sidebar;
export const IconSkipBack = icons.SkipBack;
export const IconSkipForward = icons.SkipForward;
export const IconSlack = icons.Slack;
export const IconSlash = icons.Slash;
export const IconSliders = icons.Sliders;
export const IconSmartphone = icons.Smartphone;
export const IconSmile = icons.Smile;
export const IconSpeaker = icons.Speaker;
export const IconSquare = icons.Square;
export const IconStar = icons.Star;
export const IconStopCircle = icons.StopCircle;
export const IconSun = icons.Sun;
export const IconSunrise = icons.Sunrise;
export const IconSunset = icons.Sunset;
export const IconTable = icons.Table;
export const IconTablet = icons.Tablet;
export const IconTag = icons.Tag;
export const IconTarget = icons.Target;
export const IconTerminal = icons.Terminal;
export const IconThermometer = icons.Thermometer;
export const IconThumbsDown = icons.ThumbsDown;
export const IconThumbsUp = icons.ThumbsUp;
export const IconToggleLeft = icons.ToggleLeft;
export const IconToggleRight = icons.ToggleRight;
export const IconTool = icons.Tool;
export const IconTrash2 = icons.Trash2;
export const IconTrash = icons.Trash;
export const IconTrello = icons.Trello;
export const IconTrendingDown = icons.TrendingDown;
export const IconTrendingUp = icons.TrendingUp;
export const IconTriangle = icons.Triangle;
export const IconTruck = icons.Truck;
export const IconTv = icons.Tv;
export const IconTwitch = icons.Twitch;
export const IconTwitter = icons.Twitter;
export const IconType = icons.Type;
export const IconUmbrella = icons.Umbrella;
export const IconUnderline = icons.Underline;
export const IconUnlock = icons.Unlock;
export const IconUploadCloud = icons.UploadCloud;
export const IconUpload = icons.Upload;
export const IconUserCheck = icons.UserCheck;
export const IconUserMinus = icons.UserMinus;
export const IconUserPlus = icons.UserPlus;
export const IconUserX = icons.UserX;
export const IconUser = icons.User;
export const IconUsers = icons.Users;
export const IconVideoOff = icons.VideoOff;
export const IconVideo = icons.Video;
export const IconVoicemail = icons.Voicemail;
export const IconVolume1 = icons.Volume1;
export const IconVolume2 = icons.Volume2;
export const IconVolumeX = icons.VolumeX;
export const IconVolume = icons.Volume;
export const IconWatch = icons.Watch;
export const IconWifiOff = icons.WifiOff;
export const IconWifi = icons.Wifi;
export const IconWind = icons.Wind;
export const IconXCircle = icons.XCircle;
export const IconXOctagon = icons.XOctagon;
export const IconXSquare = icons.XSquare;
export const IconX = icons.X;
export const IconYoutube = icons.Youtube;
export const IconZapOff = icons.ZapOff;
export const IconZap = icons.Zap;
export const IconZoomIn = icons.ZoomIn;
export const IconZoomOut = icons.ZoomOut;
export { IconJobPending } from "./IconJobPending";
export { IconJobReady } from "./IconJobReady";
export { IconJobProgress } from "./IconJobProgress";
export { IconJobPartlyCompleted } from "./IconJobPartlyCompleted";
export { IconJobPendingCompletion } from "./IconJobPendingCompletion";
