import { Text, VStack } from "@chakra-ui/react";
import { IconFrown } from "../Components/Icons";

const NotFound = () => (
  <VStack h="calc(100vh - 132px)" justify="center">
    <IconFrown size={60} strokeWidth="1px" />
    <Text>We don't have what you are looking for :(</Text>
  </VStack>
);

export default NotFound;
