import { gql } from "urql";

export const SAVE_MY_BASIC_PROFILE = gql`
  mutation SaveMyBasicProfile(
    $userId: Int!
    $updateUserPayload: users_set_input!
  ) {
    update_users_by_pk(pk_columns: { id: $userId }, _set: $updateUserPayload) {
      firstName
      lastName
      username
    }
  }
`;

export const SAVE_MY_USER_PROFILE = gql`
  mutation SaveMyUserProfile(
    $userId: Int!
    $updateUserProfilePayload: user_profiles_set_input!
  ) {
    update_user_profiles_by_pk(
      pk_columns: { userId: $userId }
      _set: $updateUserProfilePayload
    ) {
      address1
      address2
      city
      companyName
      country
      phone
      postalCode
      state
      taxNumber
    }
  }
`;

export const GET_MY_USER_PROFILE = gql`
  query GetMyUserProfile($userId: Int!) {
    user_profiles_by_pk(userId: $userId) {
      address1
      address2
      city
      companyName
      country
      phone
      postalCode
      state
      taxNumber
    }
  }
`;

export const GET_MY_USER_PREFERENCES = gql`
  query GetMyUserPreferences($userId: Int!) {
    user_prefs(where: { userId: { _eq: $userId } }) {
      currency
      fieldColor
      fieldUnit
      geoLocation
      invoicePayInfo
      invoiceTerms
      timezone
    }
  }
`;

export const SAVE_MY_USER_PREFERENCES = gql`
  mutation SaveMyUserPreferences(
    $userId: Int!
    $updateUserPreferencesPayload: user_prefs_set_input!
  ) {
    update_user_prefs_by_pk(
      pk_columns: { userId: $userId }
      _set: $updateUserPreferencesPayload
    ) {
      currency
      fieldColor
      fieldUnit
      geoLocation
      invoicePayInfo
      invoiceTerms
      timezone
    }
  }
`;

export const GET_MY_TAX_RATES = gql`
  query GetMyTaxRates($userId: Int!) {
    user_tax_rates(where: { userId: { _eq: $userId } }) {
      label
      name
      rate
      userId
    }
  }
`;

export const SAVE_MY_TAX_RATES = gql`
  mutation SaveMyTaxRates($rates: [user_tax_rates_insert_input!]!) {
    insert_user_tax_rates(
      objects: $rates
      on_conflict: { constraint: user_tax_rates_pkey, update_columns: [rate] }
    ) {
      affected_rows
    }
  }
`;
